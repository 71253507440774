@use "../partials/settings" as *;

.section__404 {
	height: 60vh;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	position: relative;
	
	&::before {
		content: '404';
		color: rgba(#E7E7E7, .33);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: calc(35vw + 3em);
		font-weight: bold;
	}
	
	.row {
		position: relative;
		z-index: 1;
	}
	
	p {
		font-size: calc(1vw + 1em);
		
		@media (min-width: $breakpoint-xxl) {
			font-size: 40px;
		}
	}
	
	h1 {
		font-size: calc(1.5vw + 1.5em);
		
		@media (min-width: $breakpoint-xxl) {
			font-size: 60px;
		}
	}
}