@use "../partials/settings" as *;

.usp {
	background: white;
	//box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
	padding: 0px 0px;
	position: relative;
	width: 100%;
	z-index: 2;
	
	ul {
		display: table;
		height: 50px;
		list-style: none;
		margin: 0 auto;
		position: relative;
		width: 100%;
		
		li {
			color: #666;
			display: none;
			font-size: 0.87em;
			font-weight: 300;
			left: 0;
			line-height: 1;
			padding: 17.5px 0px;
			position: absolute;
			text-align: center;
			width: 100%;
			
			.fas {
				margin-right: 5px;
				color: $groen;
				font-size: inherit;
			}
		}
		
		@media(min-width: $breakpoint-xl) {
			width: auto;
			margin: 0;
			justify-content: space-between;
			display: flex;
			
			li {
				display: block;
				float: left;
				position: relative;
				text-align: center;
				width: auto;
			}
		}
	}
}
