@use "../partials/settings" as *;

h1 {
	color: $antraciet;
	font-size: 2em;
	font-weight: bold;
	margin-bottom: 25px;
}

h2 {
	color: $antraciet;
	font-size: 1.6em;
	font-weight: bold;
	margin-bottom: 25px;
}

h3 {
	color: $antraciet;
	font-size: 1em;
	font-weight: 600;
	margin-bottom: 0;
}

h4 {
	color: $antraciet;
	font-size: 1em;
	font-weight: 500;
}

span {
	font-size: inherit;
	font-weight: inherit;
}

p {
	font-size: 1em;
	font-weight: 300;
	line-height: 1.5;
	margin-bottom: 25px;

	&:last-child {
		margin-bottom: 0;
	}
	
	&:empty {
		display: none;
	}
}

a {
	background-color: transparent;
	color: $rood;
	cursor: pointer;
	font-size: 1em;
	font-weight: 300;
	text-decoration: none;
	
	&:hover {
		color: darken($rood, 15%);
	}
	
	&.small {
		font-size: 0.8em;
		text-decoration: underline;
	}
}

.small {
	font-size: smaller !important;
}


.black {
	color: black !important;
}

.white {
	color: white !important;
}

.bold {
	font-weight: bold !important;
}

.center {
	text-align: center !important;
}

.left {
	text-align: left !important;
}

.right {
	text-align: right !important;
}


.success {
	color: $success;
}

.alert {
	color: $alert;
}

.danger {
	color: $danger;
}

.grey {
	color: $donkergrijs;
}

.rood {
	color: $rood !important;
}

.blauw {
	color: $blauw !important;
}

.uppercase {
	text-transform: uppercase;
}

small {
	// font-size: .8rem !important;
	// padding: 0 !important;
}

.error {
	color: $danger;
}

strong {
	font-size: inherit;
}
