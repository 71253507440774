@use "../partials/settings" as *;

section.section.direct-reserveren {
	background: $lichtgrijs;
	
	.content {
		@media (min-width: $breakpoint-lg) {
			padding-left: 80px;
			padding-right: 80px;
		}
		
		@media (min-width: $breakpoint-xl) {
			padding-left: 200px;
			padding-right: 200px;
		}
	}
}