@use "../partials/settings"as *;

section.text-image-block {

	.text {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		padding: 30px 0;

		@media (min-width: $breakpoint-md) {
			padding: 0;
		}

		&--has-image {
			padding: 30px 10px;
			position: relative;

			h2 {
				margin-top: 30px;

				@media (min-width: $breakpoint-lg) {
					margin-top: 0;
				}
			}

			@media (min-width: $breakpoint-lg) {
				padding: 45px;
			}

			@media (min-width: $breakpoint-xl) {
				box-shadow: 0 2px 33px 7px #EBF0FB;
				padding: 90px;
			}

			figure {
				height: 0;
				width: 100%;
				padding-top: 56.25%;
				overflow: hidden;
				position: relative;

				&.hover-img {
					display: block;
					z-index: 1;
					transition: opacity .2s ease-out;

					@media (min-width: $breakpoint-lg) {
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
					}
				}

				img {
					height: 100%;
					width: 100%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					object-fit: cover;
					position: absolute;
				}
			}
		}

		h2 {
			color: $rood;
			text-align: left;
		}
	}

	.container {
		.row {

			.video-container-wrapper {
				position: relative;
				margin-top: 30px;

				>img {
					width: 100vw;
					position: relative;
					left: 50%;
					transform: translate(-50%, 0);

					@media (min-width: $breakpoint-md) {
						width: 100%;
						position: static;
						left: auto;
						transform: translate(0, 0);
					}
				}

				.video-container {
					position: relative;
					height: 0;
					padding-top: 56.25%;
					margin-top: 50px;
					display: block;
					width: 100vw;
					left: 50%;
					transform: translate(-50%, 0);

					@media (min-width: $breakpoint-md) {
						transform: translate(0, 0);
						//width: calc(100% - 60px);
						padding-top: 50.25%;
						left: 0px;
						width: 100%;
					}
				}

				.video-container video {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.video-container .playbutton {
					position: absolute;
					z-index: 2;
					width: 90px;
					height: 90px;
					left: calc(50% - 45px);
					top: calc(50% - 45px);
					cursor: pointer;
				}

				.video-container .videoposter {
					position: absolute;
					z-index: 0;
					width: 100%;
					height: 100%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					cursor: pointer;
					object-fit: cover;

				}
			}
		}
	}


	.no-padding {
		padding: 0;

		@media (min-width: $breakpoint-md) {
			padding: 0 15px;
		}
	}

	figure {
		height: 100%;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.btn {

		@media (min-width: $breakpoint-md) {
			align-self: flex-start;
		}

	}

	.order-1 {
		order: 1;

		@media (min-width: $breakpoint-md) {
			order: initial;
		}
	}
}