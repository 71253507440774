@use 'settings' as *;

.card {
	background: #fff;
	box-shadow: 0 0 15px 0 rgba($donkergrijs, 0.2);
	width: 100%;
	//border: 1px solid #ccc;
	height: calc(100% - 25px);
	// height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	
	figure {
		width: 100%;
		height: 0px;
		position: relative;
		padding-top: 56.25%;
		background-color: #f1f1f1;
		background-image: url(../img/logo.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 40%;
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	.text {
		padding: 25px;
		display: flex;
		flex-direction: column;
		flex: 1;
		background: white;
		
		.entry-date {
			font-size: 0.7em;
			color: #666;
			margin-bottom: 10px;
		}
		
		
		h2 {
			font-size: 1.3em;
			margin-bottom: 10px;
		}
	}
	
	.btn {
		margin-top: auto;
		// margin-bottom: 25px;
		display: block;
	}
}

.card.card-horizontal {
	margin-bottom: 15px;
	background-color: white;
	padding: 15px;
	height: auto;

	figure {
		width: 100%;
		padding-top: 56.25%;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		height: 100%;

		img {
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.text {
		padding: 15px;

		p {
			margin-bottom: 10px;
		}

		.btn {
			margin-top: 0;
			width: 0%;
			display: table;
		}
	}
}

.card__car {
	background: #fff;
	box-shadow: 0 0 15px 0 rgba($donkergrijs, 0.2);
	height: calc(100% - 30px);
	margin-bottom: 30px;
	overflow: hidden;
	padding: 0;
	
	&.card__vergelijk {
		height: auto;
	}
	
	a.card__link {
		display: flex;
		flex-direction: column;
		height: 100%;
		
		&:hover {
			.card__title {
				text-decoration: underline;
			}
			
			.btn-primary {
				background: darken(#1d4f6e, 10%);
				border-color: darken(#1d4f6e, 10%);
			}
		}
	}
	
	.card__title {
		color: $blauw;
		font-weight: 100;
		margin-bottom: 0;
		text-transform: uppercase;
		
		strong {
			font-size: inherit;
		}
	}
	
	.card__description {
		font-size: 13px;
		margin-bottom: 10px;
		color: $donkergrijs
	}
	
	.card__price {
		margin-top: auto;
		
		.price__container {
			display: flex;
			flex-direction: row;
			width: 100%;
			font-size: 1.2em;
			
			.old_price {
				margin-right: 5px;
				font-size: 0.8em;
			}
			
			.btw {
				text-align: left;
				margin-left: 5px;
				font-size: 0.5em;
			}
		}
	}
	
	.card__price-after {
		font-family: Roboto, sans-serif;
		font-size: 10px;
		font-weight: 100;
		margin-left: 5px;
		
		span {
			color: $donkergrijs;
			line-height: 1;
		}
	}
	
	figure {
		display: block;
		height: 0;
		margin: 0;
		overflow: hidden;
		padding: 0;
		padding-top: 56.25%;
		position: relative;
		width: 100%;
		background: #f1f1f1;
		
		span.noimage {
			font-size: 1.2em;
			color: rgba($donkergrijs, 0.2);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-weight: 400;
			text-align: center;
		}
		
		img {
			height: 100%;
			left: 0;
			max-width: 100%;
			object-fit: cover;
			position: absolute;
			top: 0px;
			width: 100%;
		}
		
		.background {
			background: #f7f7f7;
			height: 80%;
			left: 0;
			position: absolute;
			top: 15px;
			width: 100%;
		}
	}
	
	.text {
		color: black;
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;
		padding: 18px 30px 0 30px;
		text-align: left;
		
		.btn {
			margin: 15px auto;
			width: 100%;
			margin-bottom: 28px;
			background-color: #1d4f6e;
			border-color: #1d4f6e;
			
			@media (min-width: $breakpoint-lg) {
				margin-bottom: 10px;
			}
		}
	}
	
	.card__checkbox {
		padding: 0 30px 18px 30px;
		display: none;
		
		@media (min-width: $breakpoint-lg) {
			display: flex;
		}
		
		input[type="checkbox"] {
			height: 13px;
			margin-right: 5px;
		}
		
		label {
			color: $donkergrijs;
			font-size: 12px;
			font-weight: 100;
			height: 13px;
			line-height: 15px;
			flex: 1 0 0;
		}
		
		.vergelijk__nu {
			text-decoration: underline;
			color: $blauw;
			font-size: 12px;
			display: none;
			cursor: pointer;
			
			&:hover {
				color: darken($blauw, 10%);
			}
		}
	}
}

.card__search {
	padding: 25px;
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
	height: 100%;
	
	h1 {
		font-size: 1.2em;
		color: $blauw;
		font-weight: 600;
		margin-bottom: 20px;
	}
	
	.car_types {
		margin-bottom: 25px;
	}
	
	select {
		margin-top: auto;
	}
	
	button {
		position: relative;
		text-align: left;
		
		.search-icon {
			position: absolute;
			right: 20px;
		}
	}
}

.card__horizontal {
	display: flex;
	flex-direction: row;
	padding: 5px;
	margin-bottom: 5px;
	align-items: center;
	position: relative;
	
	.figure_holder {
		// width: 150px;
		width: 80px;
		
		@media (min-width: $breakpoint-xl) {
			width: 150px;
		}
		
		figure {
			width: 100%;
			position: relative;
			
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	
	.info {
		padding: 0px 15px;
		width: 100%;
		flex: 1;
		
		.vehicle__label {
			top: 0;
			left: 0;
			margin-bottom: 5px;
			display: inline-block;
			max-width: 120px;
			position: absolute;
			transform: scale(0.75);
			transform-origin: 0% 0%;
			text-align: center;
			
			@media(min-width: $breakpoint-lg) {
				position: relative;
				display: inline-block;
				transform: scale(1);
				max-width: 300px;
			}
		}
		
		.card__title {
			font-size: 0.7em;
			margin-bottom: 0;
			@media(min-width: $breakpoint-lg) {
				font-size: 1em;
			}
		}
	}
	
	.price__container {
		margin-left: auto;
	}
	
	.arrow {
		width: 25px;
		height: 100%;
		background-color: $rood;
		display: block;
		color: white;
		text-align: center;
		font-size: 18px;
		line-height: 80px;
		margin-left: 20px;
		border-radius: 2px;
		padding: 0px 5px;
	}
	
	&:hover {
		background: #f1f1f1;
		
		.arrow {
			background-color: darken($rood, 10%);
		}
	}
}