@use "../partials/settings" as *;

.single {
	.entry-date {
		font-size: 0.7em;
		color: #666;
		margin-bottom: 0px;
		position: relative;
		top: -20px;
	}
	
	figure.header_img {
		margin: 0px;
		margin-bottom: 25px;
		position: relative;
		height: 0px;
		padding-top: 45%;
		background-color: #f1f1f1;
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-color: #f1f1f1;
		}
	}
}

p#breadcrumbs {
	font-size: 0.8em;
	color: #666;
	font-weight: 400;
	margin-bottom: 0;
	
	span, a {
		font-size: inherit;
		font-weight: inherit;
		color: #666;
	}
	
	.fa {
		font-size: 90%;
		margin: 0px 3px;
	}
	
	a:hover {
		color: $rood;
	}
}