@use "../partials/settings" as *;

.single-voertuigen, .archive {
	.section {
		padding: 20px 0px;
		background: $lichtgrijs;
		
		&.section__light {
			background: #fff;
		}
		
		&.vergelijkbaar {
			padding: 50px 25px;
			padding-bottom: 25px;
		}
	}
	
	section.vehicle {
		background: $lichtgrijs;
		padding-bottom: 0;
		
		.vehicle__image {
			.vehicle__image-buttons {
				position: relative;
				
				.vehicle__image-button {
					position: absolute;
					top: calc(50% - 37.5px);
					z-index: 2;
					padding: 25px 7.5px;
					background: rgba(white, .8);
					cursor: pointer;
					border: 0;
					outline: none;
					display: none;
					
					&--next {
						right: 0;
						padding-right: 5px;
					}
					
					&--prev {
						left: 0;
						padding-left: 5px;
					}
				}
			}
			
			&:hover {
				.vehicle__image-button {
					display: block;
				}
			}
		}
		
		.vehicle__images {
			margin-bottom: 0;
			padding: 0;
			position: relative;
			
			
			@media(min-width: $breakpoint-lg) {
				margin-bottom: 25px;
			}
			
			.vehicle__labels {
				height: auto;
				left: 0;
				position: absolute;
				top: 0;
				transform-origin: 0% 0%;
				z-index: 1;
				
				.vehicle__label {
					display: inline-block;
					margin-right: 5px;
					position: relative;
					white-space: nowrap;
				}
			}
			
			figure {
				background: #f1f1f1;
				display: block;
				height: 0;
				margin: 0;
				overflow: hidden;
				padding: 0;
				padding-top: 56.25%;
				position: relative;
				width: 100%;
			}
			
			span.noimage {
				color: rgba($donkergrijs, 0.2);
				font-size: 1.2em;
				font-weight: 400;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			
			img {
				height: 100%;
				left: 0;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: 100%;
			}
			
			.other__images {
				display: flex;
				padding: 5px;
				width: 100%;
				
				.other__image {
					cursor: pointer;
					margin: 5px;
					max-width: 120px;
					position: relative;
					width: 33.333%;
					
					&.selected {
						&::after {
							border: 1px solid $rood;
							content: "";
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
							
							@media(min-width: $breakpoint-lg) {
								border-width: 2px;
							}
						}
					}
					
					&:hover {
						&::after {
							border: 1px solid $donkergrijs;
							content: "";
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
							
							@media(min-width: $breakpoint-lg) {
								border-width: 2px;
							}
						}
					}
					
					figure {
						width: 100%;
					}
				}
			}
		}
		
		.vehicle__details {
			background: white;
		}
		
		.actie__header {
			margin-bottom: 5px;
			margin-top: auto;
		}
		
		.card__horizontal--vehicle {
			margin-bottom: 25px;
			box-shadow: 0 0 15px 0 rgba($donkergrijs, .15);
			position: relative;
			height: auto;
			
			.figure_holder {
				width: 130px;
			}
			
			.vehicle__label {
				top: 0;
				left: 0;
				right: auto;
				position: absolute;
				padding: 4px 12px;
				margin-bottom: 0;
				
				i, span {
					font-size: .8em;
				}
			}
		}
		
		.vehicle__name {
			display: flex;
			margin-bottom: 15px;
			width: 100%;
		}
		
		.vehicle__title {
			font-size: 1.3em;
			font-weight: 300;
			line-height: 1;
			margin-bottom: 0;
			margin-right: auto;
			
			@media(min-width: $breakpoint-lg) {
				font-size: 1.6em;
			}
			
			strong {
				font-size: inherit;
			}
		}
		
		.vehicle__description {
			color: $donkergrijs;
			font-size: 1em;
			line-height: 1;
		}
		
		.price__container--voertuig {
			margin-left: auto;
			padding-left: 5px;
			
			.price {
				color: $rood;
				font-size: 1em;
				line-height: 1em;
				text-align: right;
				margin-bottom: 6px;
				margin-left: 3px;
				margin-top: 3px;
				white-space: normal;

				@media(min-width: $breakpoint-lg) {
					font-size: 1em;
				}
				
				@media(min-width: $breakpoint-xl) {
					font-size: 1.6em;
				}
			}
		}
		
		.excerpt {
			display: flex;
			font-size: 0.9em;
			line-height: 1.1;
			margin-bottom: 25px;
		}
		
		.form {
			display: flex;
			flex-direction: column;
			margin-top: auto;
			
			h3 {
				color: $antraciet;
				font-size: 1em;
				margin-bottom: 5px;
			}
			
			.periodes {
				align-items: center;
				display: flex;
				justify-content: center;
				margin-bottom: 15px;
				
				.periode {
					width: 25%;
					
					label {
						border: 1px solid #666;
						border-left: none;
						border-right: 1px solid #666;
						color: #666;
						cursor: pointer;
						display: block;
						font-size: 0.8em;
						left: -2px;
						line-height: 1.1;
						padding: 10px;
						position: relative;
						text-align: center;
						width: 100%;
						
						&:hover {
							background-color: rgba(#666, 0.1);
						}
						
						.maanden {
							font-size: 0.6em;
							text-transform: uppercase;
						}
					}
					
					&:first-child label {
						-webkit-border-bottom-left-radius: 3px;
						border-bottom-left-radius: 3px;
						border-left: 1px solid #666;
						-moz-border-radius-bottomleft: 3px;
						-moz-border-radius-topleft: 3px;
						-webkit-border-top-left-radius: 3px;
						border-top-left-radius: 3px;
					}
					
					&:last-child label {
						-webkit-border-bottom-right-radius: 3px;
						border-bottom-right-radius: 3px;
						-moz-border-radius-bottomright: 3px;
						-moz-border-radius-topright: 3px;
						border-right: 1px solid #666;
						-webkit-border-top-right-radius: 3px;
						border-top-right-radius: 3px;
					}
					
					input {
						display: none;
					}
					
					input:checked ~ label {
						background-color: #666;
						color: white;
					}
				}
			}
		}
		
		.btn-primary {
		
		}
	}
	
	section.options {
		padding-top: 0;
	}
	
	.standaard {
		.standaard_inbegrepen {
			width: 100%;
		}
		
		ul li {
			line-height: 1.8em;
			list-style: none;
			margin-bottom: 15px;
			padding-left: 50px;
			position: relative;
			
			.fa {
				color: $blauw;
				font-size: 1.2em;
				left: 0;
				position: absolute;
				top: 5px;
			}
		}
	}
	
	.bijlagen {
		list-style: none;
		
		.far {
			color: #666;
			font-size: 1.1em;
			margin-right: 5px;
		}
		
		li {
			// text-transform: capitalize;
			padding-left: 2px;
		}
	}
}

.vehicle__label {
	color: white;
	font-size: 0.8em;
	font-weight: 400;
	padding: 5px 15px;
	position: absolute;
	right: 0;
	top: 0px;
	z-index: 3;
	
	i {
		font-size: inherit;
		line-height: inherit;
		margin-right: 5px;
	}
	
	&.green {
		background: $groen;
	}
	
	&.yellow {
		background: $geel;
	}
	
	&.blue {
		background-color: $deal;
	}
}

section.section .block {
	background-color: white;
	margin-bottom: 25px;
	padding: 25px;
	height: calc(100% - 25px);
	margin-bottom: 25px;
	
	&.map {
		padding: 0;
		min-height: 400px;
	}
	
	&__height {
		height: auto;
	}
	
	&__grey {
		background-color: #f1f1f1;
	}
	
	h2 {
		text-align: left;
	}
}

.list {
	color: $donkergrijs;
	width: 100%;
	
	.row {
		padding: 12px;
		
		&:nth-child(odd) {
			background-color: #f1f1f1;
		}
	}
}