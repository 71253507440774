@use 'settings' as *;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 900;

  // @media(min-width: $breakpoint-md) {
  // 	position: fixed;
  // 	top: 0;
  // 	z-index: 22;
  // }

  .top-bar {
    align-items: center;
    background: $lichtgrijs;
    display: flex;
    height: 55px;
    position: relative;
    top: 0;
    width: 100%;
    justify-content: flex-end;

    .btn-mijn {
      display: none;
      font-size: 12px;
      line-height: 37px;
      margin: 0;
      height: 38px;

      @media (min-width: $breakpoint-xl) {
        display: inline-block;
      }
    }

    ul.top-bar__menu {
      float: right;

      li {
        display: inline-block;
        list-style-type: none;
        margin-left: 15px;

        a,
        i {
          font-size: 12px;
          line-height: 18px;
          color: $antraciet;

          @media (max-width: 415px) {
            font-size: 10px;
            line-height: 12px;
          }

          @media (max-width: 357px) {
            font-size: 7.7px;
          }
        }

        a:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .header__main {
    background: white;
    flex-direction: column;
    padding: 10px 0px;
    position: relative;

    @media (min-width: $breakpoint-md) {
      height: 100px;
    }

    .flexbox {
      flex-wrap: nowrap;
    }

    .logo {
      margin-right: auto;
      max-width: 80px;
      width: 100%;

      @media (min-width: $breakpoint-xxl) {
        max-width: 140px;
      }

      img {
        width: 100%;
      }
    }

    .btn-menu {
      color: $blauw;
      display: block;
      height: 53px;
      line-height: 53px;
      margin-left: 5px;
      padding: 0;
      padding-right: 25px;
      text-align: center;

      .fa {
        font-size: 30px;
        line-height: 53px;
      }

      @media (min-width: $breakpoint-xl) {
        display: none;
      }
    }

    .btn {
      display: block;
      margin: 0;
    }

    .btn-reserveren {
      font-size: 0.7em;
      letter-spacing: 0.5px;
      padding: 0px 10px;
      margin-right: 5px;
      background: #1d4f6e;
      border-color: #1d4f6e;

      &:hover {
        color: #fff;
        background: darken(#1d4f6e, 10%);
        border-color: darken(#1d4f6e, 10%);
      }

      &:last-of-type {
        margin-right: 0px;
      }

      @media (max-width: 344px) {
        font-size: 0.5em;
        letter-spacing: 1px;
        margin-left: 5px;
        padding: 0px 10px;
      }

      @media (min-width: $breakpoint-lg) {
        font-size: 0.6em;
        letter-spacing: 1px;
        margin-right: 5px;
        padding: 0px 20px;

        &:last-of-type {
          margin-right: 0px;
        }
      }

      @media (min-width: $breakpoint-xxl) {
        font-size: 0.8em;
        letter-spacing: 1px;
        margin-left: 5px;
        padding: 0px 25px;
      }
    }

    .btn-mijn {
      //display: none;
      margin-right: 5px;
      padding: 0px 20px;
      display: inline-block;

      @media (min-width: $breakpoint-md) {
      }

      @media (min-width: $breakpoint-lg) {
        font-size: 0.6em;
      }

      @media (min-width: $breakpoint-xxl) {
        font-size: 0.8em;
      }
    }
  }

  .nav {
    align-items: center;
    background-color: $blauw;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    flex: 1 0 0;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: -100%;
    overflow-y: scroll;
    padding: 0px;
    padding-top: 50px;
    position: fixed;
    top: 0px;
    transition: left 0.2s ease-in-out;
    width: 80%;
    z-index: 20;

    @media (min-width: $breakpoint-xl) {
      overflow-y: visible;
    }

    .nav__close {
      // background-color: rgba(0, 0, 0, 0.05);
      color: #666;
      color: white;
      font-size: 1em;
      height: 50px;
      line-height: 50px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 50px;

      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }

    .nav__overlay {
      display: none;
      height: 100%;
      left: 80%;
      position: fixed;
      top: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);

      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }

    &.active {
      left: 0%;

      .nav__overlay {
        display: block;
      }
    }

    ul {
      list-style: none;
      // margin-bottom: 20px;
      width: 100%;

      li {
        width: 100%;

        a {
          border-top: 1px solid #497590;
          color: white;
          display: block;
          font-weight: 400;
          line-height: 80px;
          padding: 0px 25px;
          text-align: left;
          width: 100%;
        }

        &.menu-item-has-children > a {
          &::after {
            content: '\f107';
            display: inline-block;
            float: right;
            font-family: 'Font Awesome 5 Free';
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;

            @media (min-width: $breakpoint-xl) {
              float: none;
            }
          }
        }

        &.menu-item-has-children.hover > a {
          &::after {
            content: '\f106';
          }
        }

        .sub-menu {
          display: none;

          li {
            width: 100%;
          }
        }

        &.hover .sub-menu {
          background-color: rgba(0, 0, 0, 0.1);
          display: block;
        }

        &.current-menu-item a {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }

      @media (min-width: $breakpoint-xl) {
        li {
          width: auto;

          a {
            border-top: 0;
            padding: 0px 20px;
            width: auto;
          }

          &.menu-item-has-children {
            position: relative;

            > a {
              &::after {
                margin-left: 5px;
              }
            }
          }

          .sub-menu {
            background-color: white;
            display: none;
            left: 0;
            position: absolute;
            top: 100%;

            a {
              padding: 0px 20px;
              white-space: nowrap;
            }
          }

          &.hover .sub-menu {
            background-color: white;
            display: table;
          }
        }
      }
    }

    // ul#menu-hoofdmenu {
    //   margin-left: 25px;

    //   @media(min-width: $breakpoint-xl) {
    //     margin-left: 50px;
    //   }

    //   li {
    //     display: inline-block;

    //     &.current-menu-item a {
    //       color: $rood;
    //       font-weight: 700;
    //     }

    //     a {
    //       color: $blauw;
    //       line-height: 45px;
    //       padding: 0px 10px;

    //       @media(min-width: $breakpoint-xxl) {
    //         padding: 0px 20px;
    //       }

    //       &:hover {
    //         text-decoration: underline;
    //       }
    //     }
    //   }
    // }

    @media (min-width: $breakpoint-xl) {
      background: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      display: block;
      height: auto;
      left: 0;
      padding: 0px;
      padding-left: 25px;
      position: relative;
      top: 0;

      .btn {
        display: none;
      }

      ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        padding-right: 65px;
        width: 100%;

        li {
          display: table;
          float: left;
          width: auto;

          &.mobile-only {
            display: none;
          }

          a {
            color: $blauw;
            font-size: 0.9em;
            line-height: 50px;
            padding: 0px 15px;
            width: auto;

            @media (min-width: $breakpoint-lg) {
              padding: 0 11px;
            }

            @media (min-width: $breakpoint-xxl) {
              padding: 0 20px;
            }

            &:hover {
              color: $rood;
            }
          }
        }

        li.submenu {
          a {
            &::after {
              content: '\f107';
              display: inline-block;
              font-family: 'Font Awesome 5 Free';
              font-size: 80%;
              -webkit-font-smoothing: antialiased;
              font-style: normal;
              font-variant: normal;
              font-weight: 900;
              margin-left: 10px;
              text-rendering: auto;
            }

            &.active {
              &::after {
                content: '\f106';
              }
            }
          }
        }
      }
    }
    @media (min-width: $breakpoint-xxl) {
      padding-left: 50px;
    }
  }

  .switch {
    color: #ccc;
    display: none;
    font-size: 12px;

    @media (min-width: $breakpoint-md) {
      display: block;
    }

    u {
      color: #666;
      font-size: inherit;
    }
  }

  .minicart {
    display: inline-block;
    margin: 0px 10px;
    padding: 0px 10px;
    position: relative;

    .minicart__overlay {
      background: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    @media (min-width: $breakpoint-xl) {
      margin: 0px 20px;
    }

    .cart-icon {
      color: $blauw;
      cursor: pointer;
      font-size: 18px;
      line-height: 18px;

      &:hover {
        color: darken($blauw, 10%);
      }
    }

    &.cart-empty {
      display: none;
    }

    .circle {
      cursor: pointer;
      background: $rood;
      border-radius: 50%;
      top: -11px;
      color: white;
      display: block;
      font-size: 10px;
      height: 20px;
      line-height: 20px;
      position: absolute;
      right: 0px;
      text-align: center;
      width: 20px;
    }

    .card {
      bottom: 0;
      display: table;
      height: auto;
      // top: 50px;
      // right: -5px;
      left: 0px;
      margin-bottom: 0;
      max-height: 80%;
      max-width: $breakpoint-md;
      overflow: auto;
      padding: 25px;
      padding: 0;
      position: absolute;
      position: fixed;
      width: 100%;
      z-index: 30;
      box-shadow: 0 0 15px 5px rgb(136 136 136 / 35%);

      .arrow {
        background: white;
        display: none;
        height: 15px;
        position: absolute;
        right: 15px;
        top: -7px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 15px;
      }

      h3 {
        align-items: center;
        display: flex;
        padding: 15px;

        .close {
          color: $donkergrijs;
          cursor: pointer;
          display: block;
          font-size: 0.5em;
          line-height: inherit;
          margin-left: auto;
          text-align: right;
          text-align: center;
          width: 30px;

          &:hover {
            color: $antraciet;
          }
        }
      }

      @media (min-width: $breakpoint-md) {
        bottom: auto;
        left: auto;
        position: absolute;
        right: 0px;
        top: 45px;
        width: 400px;

        .arrow {
          display: block;
        }
      }
    }

    .minicart-empty {
      padding: 25px;
      text-align: center;

      h2 {
        margin-bottom: 0;
      }
    }

    .minicart__items {
      display: block;
      flex-direction: column;
      // max-height: 400px;
      // overflow-y: scroll
      overflow: hidden;

      @media (min-height: 60vh) {
        max-height: 60vh;
        overflow-y: scroll;
      }

      .minicart__item {
        align-items: center;

        display: grid;
        grid-template-columns: 100px 1fr min-content;

        left: 0px;
        padding: 10px 20px;
        position: relative;
        transition: left 0.2s ease-in-out;

        &:nth-child(even) {
          background-color: $lichtgrijs;
        }

        .info {
          flex-grow: 1;
          padding: 0px 10px;

          .name {
            color: $blauw;
            display: block;
            font-size: 1.2em;
            font-weight: 200;
            line-height: 1;

            strong {
              display: block;
              font-weight: 600;
            }

            @media (max-width: 400px) {
              font-size: 0.9em;
            }
          }

          .maanden {
            display: block;
            font-size: 0.7em;
          }
        }

        .aantal {
          width: 44px;
          position: relative;
          top: -2px;
        }

        .remove {
          background-color: #f1f1f1;
          color: black;
          cursor: pointer;
          display: none;
          font-size: 10px;
          height: 90px;
          left: -20px;
          line-height: 90px;
          position: absolute;
          text-align: center;
          top: 0px;
          width: 20px;

          &:hover {
            background-color: $rood;
            color: white;
          }
        }

        &:hover {
          left: 20px;

          .remove {
            display: block;
          }
        }
      }
    }

    .minicart__footer {
      align-items: center;
      border-top: 1px solid #f1f1f1;
      display: flex;
      padding: 10px 20px;

      .aantal {
        color: $donkergrijs;

        strong {
          font-weight: 600;
        }
      }

      .price__container {
        align-self: flex-end;
        margin-left: auto;
        width: auto;
      }
    }

    .btn {
      display: block;
      height: auto;
      margin: 0px 10px;
      margin-bottom: 10px;
      width: calc(100% - 20px);
    }
  }
}

.header-spacer {
  display: block;
  height: 100px;
  width: 100%;
}

.bigmenu {
  background: white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  display: none;
  left: 0;
  padding: 50px 25px;
  padding-top: 25px;
  position: fixed;
  top: 140px;
  transition: height 2s ease-in-out;
  width: 100%;
  z-index: 21;

  h3 {
    color: $rood;
    font-size: 1em;
    margin-bottom: 15px;

    .fa {
      margin-left: 10px;
    }
  }

  ul {
    list-style: none;

    li {
      a {
        color: #666;
        display: block;
        font-size: 0.9em;
        line-height: 40px;
        text-align: left;
        width: 100%;

        &:hover {
          color: $rood;
        }
      }
    }

    .alle {
      display: none;
    }

    .all a {
      color: $blauw;
      font-size: 0.8em;
      font-weight: 600;

      .fa {
        margin-left: 10px;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .fa {
    font-size: 80%;
  }
}

.price__container {
  align-items: center;
  display: block;
  text-align: right;

  .vanaf {
    color: $blauw;
    display: block;
    font-size: 0.6em;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
  }

  .old_price {
    color: $blauw;
    font-size: 0.8em;
    font-weight: 400;
    text-decoration: line-through;
  }

  .price {
    color: #c80330;
    display: block;
    font-size: 1em;
    line-height: 1em;
    font-weight: 600;
    margin-bottom: 3px;
    white-space: normal;
  }

  .btw {
    color: #ccc;
    display: block;
    font-size: 0.6em;
    line-height: 1;
  }
}

//Vue transition - Slide
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter, .slide-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
}

@media (min-width: $breakpoint-md) {
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-in-out;
  }
  .slide-enter, .slide-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(0%);
  }
}

.logged-in .header {
  //margin-top: 32px;
}

.header__searchform {
  margin-right: 50px;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 9999;
  width: calc(100% - 50px);
  top: 0;

  @media (min-width: $breakpoint-xl) {
    top: 1px;
    width: 50px;
    margin-right: 15px;
  }

  &.is-open {
    width: calc(100% - 50px);
    background: #fff;

    .header__searchform-button {
      display: none;
    }

    #searchform {
      //transform: translateX(0%);
      //transition: transform .3s ease-out;
    }
  }

  .header__searchform-button {
    background-color: white;
    border: none;
    color: $blauw;
    display: none;
    height: 50px;
    float: right;
    transition: 0.2s ease-out;
    width: 50px;
    z-index: 2;
    border-radius: 3px;

    @media (min-width: $breakpoint-lg) {
      display: block;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: $rood;

      color: white;
      transition: 0.2s ease-out;
    }
  }

  [type='submit'] {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 3px 3px 0;
  }

  #searchform {
    margin-left: auto;
    position: relative;
    right: 0;
    top: 0;
    transition: transform 0.3s ease-in;
    border-radius: 3px;
    //transform: translateX(0%);

    @media (min-width: $breakpoint-lg) {
      display: none;
      //	transform: translateX(100%);
    }
  }

  ul.suggestions {
    display: none;
    flex-direction: column;
    padding-right: 0;

    li {
      width: 100%;
    }
  }
}
