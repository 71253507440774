@use "../partials/settings" as *;

.archive, .page, .error404 {
    .archive-header {
        color: $blauw;
        display: inline-block;
        line-height: 1;
    }

    .omschrijving {
        padding: 25px;
        background-color: white;
        margin-bottom: 30px;

        h1 {
            margin-bottom: 15px;
        }
        &.deals {
            background-color: $deal;
            color: white;
            width: 100%;
            padding: 25px;
            margin-bottom: 25px;
        
            h1 {
                color: white;
                margin-bottom: 15px;
        
                .far {
                    font-size: inherit;
                }
            }
        
            .clock {
                font-size: 180px;
                position: absolute;
                top: -10px;
                right: -10px;
                opacity: 0.5;
            }
        }
    }
    .btn-filters {
        width: 100%;
        margin-top: 0;
        margin-bottom: 25px;
        background: white;
        color: $antraciet;
        border-color: white;
        display: flex;
        padding-right: 15px;
        padding-left: 20px;

        .fa {
            margin-left: auto;
        }

        @media(min-width: $breakpoint-xl) {
            display: none;
        }
    }
    .filters {
        width: 100%;
        height: 100vh;
        display: block;
        flex-direction: column;
        background: white;
        padding: 25px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;

        overflow-y: scroll;
        width: 100%;
        max-width: 500px;
        -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
        left: -100%;
        transition: left 0.2s ease-in-out;
        padding-bottom: 75px;

        @media (max-width: $breakpoint-xl) {
            top: 155px;
            height: calc(100% - 155px);
        }

        .filter__inner {
            width: 100%;
            display: block;
            //overflow: scroll;
        }

        .filter-chang-btn {
            width: 100%;
            display: block;
            margin: 0;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 20;
            max-width: 500px;

            @media(min-width: 1200px) {
                position: relative;
            }

            &.active {
                display: block;
            }
        }

        .filters__overlay {
            position: fixed;
            top: 0;
            left: 80%;
            width: 20%;
            height: 100%;
            display: none;
      
            @media(min-width: $breakpoint-xl) {
              display: none;
            }
        }

        &.active {
            left: 0%;

            .filters__overlay {
                display: block;
            }
        }

        .filters__close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 1em;
            background-color: rgba(0,0,0,0.05);
            color: #666;
      
            @media(min-width: $breakpoint-xl) {
              display: none;
            }
          }

        .filter {
            background: white;
            padding: 0px;
            padding-top: 25px;
            margin-bottom: 15px;
            border-top: 1px solid rgba(black, 0.05);

            h3 {
                color: $blauw;
                position: relative;
                cursor: pointer;
                .arrow {
                    float: right;
                    font-size: 12px;
                    line-height: 25px;
                    top: 50%;
                    transform:  translate(-50%, 0%);
                }

                &:hover {
                    color: darken($blauw, 10%);
                }
            }

            .text {
                display: none;
            }

            &.active {
                .text {
                    display: block;
                }

                h3 {
                    color: $rood;
                    margin-bottom: 15px;

                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            &.head {
                border-top: none;
                padding-top: 0;
            }
        }

        @media(min-width: $breakpoint-xl) {
            display: flex;
            left: 0;
            position: relative;
            width: 100%;
            background: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            padding: 0;
            overflow: hidden;
            height: auto;
            z-index: 1;
            
            .filter {
                padding: 25px;
                border-top: none;

                &.head {
                    padding: 25px;
                }
            }
        }
    }
}

.orderby {
    width: 1000%;
    max-width: 300px;
    display: flex;
    margin-bottom: 15px;
    float: right;
    justify-content: flex-end;

    label {
        line-height: 50px;
        white-space: nowrap;
        color: $blauw;
        font-size: 0.8em;
    }

    select {
        appearance: auto;
        width: 100%;
        padding: 0;
        width: 50%;
        padding-left: 15px;
        border: none;
        font-weight: bold;
    }
}