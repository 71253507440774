@use 'settings' as *;

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	line-height: 1.4;
	margin: 0;
	padding: 0;
}

body {
	background: $lichtgrijs;
	opacity: 0;

	&.noscroll {
		overflow-y: hidden;
	}
}

.flexbox {
	display: flex;
	justify-content: center;
	
	&-middle {
		align-items: center;
		justify-content: center;
	}
	
	&-start {
		justify-content: flex-start;
	}
	
	&-start-center {
		align-items: center;
		justify-content: flex-start;
	}
	
	&-column {
		flex-direction: column;
	}
	
	&-wrap {
		flex-wrap: wrap;
	}
}

.row-reverse {
	flex-direction: row-reverse;
}


@media(min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

@media(min-width: 1500px) {
	.container {
		max-width: 1500px;
	}
}

.page-contact {
	section.section {
		background: $lichtgrijs;
		padding: 50px 0;

		&:nth-child(even) {
			background: white;
		}
	}
}

section.section {
	background: white;
	padding: 50px 0;
	
	&--light {
		background: white;
	}
	
	&:nth-child(even) {
		background: $lichtgrijs;
	}

	&.vehicle {
		background: $lichtgrijs;
	}
	
	.section__title {
		text-align: center;
	}
	
	&.section--grijs {
		background: $lichtgrijs;
	}
	
	h2 {
		text-align: center;
	}
}

.text {
	&-center {
		text-align: center;
	}
	
	&-light {
		color: $donkergrijs;
	}
	
	&-small {
		font-size: 16px;
		line-height: 22px !important;
	}
}

hr {
	border: 1px solid $lichtgrijs;
}

figure {
	margin-bottom: 0;
}

.mb0 {
	margin-bottom: 0 !important;
}

.content {
	padding: 25px;
	background: white;
	
	@media (min-width: $breakpoint-lg) {
		padding: 40px;
	}
	
	img {
		width: 100%;
	}
	
	&__text {
		margin-bottom: 30px;
	}
}

.mb30 {
	margin-bottom: 30px;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

a.mobile-only {
	@media(max-width: $breakpoint-md) {
		display: none !important;
	}
}


.text-success {
	color: $success;
}

.text-alert {
	color: $alert;
}

.text-danger {
	color: $danger;
}

.grey {
	color: $donkergrijs;
}

.noscroll {
	overflow: hidden;
}

.breadcrumbs {
	margin-top: 5px;
	font-size: 0.7em;
	color: #888;
	
	a {
		color: inherit;
	}
	
	.fa {
		font-size: 80%;
	}
	
	strong {
		font-weight: 500;
		font-size: inherit;
	}
}