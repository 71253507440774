@use 'settings' as *;

.search-results {
	.card.card-horizontal,
	.card.card-horizontal .text
	{
		background: $lichtgrijs;
	}
}

.seachform {
	width: 100%;
	display: flex;
	flex-direction: row;
	position: relative;

	@media (min-width: $breakpoint-xl) {
		max-width: 520px;
	}

	input {
		width: calc(100% - 50px);
		height: 50px;
		border: 2px solid #DDDDDD;
		border-right: none;
		background-color: white;
		padding: 10px 20px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		outline: none;
	}
	
	button {
		width: 50px;
		height: 50px;
		background-color: $rood;
		color: white;
		border: none;
		position: relative;
		z-index: 2;
	}
	
	.suggestions {
		position: absolute;
		top: calc(100% - 0px);
		width: calc(100% - 0px);
		background-color: white;
		border: 2px solid #DDDDDD;
		border-top: 1px solid #f7f7f7;
		list-style: none;
		display: none;
		z-index: 10;
		
		li a {
			padding: 12px;
			color: $antraciet;
			display: block;
			font-size: 0.8em;
			font-weight: normal;
			
			&::after {
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f105";
				float: right;
				color: inherit;
				font-size: inherit;
			}
			
			&:hover {
				background-color: #f7f7f7;
			}
		}
	}
}