@use "../partials/settings" as *;

section.section.intro-actiepagina {
	max-height: 550px;
	min-height: 50vh;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	flex-direction: column;
	
	&--small {
		max-height: 330px;
		min-height: 50vh;

		@media(min-width: $breakpoint-lg) {
			min-height: 30vh;
		}
	}
	
	&--micro {
		max-height: 150px;
		min-height: 150px;
		background: $blauw;
	}
	
	.container, .row {
		height: 100%;
	}
	
	.bg {
		height: 100%;
		left: 50%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: auto;
		}
		
		@supports (-ms-accelerator:true) {
			height: auto;
		}
	}
	
	.gradient {
		background: linear-gradient(to right, rgba(black, .5) 0%, rgba(black, .2) 100%);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		
		@media (min-width: $breakpoint-lg) {
			background: linear-gradient(to right, rgba(black, .5) 0%, rgba(black, 0) 100%);
			width: 66%;
		}
	}
	
	.text {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		
		h1 {
			margin-bottom: 15px;
			
			@media (min-width: $breakpoint-lg) {
				font-size: 65px;
				line-height: 70px;
			}
		}
		
		p {
			
			@media (min-width: $breakpoint-sm) {
				width: 66%;
			}
			
			@media (min-width: $breakpoint-lg) {
				width: 50%;
			}
		}
		
		.btn {
			margin-top: 0;
			@media (min-width: $breakpoint-md) {
				align-self: flex-start;
			}
		}
	}
}
section.section.prijs_blok {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    background-color: #E1EAED;
    .prijs-cta-blok {
        height: 100%;

        .prijs {
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            font-weight: 100;
        }

        .btn {
            margin-top: 0px;
            @media(max-width:$breakpoint-md) {
                width: 100%;
            }
        }
    }
}

section.section.explore_blok {
    background-color: #fff !important;
    .titel {
        text-align: left;
    }

    ul li {
        line-height: 1.8em;
        list-style: none;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 50px;
        position: relative;
        font-family: 'Roboto';
        font-weight: 100;
        font-size: 19px;
        padding-bottom: 15px;
        border-bottom: 2px solid #f9fafb;

        &:last-child {
            border-bottom: none;
        }
        
        .fa {
            color: $blauw;
            font-size: 1.2em;
            left: 0;
            position: absolute;
            top: 7px;

            @media(max-width: $breakpoint-md) {
                top: 4px;
            }
        }

        @media(max-width: $breakpoint-md) {
            margin-top: 40px;
            font-size: 15px;
            padding-left: 30px;
            
        }
    }
}
.top-blok, .second-menu {
    background: #fff;
    height: auto;
    display: flex;
    overflow-x:auto;
    scroll-snap-type: x mandatory;
    margin-bottom: -20px;

    .nav-item, .second-nav-item {
        min-width: fit-content;
        width: fit-content;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        gap: 12px;
        height: auto;
        justify-content: center;
        opacity: .24;
        padding: 16px 20px;
        position: relative;
        text-align: center;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 2;
        scroll-snap-align: start;

        &.active {
            background: #F9FAFB;
            color: #004666;
            opacity:1;
            border-radius: 4px 4px 0 0;
        }

        &.active::after {
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            bottom: 0;
            content: "";
            height: 2px;
            position: absolute;
            background: #004666;
        }
    }
}

.second-menu {
    display: none; 
    position: relative;
    top: 20px;
    background: none;
  
    &.active {
        display: flex;       
    }
}
section.section:nth-child(even) {
    background: unset;
}

section.section-highlight {
    background-color: #F9FAFB;
    display: none;

    &.active {
        display: flex;
    }

    .content-blok {
        padding: 48px 60px;

        @media(max-width: $breakpoint-lg) {
            padding: 48px 0px;
        }
        .bg {
            margin-bottom: 30px;
            width: 100%;
            box-sizing: border-box;
            height: auto;
            max-width: 100%;
            vertical-align: bottom;
            display:block;
            
            @media(min-width: $breakpoint-lg) {
                margin-bottom: 0px;
            }
        }

        .titel {
            text-align: left;
            font-size: 20px;
            @media(min-width: $breakpoint-lg){
                font-size: 24px;
            }
        }
    }
}

section.section-highlight.opties {
    ul {
        font-style: normal;
        font-weight: 300;
        line-height: auto;
        list-style-type: disc;
        padding-left: 1em;
        margin-bottom: 20px;
        li::marker {
            font-size: 25px;
            color: #C70330;
        }

        li {
            padding-left: 10px;
            font-size: 20px;
            line-height: 1.5em;
        }
    }
}

section.section-fotogalerij {
    padding-top: 100px;

    @media(max-width: $breakpoint-md) {
        padding-top: 50px;
    }
    .slider {
        position: relative;
    }

    .slider {
        .slick-track {
            display: flex !important;
        }

        .slick-list {
            margin: 0 -12px;
        }

        .slick-arrow {
            appearance: none;
            border: 0;
            color: #FFFFFF;
            cursor: pointer;
            margin: 0;
            top: calc(50% - 21.5px);
            outline: 0;
            border-radius: 200px;
            position: absolute;
            z-index: 2;
            height: 43px;
            width: 43px;
            background: #C80230;
            
            i {
                font-size: 1em;

                @media(max-width: $breakpoint-md) {
                    font-size: .7em;
                    position: relative;
                    top: -2px;
                }
            }
            
            &.slick-prev {
                @media(max-width: $breakpoint-md) {
                    height: 23px;
                    width: 23px;
                    top: calc(50% + 118.5px);
                    right: 40px;
                }
                @media(min-width: $breakpoint-md) {
                    left: -60px;
                    top: calc(50% - 44px);
                    padding-left: 0;
                }

                @media(min-width: $breakpoint-xl) {
                    left: -95px;
                    padding-left: 0;
                }

                @media(min-width: 1500px) {
                    left: -125px;
                    padding-left: 0;
                }
            }
            
            &.slick-next {
                @media(max-width: $breakpoint-md) {
                    height: 23px;
                    width: 23px;
                    top: calc(50% + 118.5px);
                    right: 0px;
                }
                @media(min-width: $breakpoint-md) {
                    right: -60px;
                    top: calc(50% - 44px);
                    padding-right: 0;
                }

                @media(min-width: $breakpoint-xl) {
                    right: -95px;
                    padding-right: 0;
                }

                @media(min-width: 1500px) {
                    right: -125px;
                    padding-right: 0;
                }
            }
        }

        .slick-slide {
            height: inherit !important;
            margin: 0 12px;
        }

        .slick-dots {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 18px; 
            padding-bottom: 51px;
        
            li {
                font-size: 0;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                background-color: #DFD4B3;
                margin: 0px 5px;
                display: inline-block;

                &:only-child {
                    display: none;
                }
            }
        
            > li button {
                display: none;
            }
        }
    }

    .slide {
        width: 100%;
        display: table;
        padding-top: 0;
        height: auto;
        padding-bottom: 47px;

        .image {
            border-radius: 3px;
            max-height: 292px;
            text-align: center;
            height: 0;
            padding-top: 52%;
            overflow: hidden;
            position: relative;
        
            img {
                height: 100%;
                left: 50%;
                object-fit: cover;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }
    }
}

section.section-dezehuren {
    background-color: unset;
    @media(min-width: $breakpoint-lg) {
        padding-top: 96px;
        padding-bottom: 96px;
    }
 
    .titel {
        text-align: left;
    }

    .tekst {
        @media(max-width: $breakpoint-lg) {
            margin-bottom: 30px;
        }
    }
}

.section.section-usps {
    background-color: #FFF !important;
    padding:0px;
    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; /* Vertically align the items */
        width: 100%;
        height: 50px;

        @media(max-width: $breakpoint-lg) {
            flex-direction: column;
            height: max-content;
            margin-bottom: 30px;
            margin-top: 30px;
            align-items: baseline;
        }

        li {
            display: flex;
            align-items: center; /* Vertically align the text */
            font-size: 19px;
            font-family: 'Roboto';
            font-weight: 500;
            margin-right: 20px;

            @media(max-width: $breakpoint-lg) {
                margin-bottom: 10px;
            }

            img {
                margin-right: 20px;
            }
        }
    }
}