@use "../partials/settings" as *;

.page-id-165 {
  .card {
    .text {
      padding-bottom: 13px;
  }
  
    .card__checkbox {
      display: none;
    }
  }
  
  
}

.vergelijk__item {
    .vergelijk__head {
        position: relative;

        .vehicle__label {
            position: absolute;
            right: auto;
            left: 0;
        }

        .vergelijk__remove {
            position: absolute;
            right: -13px;
            top: -13px;
            background-color: white;
            width: 26px;
            height: 26px;
            color: black;
            text-align: center;
            line-height: 26px;
            font-size: 10px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 30;

            &:hover {
                background-color: $rood;
                color: white;
            }
        }
    }
}