@use "../partials/settings" as *;

.page {
    .single-item {
        background-color: white;
        padding: 25px;

        @media(min-width: $breakpoint-lg) {
            padding: 50px;
        }

        h1,h2,h3 {
            text-align: left;
        }
    }
}