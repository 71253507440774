@use "../partials/settings" as *;

.merken {
	h2 {
		color: $rood;
		margin-bottom: 60px;
	}
}

.card-cars {
	display: flex;
	flex-wrap: wrap;
	
	&__card {
		align-items: center;
		border: 1px solid #E7E7E7;
		border-radius: 3px;
		display: flex;
		height: 80px;
		justify-content: center;
		margin: 5px;
		position: relative;
		transform-origin: bottom center;
		transition: .15s ease-out;
		width: calc(50% - 5px);
		
		&:hover {
			box-shadow: 0 2px 15px 6px rgba(#97A0B2, .2);
			transition: .15s ease-in;
			z-index: 1;
			
			@media (min-width: $breakpoint-md) {
				box-shadow: 0 2px 30px 6px rgba(#97A0B2, .4);
				transform: scale(1.05);
			}
		}
		
		&:nth-child(odd) {
			margin-left: 0;
		}
		
		&:nth-child(even) {
			margin-right: 0;
		}
		
		@media (min-width: $breakpoint-md) {
			border: 0;
			border-radius: 0;
			height: auto;
			margin: 0;
			width: 20%;
		}

		&.disabled {
			opacity: 0.2;
			// filter: grayscale(100%);
			cursor: unset;

			&:hover {
				transform: scale(1);
				box-shadow: none;
			}
		}
	}
	
	figure {
		display: none;
		height: 0;
		padding-top: 175%;
		position: relative;
		width: 100%;
		
		@media (min-width: $breakpoint-md) {
			display: block;
		}
		
		img {
			height: 100%;
			left: 50%;
			object-fit: cover;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
	
	&__logo {
		height: 60px;
		width: 80px;
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		
		@media (min-width: $breakpoint-md) {
			left: 50%;
			max-height: 80px;
			position: absolute;
			top: 12px;
			transform: translateX(-50%);
			width: 35px;
		}
		
		@media (min-width: $breakpoint-lg) {
			width: 50px;
			top: 26px;
		}
		
		@media (min-width: $breakpoint-xl) {
			width: 65px;
			top: 37px;
		}
		
		@media (min-width: $breakpoint-xxl) {
			width: 95px;
			top:58.5px;
		}
	}
}

.merk-rotatie {
	opacity: 0;
	* {
		font-size: inherit;
	}
}

.merken-page {
	a {
		width: 100%;
		padding: 10px;
		.fa {
			float: right;
		}
	}
	ul {
		list-style: none;
		padding-bottom: 25px;
	}
}