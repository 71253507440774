@use "../partials/settings" as *;

.testimonials {
    .card {
        padding: 25px;
        figure {
            width: 70%;
            height: 0;
            padding-top: 70%;
            border: 2px solid $blauw;
            border-radius: 50%;
            margin: 0px auto;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .stars {
            color: $geel;
            display: table;
            margin: 0 auto;
        }

        h3 {
            text-align: center;
        }
    }
}