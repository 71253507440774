@use "settings"as *;

@font-face {
	font-family: 'seat';
	src: url(../fonts/SEATBCN-Light.woff2) format('woff2');
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: 'seat';
	src: url(../fonts/SEATBCN-Regular.woff2) format('woff2');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'seat';
	src: url(../fonts/SEATBCN-Medium.woff2) format('woff2');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'seat';
	src: url(../fonts/SEATBCN-CondensedBold.woff2) format('woff2');
	font-style: normal;
	font-weight: 700;
}

#seat-form * {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'seat', sans-serif;
	font-size: 18px;
	line-height: 1.5;
	padding: 0;
	color: #0f0f0f;
}

#seat-form {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 .625rem;
	background: #fafaf9;

	h1 {
		font-size: 1.8em;
		margin-bottom: 0.5em;
		font-weight: 700;
		line-height: 1.1;

		@media (min-width: 768px) {
			font-size: 2.3em;
		}
	}

	h6 {
		font-size: 1em;
		margin-bottom: 0;
	}

	p {
		font-weight: 100;
		margin: 0 0 1em;
	}

	>.form-fields {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-flow: row wrap;

		>.form-row {
			width: 100%;
			margin-bottom: 1em;
			position: relative;

			&.-w50 {
				@media (min-width: 768px) {
					width: 50%;

					&.-first {
						padding-right: 10px;
					}

					&.-last {
						padding-left: 10px;
					}
				}
			}

			&.-w33 {
				@media (min-width: 768px) {
					width: calc(100% / 3);
					padding: 0 10px;

					&.-first {
						padding-left: 0;
					}

					&.-last {
						padding-right: 0;
					}
				}
			}
		}

		input:not(.checkbox):not([type=submit]),
		select {
			background: #fff;
			width: 100%;
			appearance: none;
			border: 2px solid #becdd3;
			border-radius: 0.25em;
			height: auto;
			font-size: 1em;
			-webkit-transition: border-color .15s ease-out;
			transition: border-color .15s ease-out;
			padding: 0.5em 0.75em;
			font-weight: 100;
			color: #0f0f0f;

			&:focus,
			&:focus-visible {
				border-color: #000;
				outline: 0;
			}
		}

		input.error,
		select.error {
			border-color: #e4002c !important;
			outline-color: #e4002c !important;
		}

		label {
			font-weight: 700;
			display: block;
			font-size: 1em;
			margin-bottom: .5em;

			&.error {
				margin: 0;
				font-size: .8em;
				color: #e4002c;
				padding-top: 0.25em;
				font-weight: 100;
			}
		}

		.select-arrow {
			width: 12px;
			position: absolute;
			right: 20px;
			top: 50px;

			svg {
				width: 100%;
				fill: #becdd3;
			}
		}

		input.checkbox {
			display: none;

			&:checked {
				+label::before {
					background: #ea5d1a;
					border-color: #ea5d1a;
					content: '✓';
					color: white;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			&.error {
				+label::before {
					border-color: #e4002c;

				}
			}
		}

		label.checkbox {
			position: relative;
			padding-left: 2em;
			font-weight: 400;
			cursor: pointer;

			&:focus,
			&:hover {
				&::before {
					border-color: #000;
				}
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				background: #fff;
				height: 1.25em;
				width: 1.25em;
				border: 2px solid #ddd;
				transition: all .1s ease-out;
			}
		}

		a {
			color: #ea5d1a;
			text-underline-offset: 2px;
			font-weight: inherit;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		.button {
			background: #ea5d1a;
			color: #fff;
			border-color: transparent;
			text-decoration: none;
			appearance: none;
			transition: background-color .2s ease-in-out;
			cursor: pointer;
			font-weight: 700;
			border-radius: 0.125em;
			padding: 0.5em 1em;
			border-width: 2px;
			text-transform: none;
			margin: 0;

			&:hover {
				background-color: #ee7e49;
			}
		}

		#bot-check {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			height: 0;
			width: 0;
			z-index: -1;
		}
	}

	.feedback {
		display: block;
		text-align: center;
		font-size: 1.5em;

		>.subtitle {
			margin: 0 auto;
			max-width: 500px;
		}
	}

	ul {
		list-style: none;
		padding-left: .5em;
		margin-top: .5em;
		margin-bottom: 1.5em;
	}
}