@use "settings" as *;

footer.footer {
	background-color: #222222;
	color: white;
	line-height: 1.3;
	padding: 50px 0 25px;
	
	h3 {
		color: white;
		font-size:1em;
	}
	
	a {
		color: #ccc;
		font-size: 0.9em;
		
		&:hover {
			color: white;
		}
	}
	
	.logo {
		max-width: 140px;
		width: 100%;
	}
	
	.footer-item {
		display: flex;
		flex-direction: column;
		height: calc(100% - 25px);
		margin-bottom: 25px;
		width: 100%;
		
		h3 {
			margin-bottom: 5px;
			// line-height: 50px;
			cursor: pointer;


			&::after {
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f107";
				float: right;
				color: #666;
			}
		}

		.text {
			display: none;
		}

		&.hover .text {
			display: block;
		}

		&.hover h3::after {
			content: "\f106";
		}

		@media(min-width: $breakpoint-lg) {
			margin-bottom: 25px;
			h3 {
				line-height: 1;
				&::after {
					display: none;
				}
			}

			.text {
				display: block;
			}
		}

		p {
			margin-bottom: 0;
		}
		
		&__noheight {
			height: auto;
		}
		
		.bovag_container {
			display: block;
			margin-top: auto;
			margin-bottom: 25px;
			
			a {
				display: block;
				font-size: 0.9em;
				margin: 10px 0;
			}
			
			img {
				display: block;
				margin-top: 30px;
				width: 90px;
			}
			
			small {
				display: block;
				font-size: 0.6em;
				line-height: 1.05;
			}
		}
		
		ul {
			list-style: none;
			
			li {
				line-height: 2;
			}
		}
		
		&__socials {
			display: table;
			width: 100%;
			
			.social-icon {
				display: table;
				float: left;
				
				.fab {
					display: block;
					float: left;
					font-size: 25px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					width: 40px;
					
					&:hover {
						color: $blauw;
					}
				}
			}
		}
		
		&__vragen {
			display: table;
			width: 100%;
		}
	}
	
	.copyright {
		color: #848484;
		display: table;
		font-size: 0.7em;
		font-weight: 200;
		margin: 0 auto;
		padding: 25px;
		
		a {
			color: inherit;
			cursor: pointer;
			font-weight: inherit;
			
			&:hover {
				color: #333;
			}
		}
	}
}

.footer-spacer {
	height: 70px;
	width: 100%;
}