@use "settings" as *;

// Tarieven
.faq {
	.faq__vraag {
		font-size: 1em;
		line-height: 30px;
		cursor: pointer;
		margin-bottom: 15px;
		text-align: left;
		
		&:hover {
			color: #666;
		}
	}
	
	.circle {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		color: white;
		float: left;
		display: block;
		background-color: $rood;
		margin-right: 25px;
		
		&::after {
			content: "+";
			line-height: inherit;
			font-size: 0.8em;
		}
	}
	
	ul {
		list-style: none;
		margin-bottom: 25px;
		
		li a {
			font-weight: 500;
			color: $antraciet;
			text-decoration: underline;
			
			&:hover {
				color: $donkergrijs;
			}
		}
	}
	
	.text {
		display: none;

		@media (min-width: $breakpoint-xl) {
			padding-left: 55px;
		}
	}
	
	&.hover {
		h2 {
			margin-bottom: 15px;
		}
		.circle {
			&::after {
				content: "-";
			}
		}
		
		.text {
			display: block;
			margin-bottom: 25px;
		}
	}
}