@use 'settings' as *;

.easepick {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border: 1px solid rgba($blauw, 0.3);
  color: $blauw;
  font-size: 0.8em;
  background: none;

  &:focus {
    border: 1px solid #1d4f6e;
    outline: none;
  }
}

:host {
  --color-fg-primary: #1d4f6e;
  --color-bg-inrange: #adc4d4;
}
