@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local(Roboto-Light), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format("woff2");
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format("woff2");
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format("woff2");
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format("woff2");
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format("woff2");
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format("woff2");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format("woff2");
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}

body {
  background: #F9FAFB;
  opacity: 0;
}
body.noscroll {
  overflow-y: hidden;
}

.flexbox {
  display: flex;
  justify-content: center;
}
.flexbox-middle {
  align-items: center;
  justify-content: center;
}
.flexbox-start {
  justify-content: flex-start;
}
.flexbox-start-center {
  align-items: center;
  justify-content: flex-start;
}
.flexbox-column {
  flex-direction: column;
}
.flexbox-wrap {
  flex-wrap: wrap;
}

.row-reverse {
  flex-direction: row-reverse;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1500px;
  }
}
.page-contact section.section {
  background: #F9FAFB;
  padding: 50px 0;
}
.page-contact section.section:nth-child(even) {
  background: white;
}

section.section {
  background: white;
  padding: 50px 0;
}
section.section--light {
  background: white;
}
section.section:nth-child(even) {
  background: #F9FAFB;
}
section.section.vehicle {
  background: #F9FAFB;
}
section.section .section__title {
  text-align: center;
}
section.section.section--grijs {
  background: #F9FAFB;
}
section.section h2 {
  text-align: center;
}

.text-center {
  text-align: center;
}
.text-light {
  color: #888888;
}
.text-small {
  font-size: 16px;
  line-height: 22px !important;
}

hr {
  border: 1px solid #F9FAFB;
}

figure {
  margin-bottom: 0;
}

.mb0 {
  margin-bottom: 0 !important;
}

.content {
  padding: 25px;
  background: white;
}
@media (min-width: 992px) {
  .content {
    padding: 40px;
  }
}
.content img {
  width: 100%;
}
.content__text {
  margin-bottom: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

@media (max-width: 768px) {
  a.mobile-only {
    display: none !important;
  }
}

.text-success {
  color: green;
}

.text-alert {
  color: orange;
}

.text-danger {
  color: #CC0000;
}

.grey {
  color: #888888;
}

.noscroll {
  overflow: hidden;
}

.breadcrumbs {
  margin-top: 5px;
  font-size: 0.7em;
  color: #888;
}
.breadcrumbs a {
  color: inherit;
}
.breadcrumbs .fa {
  font-size: 80%;
}
.breadcrumbs strong {
  font-weight: 500;
  font-size: inherit;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 900;
}
.header .top-bar {
  align-items: center;
  background: #F9FAFB;
  display: flex;
  height: 55px;
  position: relative;
  top: 0;
  width: 100%;
  justify-content: flex-end;
}
.header .top-bar .btn-mijn {
  display: none;
  font-size: 12px;
  line-height: 37px;
  margin: 0;
  height: 38px;
}
@media (min-width: 1200px) {
  .header .top-bar .btn-mijn {
    display: inline-block;
  }
}
.header .top-bar ul.top-bar__menu {
  float: right;
}
.header .top-bar ul.top-bar__menu li {
  display: inline-block;
  list-style-type: none;
  margin-left: 15px;
}
.header .top-bar ul.top-bar__menu li a,
.header .top-bar ul.top-bar__menu li i {
  font-size: 12px;
  line-height: 18px;
  color: #2C2C2C;
}
@media (max-width: 415px) {
  .header .top-bar ul.top-bar__menu li a,
  .header .top-bar ul.top-bar__menu li i {
    font-size: 10px;
    line-height: 12px;
  }
}
@media (max-width: 357px) {
  .header .top-bar ul.top-bar__menu li a,
  .header .top-bar ul.top-bar__menu li i {
    font-size: 7.7px;
  }
}
.header .top-bar ul.top-bar__menu li a:hover {
  text-decoration: underline;
}
.header .top-bar ul.top-bar__menu li i {
  margin-right: 5px;
}
.header .header__main {
  background: white;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
}
@media (min-width: 768px) {
  .header .header__main {
    height: 100px;
  }
}
.header .header__main .flexbox {
  flex-wrap: nowrap;
}
.header .header__main .logo {
  margin-right: auto;
  max-width: 80px;
  width: 100%;
}
@media (min-width: 1500px) {
  .header .header__main .logo {
    max-width: 140px;
  }
}
.header .header__main .logo img {
  width: 100%;
}
.header .header__main .btn-menu {
  color: #1d4f6e;
  display: block;
  height: 53px;
  line-height: 53px;
  margin-left: 5px;
  padding: 0;
  padding-right: 25px;
  text-align: center;
}
.header .header__main .btn-menu .fa {
  font-size: 30px;
  line-height: 53px;
}
@media (min-width: 1200px) {
  .header .header__main .btn-menu {
    display: none;
  }
}
.header .header__main .btn {
  display: block;
  margin: 0;
}
.header .header__main .btn-reserveren {
  font-size: 0.7em;
  letter-spacing: 0.5px;
  padding: 0px 10px;
  margin-right: 5px;
  background: #1d4f6e;
  border-color: #1d4f6e;
}
.header .header__main .btn-reserveren:hover {
  color: #fff;
  background: rgb(18.3597122302, 50.0143884892, 69.6402877698);
  border-color: rgb(18.3597122302, 50.0143884892, 69.6402877698);
}
.header .header__main .btn-reserveren:last-of-type {
  margin-right: 0px;
}
@media (max-width: 344px) {
  .header .header__main .btn-reserveren {
    font-size: 0.5em;
    letter-spacing: 1px;
    margin-left: 5px;
    padding: 0px 10px;
  }
}
@media (min-width: 992px) {
  .header .header__main .btn-reserveren {
    font-size: 0.6em;
    letter-spacing: 1px;
    margin-right: 5px;
    padding: 0px 20px;
  }
  .header .header__main .btn-reserveren:last-of-type {
    margin-right: 0px;
  }
}
@media (min-width: 1500px) {
  .header .header__main .btn-reserveren {
    font-size: 0.8em;
    letter-spacing: 1px;
    margin-left: 5px;
    padding: 0px 25px;
  }
}
.header .header__main .btn-mijn {
  margin-right: 5px;
  padding: 0px 20px;
  display: inline-block;
}
@media (min-width: 992px) {
  .header .header__main .btn-mijn {
    font-size: 0.6em;
  }
}
@media (min-width: 1500px) {
  .header .header__main .btn-mijn {
    font-size: 0.8em;
  }
}
.header .nav {
  align-items: center;
  background-color: #1d4f6e;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  flex: 1 0 0;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: -100%;
  overflow-y: scroll;
  padding: 0px;
  padding-top: 50px;
  position: fixed;
  top: 0px;
  transition: left 0.2s ease-in-out;
  width: 80%;
  z-index: 20;
}
@media (min-width: 1200px) {
  .header .nav {
    overflow-y: visible;
  }
}
.header .nav .nav__close {
  color: #666;
  color: white;
  font-size: 1em;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
}
@media (min-width: 992px) {
  .header .nav .nav__close {
    display: none;
  }
}
.header .nav .nav__overlay {
  display: none;
  height: 100%;
  left: 80%;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
@media (min-width: 992px) {
  .header .nav .nav__overlay {
    display: none;
  }
}
.header .nav.active {
  left: 0%;
}
.header .nav.active .nav__overlay {
  display: block;
}
.header .nav ul {
  list-style: none;
  width: 100%;
}
.header .nav ul li {
  width: 100%;
}
.header .nav ul li a {
  border-top: 1px solid #497590;
  color: white;
  display: block;
  font-weight: 400;
  line-height: 80px;
  padding: 0px 25px;
  text-align: left;
  width: 100%;
}
.header .nav ul li.menu-item-has-children > a::after {
  content: "\f107";
  display: inline-block;
  float: right;
  font-family: "Font Awesome 5 Free";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
}
@media (min-width: 1200px) {
  .header .nav ul li.menu-item-has-children > a::after {
    float: none;
  }
}
.header .nav ul li.menu-item-has-children.hover > a::after {
  content: "\f106";
}
.header .nav ul li .sub-menu {
  display: none;
}
.header .nav ul li .sub-menu li {
  width: 100%;
}
.header .nav ul li.hover .sub-menu {
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
}
.header .nav ul li.current-menu-item a {
  background-color: rgba(255, 255, 255, 0.05);
}
@media (min-width: 1200px) {
  .header .nav ul li {
    width: auto;
  }
  .header .nav ul li a {
    border-top: 0;
    padding: 0px 20px;
    width: auto;
  }
  .header .nav ul li.menu-item-has-children {
    position: relative;
  }
  .header .nav ul li.menu-item-has-children > a::after {
    margin-left: 5px;
  }
  .header .nav ul li .sub-menu {
    background-color: white;
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
  }
  .header .nav ul li .sub-menu a {
    padding: 0px 20px;
    white-space: nowrap;
  }
  .header .nav ul li.hover .sub-menu {
    background-color: white;
    display: table;
  }
}
@media (min-width: 1200px) {
  .header .nav {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    height: auto;
    left: 0;
    padding: 0px;
    padding-left: 25px;
    position: relative;
    top: 0;
  }
  .header .nav .btn {
    display: none;
  }
  .header .nav ul {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 65px;
    width: 100%;
  }
  .header .nav ul li {
    display: table;
    float: left;
    width: auto;
  }
  .header .nav ul li.mobile-only {
    display: none;
  }
  .header .nav ul li a {
    color: #1d4f6e;
    font-size: 0.9em;
    line-height: 50px;
    padding: 0px 15px;
    width: auto;
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  .header .nav ul li a {
    padding: 0 11px;
  }
}
@media (min-width: 1200px) and (min-width: 1500px) {
  .header .nav ul li a {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) {
  .header .nav ul li a:hover {
    color: #C80330;
  }
  .header .nav ul li.submenu a::after {
    content: "\f107";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 80%;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    margin-left: 10px;
    text-rendering: auto;
  }
  .header .nav ul li.submenu a.active::after {
    content: "\f106";
  }
}
@media (min-width: 1500px) {
  .header .nav {
    padding-left: 50px;
  }
}
.header .switch {
  color: #ccc;
  display: none;
  font-size: 12px;
}
@media (min-width: 768px) {
  .header .switch {
    display: block;
  }
}
.header .switch u {
  color: #666;
  font-size: inherit;
}
.header .minicart {
  display: inline-block;
  margin: 0px 10px;
  padding: 0px 10px;
  position: relative;
}
.header .minicart .minicart__overlay {
  background: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 1200px) {
  .header .minicart {
    margin: 0px 20px;
  }
}
.header .minicart .cart-icon {
  color: #1d4f6e;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
}
.header .minicart .cart-icon:hover {
  color: rgb(18.3597122302, 50.0143884892, 69.6402877698);
}
.header .minicart.cart-empty {
  display: none;
}
.header .minicart .circle {
  cursor: pointer;
  background: #C80330;
  border-radius: 50%;
  top: -11px;
  color: white;
  display: block;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 0px;
  text-align: center;
  width: 20px;
}
.header .minicart .card {
  bottom: 0;
  display: table;
  height: auto;
  left: 0px;
  margin-bottom: 0;
  max-height: 80%;
  max-width: 768px;
  overflow: auto;
  padding: 25px;
  padding: 0;
  position: absolute;
  position: fixed;
  width: 100%;
  z-index: 30;
  box-shadow: 0 0 15px 5px rgba(136, 136, 136, 0.35);
}
.header .minicart .card .arrow {
  background: white;
  display: none;
  height: 15px;
  position: absolute;
  right: 15px;
  top: -7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 15px;
}
.header .minicart .card h3 {
  align-items: center;
  display: flex;
  padding: 15px;
}
.header .minicart .card h3 .close {
  color: #888888;
  cursor: pointer;
  display: block;
  font-size: 0.5em;
  line-height: inherit;
  margin-left: auto;
  text-align: right;
  text-align: center;
  width: 30px;
}
.header .minicart .card h3 .close:hover {
  color: #2C2C2C;
}
@media (min-width: 768px) {
  .header .minicart .card {
    bottom: auto;
    left: auto;
    position: absolute;
    right: 0px;
    top: 45px;
    width: 400px;
  }
  .header .minicart .card .arrow {
    display: block;
  }
}
.header .minicart .minicart-empty {
  padding: 25px;
  text-align: center;
}
.header .minicart .minicart-empty h2 {
  margin-bottom: 0;
}
.header .minicart .minicart__items {
  display: block;
  flex-direction: column;
  overflow: hidden;
}
@media (min-height: 60vh) {
  .header .minicart .minicart__items {
    max-height: 60vh;
    overflow-y: scroll;
  }
}
.header .minicart .minicart__items .minicart__item {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr min-content;
  left: 0px;
  padding: 10px 20px;
  position: relative;
  transition: left 0.2s ease-in-out;
}
.header .minicart .minicart__items .minicart__item:nth-child(even) {
  background-color: #F9FAFB;
}
.header .minicart .minicart__items .minicart__item .info {
  flex-grow: 1;
  padding: 0px 10px;
}
.header .minicart .minicart__items .minicart__item .info .name {
  color: #1d4f6e;
  display: block;
  font-size: 1.2em;
  font-weight: 200;
  line-height: 1;
}
.header .minicart .minicart__items .minicart__item .info .name strong {
  display: block;
  font-weight: 600;
}
@media (max-width: 400px) {
  .header .minicart .minicart__items .minicart__item .info .name {
    font-size: 0.9em;
  }
}
.header .minicart .minicart__items .minicart__item .info .maanden {
  display: block;
  font-size: 0.7em;
}
.header .minicart .minicart__items .minicart__item .aantal {
  width: 44px;
  position: relative;
  top: -2px;
}
.header .minicart .minicart__items .minicart__item .remove {
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;
  display: none;
  font-size: 10px;
  height: 90px;
  left: -20px;
  line-height: 90px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 20px;
}
.header .minicart .minicart__items .minicart__item .remove:hover {
  background-color: #C80330;
  color: white;
}
.header .minicart .minicart__items .minicart__item:hover {
  left: 20px;
}
.header .minicart .minicart__items .minicart__item:hover .remove {
  display: block;
}
.header .minicart .minicart__footer {
  align-items: center;
  border-top: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 20px;
}
.header .minicart .minicart__footer .aantal {
  color: #888888;
}
.header .minicart .minicart__footer .aantal strong {
  font-weight: 600;
}
.header .minicart .minicart__footer .price__container {
  align-self: flex-end;
  margin-left: auto;
  width: auto;
}
.header .minicart .btn {
  display: block;
  height: auto;
  margin: 0px 10px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
}

.header-spacer {
  display: block;
  height: 100px;
  width: 100%;
}

.bigmenu {
  background: white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  display: none;
  left: 0;
  padding: 50px 25px;
  padding-top: 25px;
  position: fixed;
  top: 140px;
  transition: height 2s ease-in-out;
  width: 100%;
  z-index: 21;
}
.bigmenu h3 {
  color: #C80330;
  font-size: 1em;
  margin-bottom: 15px;
}
.bigmenu h3 .fa {
  margin-left: 10px;
}
.bigmenu ul {
  list-style: none;
}
.bigmenu ul li a {
  color: #666;
  display: block;
  font-size: 0.9em;
  line-height: 40px;
  text-align: left;
  width: 100%;
}
.bigmenu ul li a:hover {
  color: #C80330;
}
.bigmenu ul .alle {
  display: none;
}
.bigmenu ul .all a {
  color: #1d4f6e;
  font-size: 0.8em;
  font-weight: 600;
}
.bigmenu ul .all a .fa {
  margin-left: 10px;
}
.bigmenu ul .all a .fa.rotate {
  transform: rotate(180deg);
}
.bigmenu .fa {
  font-size: 80%;
}

.price__container {
  align-items: center;
  display: block;
  text-align: right;
}
.price__container .vanaf {
  color: #1d4f6e;
  display: block;
  font-size: 0.6em;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
.price__container .old_price {
  color: #1d4f6e;
  font-size: 0.8em;
  font-weight: 400;
  text-decoration: line-through;
}
.price__container .price {
  color: #c80330;
  display: block;
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 3px;
  white-space: normal;
}
.price__container .btw {
  color: #ccc;
  display: block;
  font-size: 0.6em;
  line-height: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter, .slide-leave-to {
  transform: translateY(100%);
}

@media (min-width: 768px) {
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-in-out;
  }
  .slide-enter, .slide-leave-to {
    opacity: 0;
    transform: translateY(0%);
  }
}
.header__searchform {
  margin-right: 50px;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 9999;
  width: calc(100% - 50px);
  top: 0;
}
@media (min-width: 1200px) {
  .header__searchform {
    top: 1px;
    width: 50px;
    margin-right: 15px;
  }
}
.header__searchform.is-open {
  width: calc(100% - 50px);
  background: #fff;
}
.header__searchform.is-open .header__searchform-button {
  display: none;
}
.header__searchform .header__searchform-button {
  background-color: white;
  border: none;
  color: #1d4f6e;
  display: none;
  height: 50px;
  float: right;
  transition: 0.2s ease-out;
  width: 50px;
  z-index: 2;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .header__searchform .header__searchform-button {
    display: block;
  }
}
.header__searchform .header__searchform-button:focus {
  outline: none;
}
.header__searchform .header__searchform-button:hover {
  background-color: #C80330;
  color: white;
  transition: 0.2s ease-out;
}
.header__searchform [type=submit] {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 3px 3px 0;
}
.header__searchform #searchform {
  margin-left: auto;
  position: relative;
  right: 0;
  top: 0;
  transition: transform 0.3s ease-in;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .header__searchform #searchform {
    display: none;
  }
}
.header__searchform ul.suggestions {
  display: none;
  flex-direction: column;
  padding-right: 0;
}
.header__searchform ul.suggestions li {
  width: 100%;
}

footer.footer {
  background-color: #222222;
  color: white;
  line-height: 1.3;
  padding: 50px 0 25px;
}
footer.footer h3 {
  color: white;
  font-size: 1em;
}
footer.footer a {
  color: #ccc;
  font-size: 0.9em;
}
footer.footer a:hover {
  color: white;
}
footer.footer .logo {
  max-width: 140px;
  width: 100%;
}
footer.footer .footer-item {
  display: flex;
  flex-direction: column;
  height: calc(100% - 25px);
  margin-bottom: 25px;
  width: 100%;
}
footer.footer .footer-item h3 {
  margin-bottom: 5px;
  cursor: pointer;
}
footer.footer .footer-item h3::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  float: right;
  color: #666;
}
footer.footer .footer-item .text {
  display: none;
}
footer.footer .footer-item.hover .text {
  display: block;
}
footer.footer .footer-item.hover h3::after {
  content: "\f106";
}
@media (min-width: 992px) {
  footer.footer .footer-item {
    margin-bottom: 25px;
  }
  footer.footer .footer-item h3 {
    line-height: 1;
  }
  footer.footer .footer-item h3::after {
    display: none;
  }
  footer.footer .footer-item .text {
    display: block;
  }
}
footer.footer .footer-item p {
  margin-bottom: 0;
}
footer.footer .footer-item__noheight {
  height: auto;
}
footer.footer .footer-item .bovag_container {
  display: block;
  margin-top: auto;
  margin-bottom: 25px;
}
footer.footer .footer-item .bovag_container a {
  display: block;
  font-size: 0.9em;
  margin: 10px 0;
}
footer.footer .footer-item .bovag_container img {
  display: block;
  margin-top: 30px;
  width: 90px;
}
footer.footer .footer-item .bovag_container small {
  display: block;
  font-size: 0.6em;
  line-height: 1.05;
}
footer.footer .footer-item ul {
  list-style: none;
}
footer.footer .footer-item ul li {
  line-height: 2;
}
footer.footer .footer-item__socials {
  display: table;
  width: 100%;
}
footer.footer .footer-item__socials .social-icon {
  display: table;
  float: left;
}
footer.footer .footer-item__socials .social-icon .fab {
  display: block;
  float: left;
  font-size: 25px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}
footer.footer .footer-item__socials .social-icon .fab:hover {
  color: #1d4f6e;
}
footer.footer .footer-item__vragen {
  display: table;
  width: 100%;
}
footer.footer .copyright {
  color: #848484;
  display: table;
  font-size: 0.7em;
  font-weight: 200;
  margin: 0 auto;
  padding: 25px;
}
footer.footer .copyright a {
  color: inherit;
  cursor: pointer;
  font-weight: inherit;
}
footer.footer .copyright a:hover {
  color: #333;
}

.footer-spacer {
  height: 70px;
  width: 100%;
}

.btn {
  background: none;
  border: 2px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
  -webkit-appearance: none;
}
.btn.btn-primary {
  background: #C80330;
  border-color: #C80330;
  color: white;
}
.btn.btn-primary:hover {
  background: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  border-color: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  color: white;
}
.btn.btn-primary:hover a {
  text-decoration: none;
}
.btn.btn-white {
  border-color: white;
  color: white;
}
.btn.btn-white:hover {
  background: white;
  color: #C80330;
}
.btn.btn-secondary {
  border-color: #1d4f6e;
  color: #1d4f6e;
  padding: 0 25px;
  text-align: center;
}
.btn.btn-secondary:hover {
  background: #1d4f6e;
  color: white;
}
.btn.btn-secondary.btn-secondary--rood {
  border-color: #C80330;
  color: #C80330;
  padding: 0 25px;
  text-align: center;
}
.btn.btn-secondary.btn-secondary--rood:hover {
  background: #C80330;
  color: white;
}
.btn.btn-load {
  position: relative;
}
@keyframes example {
  from {
    width: 0%;
  }
  to {
    width: calc(100% + 4px);
  }
}
.btn.btn-load::after {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: example;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  position: absolute;
  top: -2px;
  width: 0%;
}
.btn.btn-small {
  transform: scale(0.75);
  transform-origin: left center;
}
.btn.btn-submit {
  min-width: 240px;
}
.btn.btn-login {
  margin-bottom: 10px;
}
.btn.btn-rental {
  border-radius: 16px;
  cursor: default;
  height: 32px;
  line-height: 22px;
  margin-right: 10px;
  margin-top: 0;
  padding: 3px 16px;
  text-transform: capitalize;
}
.btn.btn-rental:hover {
  background: #C80330;
  border-color: #C80330;
}
.btn.btn-location {
  margin-top: 0;
  width: 100%;
}
@media (min-width: 555px) {
  .btn.btn-location {
    width: 250px;
  }
}
.btn.btn-location i {
  margin-left: -7px;
  margin-right: 5px;
}
.btn:hover {
  background: white;
  color: #fcfcfc;
}
.btn .fa {
  font-size: inherit;
  line-height: inherit;
  position: relative;
}

button {
  cursor: pointer;
}
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

h1 {
  color: #2C2C2C;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 25px;
}

h2 {
  color: #2C2C2C;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 25px;
}

h3 {
  color: #2C2C2C;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

h4 {
  color: #2C2C2C;
  font-size: 1em;
  font-weight: 500;
}

span {
  font-size: inherit;
  font-weight: inherit;
}

p {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 25px;
}
p:last-child {
  margin-bottom: 0;
}
p:empty {
  display: none;
}

a {
  background-color: transparent;
  color: #C80330;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  text-decoration: none;
}
a:hover {
  color: rgb(124.6305418719, 1.8694581281, 29.9113300493);
}
a.small {
  font-size: 0.8em;
  text-decoration: underline;
}

.small {
  font-size: smaller !important;
}

.black {
  color: black !important;
}

.white {
  color: white !important;
}

.bold {
  font-weight: bold !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.success {
  color: green;
}

.alert {
  color: orange;
}

.danger {
  color: #CC0000;
}

.grey {
  color: #888888;
}

.rood {
  color: #C80330 !important;
}

.blauw {
  color: #1d4f6e !important;
}

.uppercase {
  text-transform: uppercase;
}

.error {
  color: #CC0000;
}

strong {
  font-size: inherit;
}

.slick-slider .slick-list .slick-track .slick-slide {
  height: 100%;
}
.slick-slider ul.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;
  width: 100%;
}
.slick-slider ul.slick-dots li {
  margin: 0 0.25rem;
}
.slick-slider ul.slick-dots li button {
  background-color: rgba(249, 250, 251, 0.75);
  border: none;
  border-radius: 100%;
  display: block;
  height: 1rem;
  padding: 0;
  text-indent: -9999px;
  width: 1rem;
}
.slick-slider ul.slick-dots li button:focus, .slick-slider ul.slick-dots li button:active {
  border: 0;
  outline: 0;
}
.merken .slider .slick-list {
  padding: 0 10% 0 0;
}
@media (min-width: 452px) {
  .merken .slider .slick-list {
    padding: 0 20% 0 0;
  }
}
@media (min-width: 992px) {
  .merken .slider .slick-list {
    margin: 0;
    padding: 0;
  }
}
.merken .slider .rechtsgebied__col {
  margin-bottom: 0;
  padding: 0 5px;
}
@media (min-width: 540px) {
  .merken .slider .rechtsgebied__col {
    padding: 0 15px;
  }
}
.merken .slider-alt .slick-list {
  padding: 0 10% 0 0;
}
@media (min-width: 452px) {
  .merken .slider-alt .slick-list {
    padding: 0 20% 0 0;
  }
}
@media (min-width: 992px) {
  .merken .slider-alt .slick-list {
    margin: 0;
    padding: 0;
  }
}
.merken .slider-alt .rechtsgebied__col {
  margin-bottom: 0;
  padding: 0 5px;
}
@media (min-width: 540px) {
  .merken .slider-alt .rechtsgebied__col {
    padding: 0 15px;
  }
}
.merken .slider-alt .slider-arrow {
  cursor: pointer;
  font-size: 38px;
  font-weight: bold;
  height: 38px;
  line-height: 38px;
  position: absolute;
  top: calc(50% - 19px);
  z-index: 10;
}
.merken .slider-alt .slider-prev {
  left: 5px;
}
@media (min-width: 768px) {
  .merken .slider-alt .slider-prev {
    left: -15px;
  }
}
.merken .slider-alt .slider-next {
  right: 5px;
}
@media (min-width: 768px) {
  .merken .slider-alt .slider-next {
    right: -15px;
  }
}

.news-slider .slick-list {
  padding: 0 15% 0 0;
}

.faq .faq__vraag {
  font-size: 1em;
  line-height: 30px;
  cursor: pointer;
  margin-bottom: 15px;
  text-align: left;
}
.faq .faq__vraag:hover {
  color: #666;
}
.faq .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  color: white;
  float: left;
  display: block;
  background-color: #C80330;
  margin-right: 25px;
}
.faq .circle::after {
  content: "+";
  line-height: inherit;
  font-size: 0.8em;
}
.faq ul {
  list-style: none;
  margin-bottom: 25px;
}
.faq ul li a {
  font-weight: 500;
  color: #2C2C2C;
  text-decoration: underline;
}
.faq ul li a:hover {
  color: #888888;
}
.faq .text {
  display: none;
}
@media (min-width: 1200px) {
  .faq .text {
    padding-left: 55px;
  }
}
.faq.hover h2 {
  margin-bottom: 15px;
}
.faq.hover .circle::after {
  content: "-";
}
.faq.hover .text {
  display: block;
  margin-bottom: 25px;
}

input[type=text],
input[type=email],
input[type=search],
input[type=number],
input[type=tel],
input[type=date],
select,
textarea {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border: 1px solid rgba(29, 79, 110, 0.3);
  color: #1d4f6e;
  font-size: 0.8em;
  background: none;
}

input[type=date] {
  padding-right: 15px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../img/angle-down.svg");
  background-size: 12px auto;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 17px;
}
select:focus {
  outline: none;
  border-color: #1d4f6e;
}

textarea {
  height: 150px;
}

.input-row {
  margin-bottom: 25px;
  position: relative;
}
.input-row label {
  position: absolute;
  background-color: white;
  font-size: 10px;
  padding: 3px 5px;
  left: 10px;
  top: -8px;
  color: #1d4f6e;
}
.input-row .feedback {
  margin-top: 3px;
  font-size: 12px;
  line-height: 12px;
  display: block;
  position: relative;
  color: #1d4f6e;
}
.input-row.error label {
  color: #CC0000;
}
.input-row.error input,
.input-row.error textarea,
.input-row.error select {
  border-color: #CC0000;
}
.input-row.error .feedback {
  color: #CC0000;
}

.radio-row {
  margin-bottom: 25px;
}
.radio-row label {
  font-size: 10px;
  color: #1d4f6e;
}
.radio-row ul {
  list-style: none;
}
.radio-row ul li + li {
  margin-left: 10px;
}
.radio-row ul li {
  display: inline-block;
}
.radio-row ul li label {
  font-size: 0.8em;
}

.input-row label .required,
.radio-row label .required {
  color: #C80330;
  margin-left: 3px;
}

.input-confirm {
  font-size: 1em;
  position: relative;
  color: #666;
  padding: 15px 25px;
}
.input-confirm:nth-child(even) {
  background: rgba(29, 79, 110, 0.04);
}
.input-confirm:first-of-type {
  margin-top: 10px;
}
.input-confirm h3 {
  font-size: inherit;
  color: #2C2C2C;
  margin-bottom: 0;
  font-weight: 600;
  padding: 0;
}

.input-aantal {
  display: flex;
}
.input-aantal .aantal-btn,
.input-aantal .days-amount-btn {
  width: 25px;
  height: 50px;
  line-height: 50px;
  background: #666;
  text-align: center;
  color: white;
  cursor: pointer;
  display: block;
}
.input-aantal .aantal-btn:hover,
.input-aantal .days-amount-btn:hover {
  background-color: #333;
}
.input-aantal .aantal-btn.min,
.input-aantal .days-amount-btn.min {
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
}
.input-aantal .aantal-btn.plus,
.input-aantal .days-amount-btn.plus {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.input-aantal input {
  width: 100%;
  max-width: 50px;
  appearance: none;
  border: 1px solid #f1f1f1;
  text-align: center;
  padding: 0px;
  -moz-appearance: textfield;
}
.input-aantal input::-webkit-outer-spin-button, .input-aantal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=checkbox] ~ label .checkbox {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  line-height: 30px;
  text-align: center;
  float: left;
  margin-right: 10px;
  border-radius: 3px;
}
input[type=checkbox] ~ label .checkbox .fa {
  opacity: 0;
  line-height: inherit;
  color: #888888;
  font-size: 0.9em;
}
input[type=checkbox] ~ label .checkbox:hover .fa {
  opacity: 0.2;
}

input[type=checkbox]:checked ~ label {
  border-color: #4C956C;
}
input[type=checkbox]:checked ~ label .checkbox .fa {
  opacity: 1;
  color: #4C956C;
}

.input-check {
  width: 100%;
  display: table;
  line-height: 0;
  margin-bottom: 3px;
  cursor: pointer;
}
.input-check input {
  display: none;
}
.input-check input[type=checkbox] ~ label {
  color: #1d4f6e;
  font-weight: 200;
  font-size: 14px;
}
.input-check input[type=checkbox] ~ label .checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #1d4f6e;
  line-height: 10px;
  text-align: center;
  float: left;
  margin: 0;
  margin-right: 10px;
  border-radius: 3px;
}
.input-check input[type=checkbox] ~ label .checkbox .fa {
  opacity: 0;
  line-height: inherit;
  color: #1d4f6e;
  font-size: 10px;
}
.input-check input[type=checkbox] ~ label .checkbox:hover .fa {
  opacity: 0.2;
}
.input-check input[type=checkbox]:checked ~ label {
  color: #C80330;
}
.input-check input[type=checkbox]:checked ~ label .checkbox {
  border-color: #C80330;
}
.input-check input[type=checkbox]:checked ~ label .checkbox .fa {
  opacity: 1;
  color: #C80330;
}

.car_types {
  width: calc(100% + 10px);
  display: flex;
  margin: 0px -5px;
}
.car_types .car_type__container {
  position: relative;
  width: 100%;
  padding: 5px;
}
.car_types .car_type {
  width: 100%;
  float: left;
  border: 2px solid white;
  font-size: 0.6em;
  display: block;
  text-align: center;
  position: relative;
  border-radius: 3px;
  padding: 20px 10px;
  cursor: pointer;
  color: #888888;
}
.car_types .car_type svg {
  fill: #888888;
}
.car_types .car_type .svg-box {
  width: calc(100% - 20px);
  margin: 0px 10px;
  display: block;
  padding-top: 83.225%;
  position: relative;
}
.car_types .car_type .svg-box svg {
  width: 100%;
  max-width: 100px;
  position: absolute;
  top: 50%;
  left: calc(50% + 1px);
  transform: translate(-50%, -50%);
}
.car_types .car_type:hover {
  border-color: #888888;
  color: #888888;
}
.car_types .car_type:hover svg {
  fill: #888888;
}
.car_types input {
  display: none;
}
.car_types input:checked ~ label.car_type {
  border: 2px solid #1d4f6e;
  color: #1d4f6e;
}
.car_types input:checked ~ label.car_type svg {
  fill: #1d4f6e;
}
.car_types input:checked ~ label.car_type.auto {
  border-color: #C80330;
  color: #C80330;
}
.car_types input:checked ~ label.car_type.auto svg {
  fill: #C80330;
}
.car_types input:checked ~ label.car_type.elektrisch {
  border-color: #4C956C;
  color: #4C956C;
}
.car_types input:checked ~ label.car_type.elektrisch svg {
  fill: #4C956C;
}

.range__slider {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.range__slider .input-row:first-child {
  margin-right: 3px;
}

fieldset {
  border: 0;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
}
fieldset h3 {
  margin-bottom: 25px;
  width: 100%;
}
fieldset p {
  font-size: 14px;
  margin-bottom: 25px;
  width: 100%;
}
fieldset .form-input {
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  fieldset .form-input {
    width: calc(50% - 5px);
  }
  fieldset .form-input:nth-of-type(even) {
    margin-left: auto;
  }
}
fieldset .form-input.wide {
  width: 100%;
}
fieldset .form-input label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  left: 15px;
  line-height: 14px;
  padding: 3px;
  position: absolute;
  top: -8px;
}
fieldset .form-input input,
fieldset .form-input textarea,
fieldset .form-input select {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
}
fieldset .form-input input:focus,
fieldset .form-input textarea:focus,
fieldset .form-input select:focus {
  outline-color: #1d4f6e;
}
fieldset .form-input input[type=search] {
  -webkit-appearance: none;
  border-radius: 0;
}
fieldset .form-input input,
fieldset .form-input select {
  height: 57px;
  line-height: 57px;
}

.wpforms-container .wpforms-form input[type=checkbox],
.wpforms-container .wpforms-form input[type=radio] {
  margin: 0 8px 0 3px !important;
}
.wpforms-container .wpforms-form input.wpforms-error {
  border-color: #CC0000 !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field {
  margin: 0;
  position: relative;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths {
  width: 100%;
}
@media (min-width: 768px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths {
    width: calc(50% - 5px);
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half:not(.wpforms-first), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths:not(.wpforms-first) {
    margin-left: 10px;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input {
  max-width: 100%;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input[type=checkbox], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-half input[type=radio], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input[type=checkbox], .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-two-fourths input[type=radio] {
  width: auto;
  margin: 0 6px 0 3px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field ul {
  height: 100% !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth {
  width: calc(50% - 5px);
  margin-left: 2.5px;
  margin-right: 2.5px;
}
@media (min-width: 768px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth {
    width: calc(25% - 7.5px);
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-one-fourth:not(.wpforms-first) {
    margin-right: 0;
    margin-left: 10px;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-has-error input,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-has-error textarea {
  border-color: #CC0000;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio > label, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox > label, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox > label {
  left: 0 !important;
}
@media (min-width: 992px) {
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox {
    width: 50%;
  }
  .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio.full-width, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox.full-width, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox.full-width {
    width: 100%;
  }
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio ul, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox ul, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul {
  padding-top: 15px !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-radio ul li input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-checkbox ul li input, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul li input {
  height: 13px !important;
  width: 13px !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field-gdpr-checkbox ul {
  height: auto !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field.wpforms-list-inline ul {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  font-weight: normal;
  left: 15px;
  line-height: 14px;
  margin: 0;
  -ms-overflow-style: none;
  overflow-x: scroll;
  padding: 2px 3px;
  position: absolute;
  scrollbar-width: none;
  top: 2px;
  white-space: nowrap;
  z-index: 1;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label::-webkit-scrollbar {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-label-inline {
  color: #1d4f6e;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-captcha-question {
  margin: 0 0 4px 0;
  top: 14px;
  position: relative;
  margin-bottom: 20px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-captcha-equation {
  top: 17px;
  position: relative;
  margin-bottom: 20px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-captcha-math {
  display: flex;
  flex-direction: column;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-captcha-math input {
  margin-left: 0px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-label-hide {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-label-hide + ul {
  padding-top: 0 !important;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-sublabel {
  display: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-focused .choices__inner,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-focused .choices__list,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-open .choices__inner,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-open .choices__list,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-active .choices__inner,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .is-active .choices__list {
  box-shadow: none;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner {
  padding: 0;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__list {
  z-index: 2;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__list--single {
  line-height: 57px;
  padding: 15px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]), .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=checkbox]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=radio]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=checkbox]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=radio]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=checkbox]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=radio]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=checkbox]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=radio]) {
  border: 1px solid #e7e7e7;
  margin-bottom: 0;
  padding: 15px;
  width: 100%;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]):not(.wpforms-error):focus, .wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=checkbox]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field textarea:not([type=radio]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=checkbox]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select:not([type=radio]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=checkbox]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not([type=radio]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=checkbox]):not(.wpforms-error):focus,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not([type=radio]):not(.wpforms-error):focus {
  border: 1px solid #1d4f6e;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select,
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox]),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not(textarea),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not(textarea),
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner {
  height: 57px;
  line-height: 57px;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field select[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=radio])[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input:not([type=checkbox])[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field-required:not(textarea)[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .wpforms-field:not(textarea)[type=file],
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field .choices__inner[type=file] {
  line-height: 1;
}
.wpforms-container .wpforms-form .wpforms-field-container .wpforms-field input[type=tel].wpforms-smart-phone-field {
  padding-left: 50px;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit] {
  background: #C80330;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  min-width: 240px;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit]:hover {
  background: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  color: white;
}
.wpforms-container .wpforms-form .wpforms-submit-container button[type=submit]:hover a {
  text-decoration: none;
}
.wpforms-container .wpforms-form .btn {
  background: none;
  border: 2px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.wpforms-container .wpforms-form .btn.btn-primary {
  background: #C80330;
  border-color: #C80330;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-primary:hover {
  background: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  border-color: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-primary:hover a {
  text-decoration: none;
}
.wpforms-container .wpforms-form .btn.btn-white {
  border-color: white;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-white:hover {
  background: white;
  color: #C80330;
}
.wpforms-container .wpforms-form .btn.btn-secondary {
  border-color: #1d4f6e;
  color: #1d4f6e;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 0 25px;
  text-align: center;
}
.wpforms-container .wpforms-form .btn.btn-secondary:hover {
  background: #1d4f6e;
  color: white;
}
.wpforms-container .wpforms-form .btn.btn-load {
  position: relative;
}
@keyframes example {
  from {
    width: 0%;
  }
  to {
    width: calc(100% + 4px);
  }
}
.wpforms-container .wpforms-form .btn.btn-load::after {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: example;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  position: absolute;
  top: -2px;
  width: 0%;
}
.wpforms-container .wpforms-form .btn.btn-small {
  transform: scale(0.75);
  transform-origin: left center;
}
.wpforms-container .wpforms-form .btn.btn-submit {
  min-width: 300px;
}
.wpforms-container .wpforms-form .btn.btn-login {
  margin-bottom: 10px;
}
.wpforms-container .wpforms-form .btn.btn-rental {
  border-radius: 20px;
  height: 40px;
  line-height: 30px;
  margin-right: 10px;
  margin-top: 0;
  padding: 5px 25px;
  text-transform: capitalize;
}
.wpforms-container .wpforms-form .btn.btn-location {
  margin-top: 0;
  width: 250px;
}
.wpforms-container .wpforms-form .btn:hover {
  background: white;
  color: #fcfcfc;
}
.wpforms-container .wpforms-form .btn .fa {
  font-size: inherit;
  line-height: inherit;
  position: relative;
}

#loginform,
#register-form {
  width: 100%;
}
#loginform .login-username,
#loginform .login-password,
#loginform .login-email,
#register-form .login-username,
#register-form .login-password,
#register-form .login-email {
  margin-bottom: 0;
  position: relative;
}
#loginform .login-username label,
#loginform .login-password label,
#loginform .login-email label,
#register-form .login-username label,
#register-form .login-password label,
#register-form .login-email label {
  background: white;
  color: #1d4f6e;
  font-size: 14px;
  font-weight: 400;
  left: 15px;
  line-height: 14px;
  padding: 3px;
  position: absolute;
  top: -8px;
}
#loginform .login-username input,
#loginform .login-password input,
#loginform .login-email input,
#register-form .login-username input,
#register-form .login-password input,
#register-form .login-email input {
  border: 1px solid #e7e7e7;
  height: 57px;
  line-height: 57px;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
}
#loginform .login-username input:focus,
#loginform .login-password input:focus,
#loginform .login-email input:focus,
#register-form .login-username input:focus,
#register-form .login-password input:focus,
#register-form .login-email input:focus {
  outline-color: #1d4f6e;
}
#loginform .login-submit #wp-submit,
#register-form .login-submit #wp-submit {
  background: none;
  background: #C80330;
  border: 2px solid #C80330;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1px;
  line-height: 47px;
  margin-top: 15px;
  max-width: 100%;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
}
#loginform .login-submit #wp-submit:hover,
#register-form .login-submit #wp-submit:hover {
  background: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  border-color: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  color: white;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-attachment: fixed;
}

.flatpickr-months .flatpickr-month {
  height: 44px;
}

@media only screen and (max-width: 600px) {
  div.wpforms-container-full .wpforms-form .wpforms-field:not(.wpforms-field-phone):not(.wpforms-field-select-style-modern) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
.wp_login_error p {
  background: #F9FAFB;
  border: 1px solid #CC0000;
  border-radius: 3px;
  margin-bottom: 25px;
  padding: 13px 15px;
}

div.wpforms-container-full .wpforms-datepicker-wrap .wpforms-field-medium + .wpforms-datepicker-clear {
  right: 15px !important;
}

.wpforms-list-inline {
  height: auto !important;
}

.flatpickr-months {
  height: 40px;
}

.bekeuring-error {
  display: none;
  margin-top: -20px;
}

#bekeuring-naam::placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.8em;
  color: #888888;
}
#bekeuring-naam:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.8em;
  color: #888888;
}

div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
  max-width: 100% !important;
}

.card {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(136, 136, 136, 0.2);
  width: 100%;
  height: calc(100% - 25px);
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.card figure {
  width: 100%;
  height: 0px;
  position: relative;
  padding-top: 56.25%;
  background-color: #f1f1f1;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 40%;
}
.card figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .text {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;
}
.card .text .entry-date {
  font-size: 0.7em;
  color: #666;
  margin-bottom: 10px;
}
.card .text h2 {
  font-size: 1.3em;
  margin-bottom: 10px;
}
.card .btn {
  margin-top: auto;
  display: block;
}

.card.card-horizontal {
  margin-bottom: 15px;
  background-color: white;
  padding: 15px;
  height: auto;
}
.card.card-horizontal figure {
  width: 100%;
  padding-top: 56.25%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.card.card-horizontal figure img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card.card-horizontal .text {
  padding: 15px;
}
.card.card-horizontal .text p {
  margin-bottom: 10px;
}
.card.card-horizontal .text .btn {
  margin-top: 0;
  width: 0%;
  display: table;
}

.card__car {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(136, 136, 136, 0.2);
  height: calc(100% - 30px);
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0;
}
.card__car.card__vergelijk {
  height: auto;
}
.card__car a.card__link {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card__car a.card__link:hover .card__title {
  text-decoration: underline;
}
.card__car a.card__link:hover .btn-primary {
  background: rgb(18.3597122302, 50.0143884892, 69.6402877698);
  border-color: rgb(18.3597122302, 50.0143884892, 69.6402877698);
}
.card__car .card__title {
  color: #1d4f6e;
  font-weight: 100;
  margin-bottom: 0;
  text-transform: uppercase;
}
.card__car .card__title strong {
  font-size: inherit;
}
.card__car .card__description {
  font-size: 13px;
  margin-bottom: 10px;
  color: #888888;
}
.card__car .card__price {
  margin-top: auto;
}
.card__car .card__price .price__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1.2em;
}
.card__car .card__price .price__container .old_price {
  margin-right: 5px;
  font-size: 0.8em;
}
.card__car .card__price .price__container .btw {
  text-align: left;
  margin-left: 5px;
  font-size: 0.5em;
}
.card__car .card__price-after {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin-left: 5px;
}
.card__car .card__price-after span {
  color: #888888;
  line-height: 1;
}
.card__car figure {
  display: block;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  background: #f1f1f1;
}
.card__car figure span.noimage {
  font-size: 1.2em;
  color: rgba(136, 136, 136, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  text-align: center;
}
.card__car figure img {
  height: 100%;
  left: 0;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.card__car figure .background {
  background: #f7f7f7;
  height: 80%;
  left: 0;
  position: absolute;
  top: 15px;
  width: 100%;
}
.card__car .text {
  color: black;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 18px 30px 0 30px;
  text-align: left;
}
.card__car .text .btn {
  margin: 15px auto;
  width: 100%;
  margin-bottom: 28px;
  background-color: #1d4f6e;
  border-color: #1d4f6e;
}
@media (min-width: 992px) {
  .card__car .text .btn {
    margin-bottom: 10px;
  }
}
.card__car .card__checkbox {
  padding: 0 30px 18px 30px;
  display: none;
}
@media (min-width: 992px) {
  .card__car .card__checkbox {
    display: flex;
  }
}
.card__car .card__checkbox input[type=checkbox] {
  height: 13px;
  margin-right: 5px;
}
.card__car .card__checkbox label {
  color: #888888;
  font-size: 12px;
  font-weight: 100;
  height: 13px;
  line-height: 15px;
  flex: 1 0 0;
}
.card__car .card__checkbox .vergelijk__nu {
  text-decoration: underline;
  color: #1d4f6e;
  font-size: 12px;
  display: none;
  cursor: pointer;
}
.card__car .card__checkbox .vergelijk__nu:hover {
  color: rgb(18.3597122302, 50.0143884892, 69.6402877698);
}

.card__search {
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  height: 100%;
}
.card__search h1 {
  font-size: 1.2em;
  color: #1d4f6e;
  font-weight: 600;
  margin-bottom: 20px;
}
.card__search .car_types {
  margin-bottom: 25px;
}
.card__search select {
  margin-top: auto;
}
.card__search button {
  position: relative;
  text-align: left;
}
.card__search button .search-icon {
  position: absolute;
  right: 20px;
}

.card__horizontal {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-bottom: 5px;
  align-items: center;
  position: relative;
}
.card__horizontal .figure_holder {
  width: 80px;
}
@media (min-width: 1200px) {
  .card__horizontal .figure_holder {
    width: 150px;
  }
}
.card__horizontal .figure_holder figure {
  width: 100%;
  position: relative;
}
.card__horizontal .figure_holder figure img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__horizontal .info {
  padding: 0px 15px;
  width: 100%;
  flex: 1;
}
.card__horizontal .info .vehicle__label {
  top: 0;
  left: 0;
  margin-bottom: 5px;
  display: inline-block;
  max-width: 120px;
  position: absolute;
  transform: scale(0.75);
  transform-origin: 0% 0%;
  text-align: center;
}
@media (min-width: 992px) {
  .card__horizontal .info .vehicle__label {
    position: relative;
    display: inline-block;
    transform: scale(1);
    max-width: 300px;
  }
}
.card__horizontal .info .card__title {
  font-size: 0.7em;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .card__horizontal .info .card__title {
    font-size: 1em;
  }
}
.card__horizontal .price__container {
  margin-left: auto;
}
.card__horizontal .arrow {
  width: 25px;
  height: 100%;
  background-color: #C80330;
  display: block;
  color: white;
  text-align: center;
  font-size: 18px;
  line-height: 80px;
  margin-left: 20px;
  border-radius: 2px;
  padding: 0px 5px;
}
.card__horizontal:hover {
  background: #f1f1f1;
}
.card__horizontal:hover .arrow {
  background-color: rgb(149.7536945813, 2.2463054187, 35.9408866995);
}

.search-results .card.card-horizontal,
.search-results .card.card-horizontal .text {
  background: #F9FAFB;
}

.seachform {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (min-width: 1200px) {
  .seachform {
    max-width: 520px;
  }
}
.seachform input {
  width: calc(100% - 50px);
  height: 50px;
  border: 2px solid #DDDDDD;
  border-right: none;
  background-color: white;
  padding: 10px 20px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  outline: none;
}
.seachform button {
  width: 50px;
  height: 50px;
  background-color: #C80330;
  color: white;
  border: none;
  position: relative;
  z-index: 2;
}
.seachform .suggestions {
  position: absolute;
  top: calc(100% - 0px);
  width: calc(100% - 0px);
  background-color: white;
  border: 2px solid #DDDDDD;
  border-top: 1px solid #f7f7f7;
  list-style: none;
  display: none;
  z-index: 10;
}
.seachform .suggestions li a {
  padding: 12px;
  color: #2C2C2C;
  display: block;
  font-size: 0.8em;
  font-weight: normal;
}
.seachform .suggestions li a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  float: right;
  color: inherit;
  font-size: inherit;
}
.seachform .suggestions li a:hover {
  background-color: #f7f7f7;
}

@font-face {
  font-family: "skoda";
  src: url(../fonts/skoda-next-light.woff2) format("woff2");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "skoda";
  src: url(../fonts/skoda-next-regular.woff2) format("woff2");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "skoda";
  src: url(../fonts/skoda-next-bold.woff2) format("woff2");
  font-style: normal;
  font-weight: 700;
}
#skoda-form * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "skoda", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  color: #1c1c1c;
}

#skoda-form {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.625rem;
  background: #fcfcfc;
}
#skoda-form h1 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 768px) {
  #skoda-form h1 {
    font-size: 2.3em;
  }
}
#skoda-form h6 {
  font-size: 1em;
  margin-bottom: 0;
}
#skoda-form p {
  font-weight: 100;
  margin: 0 0 1em;
}
#skoda-form > .form-fields {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
}
#skoda-form > .form-fields > .form-row {
  width: 100%;
  margin-bottom: 1em;
  position: relative;
}
@media (min-width: 768px) {
  #skoda-form > .form-fields > .form-row.-w50 {
    width: 50%;
  }
  #skoda-form > .form-fields > .form-row.-w50.-first {
    padding-right: 10px;
  }
  #skoda-form > .form-fields > .form-row.-w50.-last {
    padding-left: 10px;
  }
}
@media (min-width: 768px) {
  #skoda-form > .form-fields > .form-row.-w33 {
    width: 33.3333333333%;
    padding: 0 10px;
  }
  #skoda-form > .form-fields > .form-row.-w33.-first {
    padding-left: 0;
  }
  #skoda-form > .form-fields > .form-row.-w33.-last {
    padding-right: 0;
  }
}
#skoda-form > .form-fields input:not(.checkbox):not([type=submit]), #skoda-form > .form-fields select {
  background: #fff;
  width: 100%;
  appearance: none;
  border: 2px solid #becdd3;
  border-radius: 0;
  height: auto;
  font-size: 1em;
  -webkit-transition: border-color 0.15s ease-out;
  transition: border-color 0.15s ease-out;
  padding: 0.5em 0.75em;
  font-weight: 100;
  color: #0f0f0f;
}
#skoda-form > .form-fields input:not(.checkbox):not([type=submit]):focus, #skoda-form > .form-fields input:not(.checkbox):not([type=submit]):focus-visible, #skoda-form > .form-fields select:focus, #skoda-form > .form-fields select:focus-visible {
  border-radius: 0;
  border-color: #000;
  outline: 0;
}
#skoda-form > .form-fields input.error, #skoda-form > .form-fields select.error {
  border-color: #e4002c !important;
  outline-color: #e4002c !important;
}
#skoda-form > .form-fields label {
  font-weight: 700;
  display: block;
  font-size: 1em;
  margin-bottom: 0.5em;
}
#skoda-form > .form-fields label.error {
  margin: 0;
  font-size: 0.8em;
  color: #e4002c;
  padding-top: 0.25em;
  font-weight: 100;
}
#skoda-form > .form-fields .select-arrow {
  width: 12px;
  position: absolute;
  right: 20px;
  top: 50px;
}
#skoda-form > .form-fields .select-arrow svg {
  width: 100%;
  fill: #becdd3;
}
#skoda-form > .form-fields input.checkbox {
  display: none;
}
#skoda-form > .form-fields input.checkbox:checked + label::before {
  background: #4ba82e;
  border-color: #4ba82e;
  content: "✓";
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#skoda-form > .form-fields input.checkbox.error + label::before {
  border-color: #e4002c;
}
#skoda-form > .form-fields label.checkbox {
  position: relative;
  padding-left: 2em;
  font-weight: 400;
  cursor: pointer;
}
#skoda-form > .form-fields label.checkbox:focus::before, #skoda-form > .form-fields label.checkbox:hover::before {
  border-color: #000;
}
#skoda-form > .form-fields label.checkbox::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  height: 1.25em;
  width: 1.25em;
  border: 2px solid #ddd;
  transition: all 0.1s ease-out;
}
#skoda-form > .form-fields a {
  color: #419468;
  text-underline-offset: 2px;
  font-weight: inherit;
  text-decoration: underline;
}
#skoda-form > .form-fields a:hover {
  text-decoration: none;
}
#skoda-form > .form-fields .button {
  display: inline-block;
  text-align: left;
  margin: 0 0.25em 0.25em 0;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  text-decoration: none;
  line-height: 1.125;
  font-weight: 700;
  font-size: 0.75rem;
  border-radius: 4em;
  padding: 0.925rem 1.75rem;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  background-color: #78FAAE;
  color: #161718;
  border-color: transparent;
}
#skoda-form > .form-fields .button:hover {
  background-color: #449729;
}
#skoda-form > .form-fields #bot-check {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
#skoda-form .feedback {
  display: block;
  text-align: center;
  font-size: 1.5em;
}
#skoda-form .feedback > .subtitle {
  margin: 0 auto;
  max-width: 500px;
}
#skoda-form ul {
  list-style: none;
  padding-left: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.loading-animation {
  display: none;
  margin: 1.5em auto;
  width: 50px;
  height: 50px;
}

@font-face {
  font-family: "seat";
  src: url(../fonts/SEATBCN-Light.woff2) format("woff2");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "seat";
  src: url(../fonts/SEATBCN-Regular.woff2) format("woff2");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "seat";
  src: url(../fonts/SEATBCN-Medium.woff2) format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "seat";
  src: url(../fonts/SEATBCN-CondensedBold.woff2) format("woff2");
  font-style: normal;
  font-weight: 700;
}
#seat-form * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "seat", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  color: #0f0f0f;
}

#seat-form {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.625rem;
  background: #fafaf9;
}
#seat-form h1 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 768px) {
  #seat-form h1 {
    font-size: 2.3em;
  }
}
#seat-form h6 {
  font-size: 1em;
  margin-bottom: 0;
}
#seat-form p {
  font-weight: 100;
  margin: 0 0 1em;
}
#seat-form > .form-fields {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
}
#seat-form > .form-fields > .form-row {
  width: 100%;
  margin-bottom: 1em;
  position: relative;
}
@media (min-width: 768px) {
  #seat-form > .form-fields > .form-row.-w50 {
    width: 50%;
  }
  #seat-form > .form-fields > .form-row.-w50.-first {
    padding-right: 10px;
  }
  #seat-form > .form-fields > .form-row.-w50.-last {
    padding-left: 10px;
  }
}
@media (min-width: 768px) {
  #seat-form > .form-fields > .form-row.-w33 {
    width: 33.3333333333%;
    padding: 0 10px;
  }
  #seat-form > .form-fields > .form-row.-w33.-first {
    padding-left: 0;
  }
  #seat-form > .form-fields > .form-row.-w33.-last {
    padding-right: 0;
  }
}
#seat-form > .form-fields input:not(.checkbox):not([type=submit]),
#seat-form > .form-fields select {
  background: #fff;
  width: 100%;
  appearance: none;
  border: 2px solid #becdd3;
  border-radius: 0.25em;
  height: auto;
  font-size: 1em;
  -webkit-transition: border-color 0.15s ease-out;
  transition: border-color 0.15s ease-out;
  padding: 0.5em 0.75em;
  font-weight: 100;
  color: #0f0f0f;
}
#seat-form > .form-fields input:not(.checkbox):not([type=submit]):focus, #seat-form > .form-fields input:not(.checkbox):not([type=submit]):focus-visible,
#seat-form > .form-fields select:focus,
#seat-form > .form-fields select:focus-visible {
  border-color: #000;
  outline: 0;
}
#seat-form > .form-fields input.error,
#seat-form > .form-fields select.error {
  border-color: #e4002c !important;
  outline-color: #e4002c !important;
}
#seat-form > .form-fields label {
  font-weight: 700;
  display: block;
  font-size: 1em;
  margin-bottom: 0.5em;
}
#seat-form > .form-fields label.error {
  margin: 0;
  font-size: 0.8em;
  color: #e4002c;
  padding-top: 0.25em;
  font-weight: 100;
}
#seat-form > .form-fields .select-arrow {
  width: 12px;
  position: absolute;
  right: 20px;
  top: 50px;
}
#seat-form > .form-fields .select-arrow svg {
  width: 100%;
  fill: #becdd3;
}
#seat-form > .form-fields input.checkbox {
  display: none;
}
#seat-form > .form-fields input.checkbox:checked + label::before {
  background: #ea5d1a;
  border-color: #ea5d1a;
  content: "✓";
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#seat-form > .form-fields input.checkbox.error + label::before {
  border-color: #e4002c;
}
#seat-form > .form-fields label.checkbox {
  position: relative;
  padding-left: 2em;
  font-weight: 400;
  cursor: pointer;
}
#seat-form > .form-fields label.checkbox:focus::before, #seat-form > .form-fields label.checkbox:hover::before {
  border-color: #000;
}
#seat-form > .form-fields label.checkbox::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  height: 1.25em;
  width: 1.25em;
  border: 2px solid #ddd;
  transition: all 0.1s ease-out;
}
#seat-form > .form-fields a {
  color: #ea5d1a;
  text-underline-offset: 2px;
  font-weight: inherit;
  text-decoration: underline;
}
#seat-form > .form-fields a:hover {
  text-decoration: none;
}
#seat-form > .form-fields .button {
  background: #ea5d1a;
  color: #fff;
  border-color: transparent;
  text-decoration: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.125em;
  padding: 0.5em 1em;
  border-width: 2px;
  text-transform: none;
  margin: 0;
}
#seat-form > .form-fields .button:hover {
  background-color: #ee7e49;
}
#seat-form > .form-fields #bot-check {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
#seat-form .feedback {
  display: block;
  text-align: center;
  font-size: 1.5em;
}
#seat-form .feedback > .subtitle {
  margin: 0 auto;
  max-width: 500px;
}
#seat-form ul {
  list-style: none;
  padding-left: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.easepick {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border: 1px solid rgba(29, 79, 110, 0.3);
  color: #1d4f6e;
  font-size: 0.8em;
  background: none;
}
.easepick:focus {
  border: 1px solid #1d4f6e;
  outline: none;
}

:host {
  --color-fg-primary: #1d4f6e;
  --color-bg-inrange: #adc4d4;
}

section.section.intro {
  max-height: 550px;
  min-height: 40vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
section.section.intro--small {
  max-height: 330px;
  min-height: 50vh;
}
@media (min-width: 992px) {
  section.section.intro--small {
    min-height: 30vh;
  }
}
section.section.intro--micro {
  max-height: 150px;
  min-height: 150px;
  background: #1d4f6e;
}
section.section.intro .container, section.section.intro .row {
  height: 100%;
}
section.section.intro .bg {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  section.section.intro .bg {
    height: auto;
  }
}
@supports (-ms-accelerator: true) {
  section.section.intro .bg {
    height: auto;
  }
}
section.section.intro .gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  section.section.intro .gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    width: 66%;
  }
}
section.section.intro .text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
section.section.intro .text h1 {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  section.section.intro .text h1 {
    font-size: 65px;
    line-height: 70px;
  }
}
@media (min-width: 540px) {
  section.section.intro .text p {
    width: 66%;
  }
}
@media (min-width: 992px) {
  section.section.intro .text p {
    width: 50%;
  }
}
section.section.intro .text .btn {
  margin-top: 0;
}
@media (min-width: 768px) {
  section.section.intro .text .btn {
    align-self: flex-start;
  }
}

.usp {
  background: white;
  padding: 0px 0px;
  position: relative;
  width: 100%;
  z-index: 2;
}
.usp ul {
  display: table;
  height: 50px;
  list-style: none;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.usp ul li {
  color: #666;
  display: none;
  font-size: 0.87em;
  font-weight: 300;
  left: 0;
  line-height: 1;
  padding: 17.5px 0px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.usp ul li .fas {
  margin-right: 5px;
  color: #4C956C;
  font-size: inherit;
}
@media (min-width: 1200px) {
  .usp ul {
    width: auto;
    margin: 0;
    justify-content: space-between;
    display: flex;
  }
  .usp ul li {
    display: block;
    float: left;
    position: relative;
    text-align: center;
    width: auto;
  }
}

section.text-image .text-image__text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px 0;
}
@media (min-width: 768px) {
  section.text-image .text-image__text {
    padding: 0;
  }
}
section.text-image .text-image__text--has-image {
  padding: 30px 10px;
  position: relative;
}
section.text-image .text-image__text--has-image h2 {
  margin-top: 30px;
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image h2 {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image {
    padding: 45px;
  }
}
@media (min-width: 1200px) {
  section.text-image .text-image__text--has-image {
    box-shadow: 0 2px 33px 7px #EBF0FB;
    padding: 90px;
  }
}
section.text-image .text-image__text--has-image figure {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}
section.text-image .text-image__text--has-image figure.hover-img {
  display: block;
  z-index: 1;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 992px) {
  section.text-image .text-image__text--has-image figure.hover-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
section.text-image .text-image__text--has-image figure img {
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  position: absolute;
}
section.text-image .text-image__text h2 {
  color: #C80330;
  text-align: left;
}
section.text-image .no-padding {
  padding: 0;
}
@media (min-width: 768px) {
  section.text-image .no-padding {
    padding: 0 15px;
  }
}
section.text-image figure {
  height: 100%;
  width: 100%;
}
section.text-image figure img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) {
  section.text-image .btn {
    align-self: flex-start;
  }
}
section.text-image .order-1 {
  order: 1;
}
@media (min-width: 768px) {
  section.text-image .order-1 {
    order: initial;
  }
}

section.text-image-block .text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px 0;
}
@media (min-width: 768px) {
  section.text-image-block .text {
    padding: 0;
  }
}
section.text-image-block .text--has-image {
  padding: 30px 10px;
  position: relative;
}
section.text-image-block .text--has-image h2 {
  margin-top: 30px;
}
@media (min-width: 992px) {
  section.text-image-block .text--has-image h2 {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  section.text-image-block .text--has-image {
    padding: 45px;
  }
}
@media (min-width: 1200px) {
  section.text-image-block .text--has-image {
    box-shadow: 0 2px 33px 7px #EBF0FB;
    padding: 90px;
  }
}
section.text-image-block .text--has-image figure {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}
section.text-image-block .text--has-image figure.hover-img {
  display: block;
  z-index: 1;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 992px) {
  section.text-image-block .text--has-image figure.hover-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
section.text-image-block .text--has-image figure img {
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  position: absolute;
}
section.text-image-block .text h2 {
  color: #C80330;
  text-align: left;
}
section.text-image-block .container .row .video-container-wrapper {
  position: relative;
  margin-top: 30px;
}
section.text-image-block .container .row .video-container-wrapper > img {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
@media (min-width: 768px) {
  section.text-image-block .container .row .video-container-wrapper > img {
    width: 100%;
    position: static;
    left: auto;
    transform: translate(0, 0);
  }
}
section.text-image-block .container .row .video-container-wrapper .video-container {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  margin-top: 50px;
  display: block;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0);
}
@media (min-width: 768px) {
  section.text-image-block .container .row .video-container-wrapper .video-container {
    transform: translate(0, 0);
    padding-top: 50.25%;
    left: 0px;
    width: 100%;
  }
}
section.text-image-block .container .row .video-container-wrapper .video-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.text-image-block .container .row .video-container-wrapper .video-container .playbutton {
  position: absolute;
  z-index: 2;
  width: 90px;
  height: 90px;
  left: calc(50% - 45px);
  top: calc(50% - 45px);
  cursor: pointer;
}
section.text-image-block .container .row .video-container-wrapper .video-container .videoposter {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  object-fit: cover;
}
section.text-image-block .no-padding {
  padding: 0;
}
@media (min-width: 768px) {
  section.text-image-block .no-padding {
    padding: 0 15px;
  }
}
section.text-image-block figure {
  height: 100%;
  width: 100%;
}
section.text-image-block figure img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) {
  section.text-image-block .btn {
    align-self: flex-start;
  }
}
section.text-image-block .order-1 {
  order: 1;
}
@media (min-width: 768px) {
  section.text-image-block .order-1 {
    order: initial;
  }
}

.merken h2 {
  color: #C80330;
  margin-bottom: 60px;
}

.card-cars {
  display: flex;
  flex-wrap: wrap;
}
.card-cars__card {
  align-items: center;
  border: 1px solid #E7E7E7;
  border-radius: 3px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 5px;
  position: relative;
  transform-origin: bottom center;
  transition: 0.15s ease-out;
  width: calc(50% - 5px);
}
.card-cars__card:hover {
  box-shadow: 0 2px 15px 6px rgba(151, 160, 178, 0.2);
  transition: 0.15s ease-in;
  z-index: 1;
}
@media (min-width: 768px) {
  .card-cars__card:hover {
    box-shadow: 0 2px 30px 6px rgba(151, 160, 178, 0.4);
    transform: scale(1.05);
  }
}
.card-cars__card:nth-child(odd) {
  margin-left: 0;
}
.card-cars__card:nth-child(even) {
  margin-right: 0;
}
@media (min-width: 768px) {
  .card-cars__card {
    border: 0;
    border-radius: 0;
    height: auto;
    margin: 0;
    width: 20%;
  }
}
.card-cars__card.disabled {
  opacity: 0.2;
  cursor: unset;
}
.card-cars__card.disabled:hover {
  transform: scale(1);
  box-shadow: none;
}
.card-cars figure {
  display: none;
  height: 0;
  padding-top: 175%;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .card-cars figure {
    display: block;
  }
}
.card-cars figure img {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.card-cars__logo {
  height: 60px;
  width: 80px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-cars__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .card-cars__logo {
    left: 50%;
    max-height: 80px;
    position: absolute;
    top: 12px;
    transform: translateX(-50%);
    width: 35px;
  }
}
@media (min-width: 992px) {
  .card-cars__logo {
    width: 50px;
    top: 26px;
  }
}
@media (min-width: 1200px) {
  .card-cars__logo {
    width: 65px;
    top: 37px;
  }
}
@media (min-width: 1500px) {
  .card-cars__logo {
    width: 95px;
    top: 58.5px;
  }
}

.merk-rotatie {
  opacity: 0;
}
.merk-rotatie * {
  font-size: inherit;
}

.merken-page a {
  width: 100%;
  padding: 10px;
}
.merken-page a .fa {
  float: right;
}
.merken-page ul {
  list-style: none;
  padding-bottom: 25px;
}

.sidebar-menu {
  background: white;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .sidebar-menu.sticky {
    position: sticky;
    top: 130px;
  }
}
.sidebar-menu nav ul {
  list-style: none;
}
.sidebar-menu nav ul li {
  position: relative;
  border-bottom: 1px solid #E7E7E7;
}
.sidebar-menu nav ul li.current-menu-item {
  background: #E7E7E7;
}
.sidebar-menu nav ul li.current-menu-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #C80330;
}
.sidebar-menu nav ul li:hover {
  background: #F1F1F1;
}
.sidebar-menu nav ul li:last-child {
  border-bottom: 0;
}
.sidebar-menu nav ul li a {
  padding: 25px;
  display: block;
  width: 100%;
  height: 100%;
  color: #2C2C2C;
  font-weight: bold;
}
.sidebar-menu nav ul li a:hover {
  text-decoration: none;
}

.brands .brand {
  float: left;
  width: 50%;
  list-style: none;
  margin-bottom: 25px;
}
.brands .brand figure {
  width: 150px;
  margin: 0px auto;
  height: 0px;
  padding-top: 25%;
  position: relative;
}
.brands .brand figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.brands .brand figure img:hover {
  transform: scale(1.02);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .brands .brand figure img {
    width: auto;
  }
}
@media (min-width: 1020px) {
  .brands .brand {
    width: 20%;
  }
}

.testimonials .card {
  padding: 25px;
}
.testimonials .card figure {
  width: 70%;
  height: 0;
  padding-top: 70%;
  border: 2px solid #1d4f6e;
  border-radius: 50%;
  margin: 0px auto;
}
.testimonials .card figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonials .card .stars {
  color: #FAB611;
  display: table;
  margin: 0 auto;
}
.testimonials .card h3 {
  text-align: center;
}

.page-template-info-page section.section:not(.intro), .page-template-page-bekeuring-opvragen section.section:not(.intro), .page-template-page-mijn-business-online section.section:not(.intro) {
  background: #F9FAFB;
}

.page-template-page-mijn-business-online figure.content-image {
  height: 0;
  padding-top: 33%;
  position: relative;
  width: 100%;
  min-height: 170px;
}
.page-template-page-mijn-business-online figure.content-image .content-image__text {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  padding: 25px;
}
@media (min-width: 992px) {
  .page-template-page-mijn-business-online figure.content-image .content-image__text {
    padding: 40px;
  }
}
.page-template-page-mijn-business-online figure.content-image .content-image__text h2 {
  font-size: 1.6em;
}
@media (min-width: 992px) {
  .page-template-page-mijn-business-online figure.content-image .content-image__text h2 {
    font-size: 2em;
  }
}
.page-template-page-mijn-business-online figure.content-image img {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.section__404 {
  height: 60vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  position: relative;
}
.section__404::before {
  content: "404";
  color: rgba(231, 231, 231, 0.33);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(35vw + 3em);
  font-weight: bold;
}
.section__404 .row {
  position: relative;
  z-index: 1;
}
.section__404 p {
  font-size: calc(1vw + 1em);
}
@media (min-width: 1500px) {
  .section__404 p {
    font-size: 40px;
  }
}
.section__404 h1 {
  font-size: calc(1.5vw + 1.5em);
}
@media (min-width: 1500px) {
  .section__404 h1 {
    font-size: 60px;
  }
}

.page-contact h1 {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.1;
  margin: 0 0 10px;
  padding: 20px 2px;
}
.page-contact a {
  color: #404040;
  text-decoration: none;
}
.page-contact .content a:hover {
  text-decoration: underline;
}
.page-contact .content img {
  margin: 0 -40px -45px -40px;
  width: calc(100% + 80px);
}

.maps {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .maps {
    display: flex;
    flex-flow: row-reverse;
    align-items: stretch;
    justify-content: stretch;
  }
}
.maps #map {
  height: 500px;
  order: 2;
  width: 100%;
}
.maps .popover {
  background: #fff;
  border: 1px solid #ccc;
  display: none;
  height: 100%;
  left: unset;
  max-width: 100%;
  order: 1;
  padding: 25px;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .maps .popover {
    max-width: 350px;
    height: 500px;
  }
}
.maps .popover .vestigingsinfo {
  margin-top: 50px;
}
.maps .popover__partner-list {
  padding: 15px;
}
.maps .popover__partner-list .pad {
  padding: 10px;
  margin-bottom: 10px;
}
.maps .popover__partner-list .vestigingsinfo {
  margin-top: 0;
  height: 100%;
  overflow: hidden;
}
.maps .popover__partner-list .vestigingsinfo .listings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.maps .popover__partner-list .vestigingsinfo ul {
  list-style-type: none;
}
.maps .popover__partner-list .vestigingsinfo ul li {
  position: relative;
  padding: 5px 0;
}
.maps .popover__partner-list .vestigingsinfo ul li strong {
  font-weight: 500;
  font-size: 1em;
}
.maps .popover__partner-list .vestigingsinfo ul li strong::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
}
.maps .popover__partner-list .vestigingsinfo ul li p {
  font-size: 0.9em;
}
.maps .popover__partner-list .vestigingsinfo ul li div {
  font-weight: 300;
}
.maps .popover a:hover {
  color: black;
  text-decoration: underline;
}
.maps .popover h3 {
  color: black;
  margin-bottom: 20px;
}
.maps .popover p {
  color: black;
}
.maps .popover hr {
  margin: 15px 0;
}
.maps .popover .partnerlink {
  align-items: center;
  background: #C80330;
  border-radius: 50px;
  color: white;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 80px;
  top: 27px;
  transition: 0.15s ease-out;
  width: 35px;
}
.maps .popover .partnerlink:hover {
  background: rgb(149.7536945813, 2.2463054187, 35.9408866995);
  color: white;
  text-decoration: none;
  transition: 0.15s ease-out;
}
.maps .popover .close {
  color: black;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  opacity: 0.66;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0px;
  transition: 0.15s ease-out;
  width: 40px;
}
.maps .popover .close:hover {
  color: rgba(0, 0, 0, 0.33);
  transition: 0.15s ease-out;
}
.maps .popover .rental a:hover {
  color: white;
  text-decoration: none;
}

.search-location {
  display: inline-block;
  height: 50px;
  position: relative;
  width: 100%;
}
@media (min-width: 555px) {
  .search-location {
    width: auto;
  }
}
.search-location .search-location__results {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  left: 11px;
  list-style: none;
  margin: 0;
  position: absolute;
  text-align: left;
  top: 50px;
  width: calc(100% - 11px);
  z-index: 2;
}
.search-location .search-location__results li {
  border-bottom: 1px solid #E7E7E7;
  padding: 5px 15px;
  position: relative;
}
.search-location .search-location__results li:hover {
  background: #E7E7E7;
}
.search-location .search-location__results li a {
  font-weight: bold;
}
.search-location .search-location__results li a:hover {
  color: black;
  text-decoration: none;
}
.search-location .search-location__results li a::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

#geocoder {
  margin-top: 10px;
}
@media (min-width: 555px) {
  #geocoder {
    margin-left: 10px;
    margin-top: 0;
  }
}
#geocoder .mapboxgl-ctrl-geocoder {
  appearance: none;
  border: 1px solid #E7E7E7;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: 555px) {
  #geocoder .mapboxgl-ctrl-geocoder {
    width: 250px;
  }
}
#geocoder .mapboxgl-ctrl-geocoder:focus {
  border-color: #1d4f6e;
}
#geocoder .mapboxgl-ctrl-geocoder::placeholder {
  font-size: 0.8em;
}
#geocoder .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  height: 36px;
  padding: 6px 35px;
  border: 0;
  font-size: 1em;
  position: relative;
  top: -1px;
}
#geocoder .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  top: 13px;
  right: 14px;
}
#geocoder .mapboxgl-ctrl-geocoder--icon-search {
  top: 13px;
  left: 14px;
}
#geocoder .mapboxgl-ctrl-geocoder, #geocoder .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
  text-align: left;
  z-index: 210;
}

/* Marker tweaks */
.loading-spinner {
  display: none;
  height: 50px;
  left: calc(50% - 25px);
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  z-index: 1;
}

.sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 33.3333%;
}

.pad2 {
  padding: 20px;
}

.map {
  bottom: 0;
  /*left:33.3333%;*/
  /*width:66.6666%;*/
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
}

.heading {
  background: #fff;
  background-color: #00853e;
  border-bottom: 1px solid #eee;
  color: #fff;
  line-height: 60px;
  min-height: 60px;
  padding: 0 10px;
}

.listings {
  height: 100%;
  overflow: auto;
}

.listings .item {
  display: block;
  padding: 10px;
  text-decoration: none;
  border-radius: 3px;
}
.listings .item.active {
  background-color: rgba(29, 79, 110, 0.2);
}
.listings .item:hover {
  background-color: #f7f7f7;
}
.listings .item:hover.active {
  background-color: rgba(29, 79, 110, 0.3);
}
.listings .item * {
  text-decoration: none;
}

.marker {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  background-image: url("../img/map-pin.svg");
  width: 36px;
  height: 36px;
}

.clearfix {
  display: block;
}

.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

#loc {
  background: white;
  border: 2px solid #d6d6d6;
  border-radius: 4px;
  color: #0c0c0c;
  display: none;
  font-family: "HurmeBold", Arial, Helvetica, sans-serif;
  font-size: 17px;
  left: calc(50% - 130px);
  padding: 13px;
  position: absolute;
  text-align: center;
  top: 158px;
  width: 260px;
  z-index: 1;
}

/* Marker tweaks */
.mapboxgl-popup {
  padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
  color: white;
  display: block;
  right: 10px !important;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0 !important;
  width: 180px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {
  background: #626262;
  border-radius: 3px 3px 0 0;
  color: #fff200;
  display: block;
  font-weight: 700;
  margin: 0;
  margin-top: -15px;
  padding: 10px;
  text-transform: capitalize;
}

.mapboxgl-popup-content h4 {
  display: block;
  font-weight: 400;
  margin: 0;
  padding: 10px 10px 10px 10px;
  text-transform: capitalize;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #ffeb00;
}

.mapboxgl-ctrl-geocoder {
  border: 2px solid #00853e;
  border: 0;
  border-radius: 0;
  margin-top: 0;
  position: relative;
  top: 0;
  width: 800px;
}

.mapboxgl-ctrl-geocoder > div {
  margin-left: 0;
  min-width: 100%;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  display: none;
  width: 0;
}

a.mapboxgl-ctrl-logo {
  opacity: 0;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
  display: none;
  width: 0;
}

.page .single-item {
  background-color: white;
  padding: 25px;
}
@media (min-width: 992px) {
  .page .single-item {
    padding: 50px;
  }
}
.page .single-item h1, .page .single-item h2, .page .single-item h3 {
  text-align: left;
}

section.section.direct-reserveren {
  background: #F9FAFB;
}
@media (min-width: 992px) {
  section.section.direct-reserveren .content {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1200px) {
  section.section.direct-reserveren .content {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.single .entry-date {
  font-size: 0.7em;
  color: #666;
  margin-bottom: 0px;
  position: relative;
  top: -20px;
}
.single figure.header_img {
  margin: 0px;
  margin-bottom: 25px;
  position: relative;
  height: 0px;
  padding-top: 45%;
  background-color: #f1f1f1;
}
.single figure.header_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f1f1f1;
}

p#breadcrumbs {
  font-size: 0.8em;
  color: #666;
  font-weight: 400;
  margin-bottom: 0;
}
p#breadcrumbs span, p#breadcrumbs a {
  font-size: inherit;
  font-weight: inherit;
  color: #666;
}
p#breadcrumbs .fa {
  font-size: 90%;
  margin: 0px 3px;
}
p#breadcrumbs a:hover {
  color: #C80330;
}

.single-voertuigen .section, .archive .section {
  padding: 20px 0px;
  background: #F9FAFB;
}
.single-voertuigen .section.section__light, .archive .section.section__light {
  background: #fff;
}
.single-voertuigen .section.vergelijkbaar, .archive .section.vergelijkbaar {
  padding: 50px 25px;
  padding-bottom: 25px;
}
.single-voertuigen section.vehicle, .archive section.vehicle {
  background: #F9FAFB;
  padding-bottom: 0;
}
.single-voertuigen section.vehicle .vehicle__image .vehicle__image-buttons, .archive section.vehicle .vehicle__image .vehicle__image-buttons {
  position: relative;
}
.single-voertuigen section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button, .archive section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button {
  position: absolute;
  top: calc(50% - 37.5px);
  z-index: 2;
  padding: 25px 7.5px;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  border: 0;
  outline: none;
  display: none;
}
.single-voertuigen section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button--next, .archive section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button--next {
  right: 0;
  padding-right: 5px;
}
.single-voertuigen section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button--prev, .archive section.vehicle .vehicle__image .vehicle__image-buttons .vehicle__image-button--prev {
  left: 0;
  padding-left: 5px;
}
.single-voertuigen section.vehicle .vehicle__image:hover .vehicle__image-button, .archive section.vehicle .vehicle__image:hover .vehicle__image-button {
  display: block;
}
.single-voertuigen section.vehicle .vehicle__images, .archive section.vehicle .vehicle__images {
  margin-bottom: 0;
  padding: 0;
  position: relative;
}
@media (min-width: 992px) {
  .single-voertuigen section.vehicle .vehicle__images, .archive section.vehicle .vehicle__images {
    margin-bottom: 25px;
  }
}
.single-voertuigen section.vehicle .vehicle__images .vehicle__labels, .archive section.vehicle .vehicle__images .vehicle__labels {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: 0% 0%;
  z-index: 1;
}
.single-voertuigen section.vehicle .vehicle__images .vehicle__labels .vehicle__label, .archive section.vehicle .vehicle__images .vehicle__labels .vehicle__label {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  white-space: nowrap;
}
.single-voertuigen section.vehicle .vehicle__images figure, .archive section.vehicle .vehicle__images figure {
  background: #f1f1f1;
  display: block;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.single-voertuigen section.vehicle .vehicle__images span.noimage, .archive section.vehicle .vehicle__images span.noimage {
  color: rgba(136, 136, 136, 0.2);
  font-size: 1.2em;
  font-weight: 400;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.single-voertuigen section.vehicle .vehicle__images img, .archive section.vehicle .vehicle__images img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.single-voertuigen section.vehicle .vehicle__images .other__images, .archive section.vehicle .vehicle__images .other__images {
  display: flex;
  padding: 5px;
  width: 100%;
}
.single-voertuigen section.vehicle .vehicle__images .other__images .other__image, .archive section.vehicle .vehicle__images .other__images .other__image {
  cursor: pointer;
  margin: 5px;
  max-width: 120px;
  position: relative;
  width: 33.333%;
}
.single-voertuigen section.vehicle .vehicle__images .other__images .other__image.selected::after, .archive section.vehicle .vehicle__images .other__images .other__image.selected::after {
  border: 1px solid #C80330;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .single-voertuigen section.vehicle .vehicle__images .other__images .other__image.selected::after, .archive section.vehicle .vehicle__images .other__images .other__image.selected::after {
    border-width: 2px;
  }
}
.single-voertuigen section.vehicle .vehicle__images .other__images .other__image:hover::after, .archive section.vehicle .vehicle__images .other__images .other__image:hover::after {
  border: 1px solid #888888;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .single-voertuigen section.vehicle .vehicle__images .other__images .other__image:hover::after, .archive section.vehicle .vehicle__images .other__images .other__image:hover::after {
    border-width: 2px;
  }
}
.single-voertuigen section.vehicle .vehicle__images .other__images .other__image figure, .archive section.vehicle .vehicle__images .other__images .other__image figure {
  width: 100%;
}
.single-voertuigen section.vehicle .vehicle__details, .archive section.vehicle .vehicle__details {
  background: white;
}
.single-voertuigen section.vehicle .actie__header, .archive section.vehicle .actie__header {
  margin-bottom: 5px;
  margin-top: auto;
}
.single-voertuigen section.vehicle .card__horizontal--vehicle, .archive section.vehicle .card__horizontal--vehicle {
  margin-bottom: 25px;
  box-shadow: 0 0 15px 0 rgba(136, 136, 136, 0.15);
  position: relative;
  height: auto;
}
.single-voertuigen section.vehicle .card__horizontal--vehicle .figure_holder, .archive section.vehicle .card__horizontal--vehicle .figure_holder {
  width: 130px;
}
.single-voertuigen section.vehicle .card__horizontal--vehicle .vehicle__label, .archive section.vehicle .card__horizontal--vehicle .vehicle__label {
  top: 0;
  left: 0;
  right: auto;
  position: absolute;
  padding: 4px 12px;
  margin-bottom: 0;
}
.single-voertuigen section.vehicle .card__horizontal--vehicle .vehicle__label i, .single-voertuigen section.vehicle .card__horizontal--vehicle .vehicle__label span, .archive section.vehicle .card__horizontal--vehicle .vehicle__label i, .archive section.vehicle .card__horizontal--vehicle .vehicle__label span {
  font-size: 0.8em;
}
.single-voertuigen section.vehicle .vehicle__name, .archive section.vehicle .vehicle__name {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
.single-voertuigen section.vehicle .vehicle__title, .archive section.vehicle .vehicle__title {
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
  margin-right: auto;
}
@media (min-width: 992px) {
  .single-voertuigen section.vehicle .vehicle__title, .archive section.vehicle .vehicle__title {
    font-size: 1.6em;
  }
}
.single-voertuigen section.vehicle .vehicle__title strong, .archive section.vehicle .vehicle__title strong {
  font-size: inherit;
}
.single-voertuigen section.vehicle .vehicle__description, .archive section.vehicle .vehicle__description {
  color: #888888;
  font-size: 1em;
  line-height: 1;
}
.single-voertuigen section.vehicle .price__container--voertuig, .archive section.vehicle .price__container--voertuig {
  margin-left: auto;
  padding-left: 5px;
}
.single-voertuigen section.vehicle .price__container--voertuig .price, .archive section.vehicle .price__container--voertuig .price {
  color: #C80330;
  font-size: 1em;
  line-height: 1em;
  text-align: right;
  margin-bottom: 6px;
  margin-left: 3px;
  margin-top: 3px;
  white-space: normal;
}
@media (min-width: 992px) {
  .single-voertuigen section.vehicle .price__container--voertuig .price, .archive section.vehicle .price__container--voertuig .price {
    font-size: 1em;
  }
}
@media (min-width: 1200px) {
  .single-voertuigen section.vehicle .price__container--voertuig .price, .archive section.vehicle .price__container--voertuig .price {
    font-size: 1.6em;
  }
}
.single-voertuigen section.vehicle .excerpt, .archive section.vehicle .excerpt {
  display: flex;
  font-size: 0.9em;
  line-height: 1.1;
  margin-bottom: 25px;
}
.single-voertuigen section.vehicle .form, .archive section.vehicle .form {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.single-voertuigen section.vehicle .form h3, .archive section.vehicle .form h3 {
  color: #2C2C2C;
  font-size: 1em;
  margin-bottom: 5px;
}
.single-voertuigen section.vehicle .form .periodes, .archive section.vehicle .form .periodes {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.single-voertuigen section.vehicle .form .periodes .periode, .archive section.vehicle .form .periodes .periode {
  width: 25%;
}
.single-voertuigen section.vehicle .form .periodes .periode label, .archive section.vehicle .form .periodes .periode label {
  border: 1px solid #666;
  border-left: none;
  border-right: 1px solid #666;
  color: #666;
  cursor: pointer;
  display: block;
  font-size: 0.8em;
  left: -2px;
  line-height: 1.1;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 100%;
}
.single-voertuigen section.vehicle .form .periodes .periode label:hover, .archive section.vehicle .form .periodes .periode label:hover {
  background-color: rgba(102, 102, 102, 0.1);
}
.single-voertuigen section.vehicle .form .periodes .periode label .maanden, .archive section.vehicle .form .periodes .periode label .maanden {
  font-size: 0.6em;
  text-transform: uppercase;
}
.single-voertuigen section.vehicle .form .periodes .periode:first-child label, .archive section.vehicle .form .periodes .periode:first-child label {
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid #666;
  -moz-border-radius-bottomleft: 3px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
}
.single-voertuigen section.vehicle .form .periodes .periode:last-child label, .archive section.vehicle .form .periodes .periode:last-child label {
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-topright: 3px;
  border-right: 1px solid #666;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
.single-voertuigen section.vehicle .form .periodes .periode input, .archive section.vehicle .form .periodes .periode input {
  display: none;
}
.single-voertuigen section.vehicle .form .periodes .periode input:checked ~ label, .archive section.vehicle .form .periodes .periode input:checked ~ label {
  background-color: #666;
  color: white;
}
.single-voertuigen section.options, .archive section.options {
  padding-top: 0;
}
.single-voertuigen .standaard .standaard_inbegrepen, .archive .standaard .standaard_inbegrepen {
  width: 100%;
}
.single-voertuigen .standaard ul li, .archive .standaard ul li {
  line-height: 1.8em;
  list-style: none;
  margin-bottom: 15px;
  padding-left: 50px;
  position: relative;
}
.single-voertuigen .standaard ul li .fa, .archive .standaard ul li .fa {
  color: #1d4f6e;
  font-size: 1.2em;
  left: 0;
  position: absolute;
  top: 5px;
}
.single-voertuigen .bijlagen, .archive .bijlagen {
  list-style: none;
}
.single-voertuigen .bijlagen .far, .archive .bijlagen .far {
  color: #666;
  font-size: 1.1em;
  margin-right: 5px;
}
.single-voertuigen .bijlagen li, .archive .bijlagen li {
  padding-left: 2px;
}

.vehicle__label {
  color: white;
  font-size: 0.8em;
  font-weight: 400;
  padding: 5px 15px;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 3;
}
.vehicle__label i {
  font-size: inherit;
  line-height: inherit;
  margin-right: 5px;
}
.vehicle__label.green {
  background: #4C956C;
}
.vehicle__label.yellow {
  background: #FAB611;
}
.vehicle__label.blue {
  background-color: #0093FF;
}

section.section .block {
  background-color: white;
  margin-bottom: 25px;
  padding: 25px;
  height: calc(100% - 25px);
  margin-bottom: 25px;
}
section.section .block.map {
  padding: 0;
  min-height: 400px;
}
section.section .block__height {
  height: auto;
}
section.section .block__grey {
  background-color: #f1f1f1;
}
section.section .block h2 {
  text-align: left;
}

.list {
  color: #888888;
  width: 100%;
}
.list .row {
  padding: 12px;
}
.list .row:nth-child(odd) {
  background-color: #f1f1f1;
}

.archive .archive-header, .page .archive-header, .error404 .archive-header {
  color: #1d4f6e;
  display: inline-block;
  line-height: 1;
}
.archive .omschrijving, .page .omschrijving, .error404 .omschrijving {
  padding: 25px;
  background-color: white;
  margin-bottom: 30px;
}
.archive .omschrijving h1, .page .omschrijving h1, .error404 .omschrijving h1 {
  margin-bottom: 15px;
}
.archive .omschrijving.deals, .page .omschrijving.deals, .error404 .omschrijving.deals {
  background-color: #0093FF;
  color: white;
  width: 100%;
  padding: 25px;
  margin-bottom: 25px;
}
.archive .omschrijving.deals h1, .page .omschrijving.deals h1, .error404 .omschrijving.deals h1 {
  color: white;
  margin-bottom: 15px;
}
.archive .omschrijving.deals h1 .far, .page .omschrijving.deals h1 .far, .error404 .omschrijving.deals h1 .far {
  font-size: inherit;
}
.archive .omschrijving.deals .clock, .page .omschrijving.deals .clock, .error404 .omschrijving.deals .clock {
  font-size: 180px;
  position: absolute;
  top: -10px;
  right: -10px;
  opacity: 0.5;
}
.archive .btn-filters, .page .btn-filters, .error404 .btn-filters {
  width: 100%;
  margin-top: 0;
  margin-bottom: 25px;
  background: white;
  color: #2C2C2C;
  border-color: white;
  display: flex;
  padding-right: 15px;
  padding-left: 20px;
}
.archive .btn-filters .fa, .page .btn-filters .fa, .error404 .btn-filters .fa {
  margin-left: auto;
}
@media (min-width: 1200px) {
  .archive .btn-filters, .page .btn-filters, .error404 .btn-filters {
    display: none;
  }
}
.archive .filters, .page .filters, .error404 .filters {
  width: 100%;
  height: 100vh;
  display: block;
  flex-direction: column;
  background: white;
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  overflow-y: scroll;
  width: 100%;
  max-width: 500px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  left: -100%;
  transition: left 0.2s ease-in-out;
  padding-bottom: 75px;
}
@media (max-width: 1200px) {
  .archive .filters, .page .filters, .error404 .filters {
    top: 155px;
    height: calc(100% - 155px);
  }
}
.archive .filters .filter__inner, .page .filters .filter__inner, .error404 .filters .filter__inner {
  width: 100%;
  display: block;
}
.archive .filters .filter-chang-btn, .page .filters .filter-chang-btn, .error404 .filters .filter-chang-btn {
  width: 100%;
  display: block;
  margin: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
  max-width: 500px;
}
@media (min-width: 1200px) {
  .archive .filters .filter-chang-btn, .page .filters .filter-chang-btn, .error404 .filters .filter-chang-btn {
    position: relative;
  }
}
.archive .filters .filter-chang-btn.active, .page .filters .filter-chang-btn.active, .error404 .filters .filter-chang-btn.active {
  display: block;
}
.archive .filters .filters__overlay, .page .filters .filters__overlay, .error404 .filters .filters__overlay {
  position: fixed;
  top: 0;
  left: 80%;
  width: 20%;
  height: 100%;
  display: none;
}
@media (min-width: 1200px) {
  .archive .filters .filters__overlay, .page .filters .filters__overlay, .error404 .filters .filters__overlay {
    display: none;
  }
}
.archive .filters.active, .page .filters.active, .error404 .filters.active {
  left: 0%;
}
.archive .filters.active .filters__overlay, .page .filters.active .filters__overlay, .error404 .filters.active .filters__overlay {
  display: block;
}
.archive .filters .filters__close, .page .filters .filters__close, .error404 .filters .filters__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 1em;
  background-color: rgba(0, 0, 0, 0.05);
  color: #666;
}
@media (min-width: 1200px) {
  .archive .filters .filters__close, .page .filters .filters__close, .error404 .filters .filters__close {
    display: none;
  }
}
.archive .filters .filter, .page .filters .filter, .error404 .filters .filter {
  background: white;
  padding: 0px;
  padding-top: 25px;
  margin-bottom: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.archive .filters .filter h3, .page .filters .filter h3, .error404 .filters .filter h3 {
  color: #1d4f6e;
  position: relative;
  cursor: pointer;
}
.archive .filters .filter h3 .arrow, .page .filters .filter h3 .arrow, .error404 .filters .filter h3 .arrow {
  float: right;
  font-size: 12px;
  line-height: 25px;
  top: 50%;
  transform: translate(-50%, 0%);
}
.archive .filters .filter h3:hover, .page .filters .filter h3:hover, .error404 .filters .filter h3:hover {
  color: rgb(18.3597122302, 50.0143884892, 69.6402877698);
}
.archive .filters .filter .text, .page .filters .filter .text, .error404 .filters .filter .text {
  display: none;
}
.archive .filters .filter.active .text, .page .filters .filter.active .text, .error404 .filters .filter.active .text {
  display: block;
}
.archive .filters .filter.active h3, .page .filters .filter.active h3, .error404 .filters .filter.active h3 {
  color: #C80330;
  margin-bottom: 15px;
}
.archive .filters .filter.active h3 .arrow, .page .filters .filter.active h3 .arrow, .error404 .filters .filter.active h3 .arrow {
  transform: rotate(180deg);
}
.archive .filters .filter.head, .page .filters .filter.head, .error404 .filters .filter.head {
  border-top: none;
  padding-top: 0;
}
@media (min-width: 1200px) {
  .archive .filters, .page .filters, .error404 .filters {
    display: flex;
    left: 0;
    position: relative;
    width: 100%;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    overflow: hidden;
    height: auto;
    z-index: 1;
  }
  .archive .filters .filter, .page .filters .filter, .error404 .filters .filter {
    padding: 25px;
    border-top: none;
  }
  .archive .filters .filter.head, .page .filters .filter.head, .error404 .filters .filter.head {
    padding: 25px;
  }
}

.orderby {
  width: 1000%;
  max-width: 300px;
  display: flex;
  margin-bottom: 15px;
  float: right;
  justify-content: flex-end;
}
.orderby label {
  line-height: 50px;
  white-space: nowrap;
  color: #1d4f6e;
  font-size: 0.8em;
}
.orderby select {
  appearance: auto;
  width: 100%;
  padding: 0;
  width: 50%;
  padding-left: 15px;
  border: none;
  font-weight: bold;
}

.page-id-165 .card .text {
  padding-bottom: 13px;
}
.page-id-165 .card .card__checkbox {
  display: none;
}

.vergelijk__item .vergelijk__head {
  position: relative;
}
.vergelijk__item .vergelijk__head .vehicle__label {
  position: absolute;
  right: auto;
  left: 0;
}
.vergelijk__item .vergelijk__head .vergelijk__remove {
  position: absolute;
  right: -13px;
  top: -13px;
  background-color: white;
  width: 26px;
  height: 26px;
  color: black;
  text-align: center;
  line-height: 26px;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 30;
}
.vergelijk__item .vergelijk__head .vergelijk__remove:hover {
  background-color: #C80330;
  color: white;
}

#winkelwagen {
  padding: 25px 0px;
  overflow: hidden;
  background: #F9FAFB;
}
@media (min-width: 768px) {
  #winkelwagen {
    padding: 50px 0px;
  }
}
#winkelwagen .steps {
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 0px -10px;
  margin-bottom: 25px;
  background-color: rgba(0, 0, 0, 0.05);
}
#winkelwagen .steps .step__filler {
  width: 33%;
  position: absolute;
  bottom: 0;
  height: 100%;
  background: #1d4f6e;
  transition: width 0.2s linear;
}
#winkelwagen .steps .step {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 15px 0px;
  font-size: 0;
}
#winkelwagen .steps .step strong {
  font-size: 18px;
}
@media (min-width: 768px) {
  #winkelwagen .steps .step {
    font-size: 1em;
  }
}
#winkelwagen .steps .step.active {
  color: white;
}
#winkelwagen .steps .step:last-child {
  border-right: none;
}
#winkelwagen .block {
  margin-top: 10px;
  padding: 25px;
  background: white;
}
#winkelwagen .block .optie {
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
}
#winkelwagen .block .optie .cart__items {
  flex-direction: column;
  margin-top: 25px;
}
@media (min-width: 992px) {
  #winkelwagen .block .optie .cart__items {
    flex-direction: row;
  }
}
#winkelwagen .block .optie .tankpas__card {
  margin-bottom: 25px;
  width: 100%;
  padding-right: 5px;
}
#winkelwagen .block .optie .tankpas__card label.cart__item {
  width: 100%;
  flex-direction: row;
  display: flex;
  border: 2px solid #F9FAFB;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}
#winkelwagen .block .optie .tankpas__card label.cart__item:hover {
  border-color: #888888;
}
#winkelwagen .block .optie .tankpas__card label.cart__item .checkbox {
  float: none;
  margin-right: 25px;
}
#winkelwagen .block .optie .tankpas__card input {
  display: none;
}
#winkelwagen .block .optie .tankpas__card input:checked ~ .cart__item {
  border-color: #4C956C;
}
#winkelwagen .block .optie h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
}
#winkelwagen .block .optie h3:hover {
  opacity: 0.8;
}
#winkelwagen .block .optie h3 label {
  width: 100%;
  line-height: 30px;
}
#winkelwagen .block .optie h3 input {
  display: none;
}
#winkelwagen .block .optie h3 .arrow {
  margin-left: auto;
}
#winkelwagen .block h2 {
  text-align: left;
}
#winkelwagen .block.cart h2 {
  padding-left: 10px;
}
#winkelwagen .block .prijs {
  text-align: right;
}
#winkelwagen .cart {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
#winkelwagen .cart h3,
#winkelwagen .cart h2 {
  padding: 25px;
  display: block;
  margin-bottom: 0;
}
#winkelwagen .cart .cart__items {
  display: flex;
  flex-direction: column;
}
#winkelwagen .cart .cart__items .cart__item {
  padding: 10px 20px;
  position: relative;
}
#winkelwagen .cart .cart__items .cart__item:nth-child(odd) {
  background: rgba(29, 79, 110, 0.04);
}
#winkelwagen .cart .cart__items .cart__item:nth-child(odd) label {
  background: #f6f8f9;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  #winkelwagen .cart .cart__items .cart__item .cart__item--inner {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .cart__item--a {
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  display: flex;
  width: 50%;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .cart__item--b {
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: end;
  width: 25%;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .cart__item--extras {
  text-align: left;
  font-size: 13px;
  width: 25%;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .cart__item--extras span {
  white-space: nowrap;
  display: block;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .cart__item--extras span strong {
  color: #C80330;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .figure_holder {
  width: 30%;
  max-width: 120px;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner figure {
  padding-top: 0px;
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
  position: relative;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .info {
  padding: 0px 10px;
  flex-grow: 1;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .info .name {
  color: #1d4f6e;
  font-weight: 200;
  font-size: 1.2em;
  line-height: 1;
  display: block;
}
@media (min-width: 540px) {
  #winkelwagen .cart .cart__items .cart__item .cart__item--inner .info .name {
    font-size: 1.2em;
  }
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .info .name strong {
  font-weight: 600;
  display: inline-block;
  font-size: inherit;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .info .maanden {
  display: block;
  font-size: 0.7em;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .details {
  font-size: 0.6em;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .details span {
  display: block;
  white-space: nowrap;
  padding-right: 30px;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .startdatum {
  padding-right: 5px;
  margin-bottom: 0;
  width: 50%;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .startdatum input {
  max-width: 250px;
  border-radius: 3px;
}
@media (min-width: 992px) {
  #winkelwagen .cart .cart__items .cart__item .cart__item--inner .startdatum {
    padding-right: 50px;
  }
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .input-aantal {
  margin-left: auto;
}
#winkelwagen .cart .cart__items .cart__item .cart__item--inner .price__container {
  padding-left: 15px;
}
@media (min-width: 768px) {
  #winkelwagen .cart .cart__items .cart__item .cart__item--inner .price__container {
    width: 150px;
    padding-left: 25px;
  }
}
#winkelwagen .cart .cart__items .cart__item .remove {
  position: absolute;
  right: -13px;
  background-color: white;
  width: 26px;
  height: 26px;
  color: black;
  text-align: center;
  line-height: 22px;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  top: 50%;
  transform: translate(0%, -50%);
  border: 2px solid black;
}
#winkelwagen .cart .cart__items .cart__item .remove:hover {
  background-color: #C80330;
  color: white;
  border-color: #C80330;
}
#winkelwagen .cart .cart__items .cart__item:hover .remove {
  display: block;
}
#winkelwagen .cart .cart__items .cart__item .info-wide {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.6em;
  padding-top: 3px;
  color: #2C2C2C;
}
#winkelwagen .cart .cart__items .cart__item .info-wide span {
  font-size: inherit;
  display: block;
  padding-right: 50px;
}
#winkelwagen .cart .cart__items .cart__item.tankpas .cart__item--inner .info .name {
  flex: 1 0 0;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish {
  padding: 0px;
  border: 1px solid #ebebeb;
  margin: 10px;
  background: white;
  border-radius: 3px;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .cart__item--inner {
  padding: 15px;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows {
  width: 100%;
  display: table;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item {
  display: block;
  padding: 7px 15px;
  font-size: 0.7em;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item:nth-child(even) {
  background: rgba(29, 79, 110, 0.04);
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item strong {
  font-size: 1em;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item .extra-price {
  float: right;
  color: #C80330;
  font-weight: 600;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item.subtotaal {
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}
#winkelwagen .cart .cart__items .cart__item.cart__item--finish .info-rows .info-item.subtotaal .price__container {
  margin-left: auto;
  padding-left: 25px;
}
#winkelwagen .cart .cart__footer {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f1f1f1;
}
#winkelwagen .cart .cart__footer .aantal {
  color: #888888;
}
#winkelwagen .cart .cart__footer .aantal strong {
  font-weight: 600;
}
#winkelwagen .cart .cart__footer .price__container {
  width: auto;
  align-self: flex-end;
  margin-left: auto;
  padding-left: 25px;
  display: none;
}
@media (min-width: 992px) {
  #winkelwagen .cart .cart__footer .price__container {
    display: block;
  }
}
#winkelwagen .gegevens {
  padding: 10px;
}
#winkelwagen .gegevens h2 {
  padding: 25px 25px 25px 0;
  display: block;
  margin-bottom: 0;
}
#winkelwagen .gegevens .input-confirm-group + .input-confirm-group {
  margin-top: 25px;
}
#winkelwagen .gegevens .input-confirm-group {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}
#winkelwagen .gegevens .input-confirm-group > h3 {
  padding: 15px 10px;
  display: block;
  margin-bottom: 0;
  font-size: 22px;
}
#winkelwagen .gegevens .input-confirm-group .input-confirm {
  margin: 0;
  padding: 15px 10px;
}
#winkelwagen .gegevens .input-confirm-group .input-confirm h3 {
  padding: 0;
}
#winkelwagen .buttons {
  padding: 10px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}
#winkelwagen .buttons.floating-cart-buttons {
  box-shadow: 0 0 6px 3px rgba(34, 34, 34, 0.2);
  position: fixed;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 30;
  display: none;
}
@media (min-height: 1000px) and (max-width: 1200px) {
  #winkelwagen .buttons {
    position: relative;
  }
}
#winkelwagen .buttons .buttons-flex-container {
  display: flex;
  align-items: center;
}
#winkelwagen .buttons .price__container {
  margin-right: 15px;
}
#winkelwagen .buttons .price__container.totaal {
  display: none;
}
@media (min-width: 768px) {
  #winkelwagen .buttons .price__container.totaal {
    display: flex;
    flex-grow: 1;
    margin-right: auto;
    padding-left: 15px;
  }
  #winkelwagen .buttons .price__container.totaal .price {
    color: #333;
  }
}
#winkelwagen .buttons .btn {
  margin: 0;
}
#winkelwagen .buttons .btn-step {
  float: right;
}
#winkelwagen .buttons .btn-finish {
  background-color: #FAB611;
  border-color: #FAB611;
}
#winkelwagen .buttons .btn-finish:hover {
  background-color: rgb(211.5555555556, 151.1111111111, 4.4444444444);
  border-color: rgb(211.5555555556, 151.1111111111, 4.4444444444);
}
#winkelwagen .reservering__finish {
  text-align: center;
  padding: 50px;
  width: auto;
  max-width: 600px;
}
#winkelwagen .reservering__finish .far {
  font-size: 120px;
  color: #4C956C;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: none;
}
.popup .popup__overlay {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.popup .popup__container {
  width: 100%;
  max-width: 500px;
  padding: 25px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}
.popup .popup__container .popup__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
  text-align: center;
  line-height: 39px;
  cursor: pointer;
}
.popup .popup__container .popup__close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.popup .popup__container .btn {
  height: auto;
  padding: 0px 10px;
  width: 100%;
}

section.section.intro-actiepagina {
  max-height: 550px;
  min-height: 50vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
section.section.intro-actiepagina--small {
  max-height: 330px;
  min-height: 50vh;
}
@media (min-width: 992px) {
  section.section.intro-actiepagina--small {
    min-height: 30vh;
  }
}
section.section.intro-actiepagina--micro {
  max-height: 150px;
  min-height: 150px;
  background: #1d4f6e;
}
section.section.intro-actiepagina .container, section.section.intro-actiepagina .row {
  height: 100%;
}
section.section.intro-actiepagina .bg {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  section.section.intro-actiepagina .bg {
    height: auto;
  }
}
@supports (-ms-accelerator: true) {
  section.section.intro-actiepagina .bg {
    height: auto;
  }
}
section.section.intro-actiepagina .gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  section.section.intro-actiepagina .gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    width: 66%;
  }
}
section.section.intro-actiepagina .text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
section.section.intro-actiepagina .text h1 {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  section.section.intro-actiepagina .text h1 {
    font-size: 65px;
    line-height: 70px;
  }
}
@media (min-width: 540px) {
  section.section.intro-actiepagina .text p {
    width: 66%;
  }
}
@media (min-width: 992px) {
  section.section.intro-actiepagina .text p {
    width: 50%;
  }
}
section.section.intro-actiepagina .text .btn {
  margin-top: 0;
}
@media (min-width: 768px) {
  section.section.intro-actiepagina .text .btn {
    align-self: flex-start;
  }
}

section.section.prijs_blok {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  background-color: #E1EAED;
}
section.section.prijs_blok .prijs-cta-blok {
  height: 100%;
}
section.section.prijs_blok .prijs-cta-blok .prijs {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 100;
}
section.section.prijs_blok .prijs-cta-blok .btn {
  margin-top: 0px;
}
@media (max-width: 768px) {
  section.section.prijs_blok .prijs-cta-blok .btn {
    width: 100%;
  }
}

section.section.explore_blok {
  background-color: #fff !important;
}
section.section.explore_blok .titel {
  text-align: left;
}
section.section.explore_blok ul li {
  line-height: 1.8em;
  list-style: none;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 50px;
  position: relative;
  font-family: "Roboto";
  font-weight: 100;
  font-size: 19px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f9fafb;
}
section.section.explore_blok ul li:last-child {
  border-bottom: none;
}
section.section.explore_blok ul li .fa {
  color: #1d4f6e;
  font-size: 1.2em;
  left: 0;
  position: absolute;
  top: 7px;
}
@media (max-width: 768px) {
  section.section.explore_blok ul li .fa {
    top: 4px;
  }
}
@media (max-width: 768px) {
  section.section.explore_blok ul li {
    margin-top: 40px;
    font-size: 15px;
    padding-left: 30px;
  }
}

.top-blok, .second-menu {
  background: #fff;
  height: auto;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin-bottom: -20px;
}
.top-blok .nav-item, .top-blok .second-nav-item, .second-menu .nav-item, .second-menu .second-nav-item {
  min-width: fit-content;
  width: fit-content;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 12px;
  height: auto;
  justify-content: center;
  opacity: 0.24;
  padding: 16px 20px;
  position: relative;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 2;
  scroll-snap-align: start;
}
.top-blok .nav-item.active, .top-blok .second-nav-item.active, .second-menu .nav-item.active, .second-menu .second-nav-item.active {
  background: #F9FAFB;
  color: #004666;
  opacity: 1;
  border-radius: 4px 4px 0 0;
}
.top-blok .nav-item.active::after, .top-blok .second-nav-item.active::after, .second-menu .nav-item.active::after, .second-menu .second-nav-item.active::after {
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  background: #004666;
}

.second-menu {
  display: none;
  position: relative;
  top: 20px;
  background: none;
}
.second-menu.active {
  display: flex;
}

section.section:nth-child(even) {
  background: unset;
}

section.section-highlight {
  background-color: #F9FAFB;
  display: none;
}
section.section-highlight.active {
  display: flex;
}
section.section-highlight .content-blok {
  padding: 48px 60px;
}
@media (max-width: 992px) {
  section.section-highlight .content-blok {
    padding: 48px 0px;
  }
}
section.section-highlight .content-blok .bg {
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  display: block;
}
@media (min-width: 992px) {
  section.section-highlight .content-blok .bg {
    margin-bottom: 0px;
  }
}
section.section-highlight .content-blok .titel {
  text-align: left;
  font-size: 20px;
}
@media (min-width: 992px) {
  section.section-highlight .content-blok .titel {
    font-size: 24px;
  }
}

section.section-highlight.opties ul {
  font-style: normal;
  font-weight: 300;
  line-height: auto;
  list-style-type: disc;
  padding-left: 1em;
  margin-bottom: 20px;
}
section.section-highlight.opties ul li::marker {
  font-size: 25px;
  color: #C70330;
}
section.section-highlight.opties ul li {
  padding-left: 10px;
  font-size: 20px;
  line-height: 1.5em;
}

section.section-fotogalerij {
  padding-top: 100px;
}
@media (max-width: 768px) {
  section.section-fotogalerij {
    padding-top: 50px;
  }
}
section.section-fotogalerij .slider {
  position: relative;
}
section.section-fotogalerij .slider .slick-track {
  display: flex !important;
}
section.section-fotogalerij .slider .slick-list {
  margin: 0 -12px;
}
section.section-fotogalerij .slider .slick-arrow {
  appearance: none;
  border: 0;
  color: #FFFFFF;
  cursor: pointer;
  margin: 0;
  top: calc(50% - 21.5px);
  outline: 0;
  border-radius: 200px;
  position: absolute;
  z-index: 2;
  height: 43px;
  width: 43px;
  background: #C80230;
}
section.section-fotogalerij .slider .slick-arrow i {
  font-size: 1em;
}
@media (max-width: 768px) {
  section.section-fotogalerij .slider .slick-arrow i {
    font-size: 0.7em;
    position: relative;
    top: -2px;
  }
}
@media (max-width: 768px) {
  section.section-fotogalerij .slider .slick-arrow.slick-prev {
    height: 23px;
    width: 23px;
    top: calc(50% + 118.5px);
    right: 40px;
  }
}
@media (min-width: 768px) {
  section.section-fotogalerij .slider .slick-arrow.slick-prev {
    left: -60px;
    top: calc(50% - 44px);
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  section.section-fotogalerij .slider .slick-arrow.slick-prev {
    left: -95px;
    padding-left: 0;
  }
}
@media (min-width: 1500px) {
  section.section-fotogalerij .slider .slick-arrow.slick-prev {
    left: -125px;
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  section.section-fotogalerij .slider .slick-arrow.slick-next {
    height: 23px;
    width: 23px;
    top: calc(50% + 118.5px);
    right: 0px;
  }
}
@media (min-width: 768px) {
  section.section-fotogalerij .slider .slick-arrow.slick-next {
    right: -60px;
    top: calc(50% - 44px);
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  section.section-fotogalerij .slider .slick-arrow.slick-next {
    right: -95px;
    padding-right: 0;
  }
}
@media (min-width: 1500px) {
  section.section-fotogalerij .slider .slick-arrow.slick-next {
    right: -125px;
    padding-right: 0;
  }
}
section.section-fotogalerij .slider .slick-slide {
  height: inherit !important;
  margin: 0 12px;
}
section.section-fotogalerij .slider .slick-dots {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
  padding-bottom: 51px;
}
section.section-fotogalerij .slider .slick-dots li {
  font-size: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: #DFD4B3;
  margin: 0px 5px;
  display: inline-block;
}
section.section-fotogalerij .slider .slick-dots li:only-child {
  display: none;
}
section.section-fotogalerij .slider .slick-dots > li button {
  display: none;
}
section.section-fotogalerij .slide {
  width: 100%;
  display: table;
  padding-top: 0;
  height: auto;
  padding-bottom: 47px;
}
section.section-fotogalerij .slide .image {
  border-radius: 3px;
  max-height: 292px;
  text-align: center;
  height: 0;
  padding-top: 52%;
  overflow: hidden;
  position: relative;
}
section.section-fotogalerij .slide .image img {
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

section.section-dezehuren {
  background-color: unset;
}
@media (min-width: 992px) {
  section.section-dezehuren {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
section.section-dezehuren .titel {
  text-align: left;
}
@media (max-width: 992px) {
  section.section-dezehuren .tekst {
    margin-bottom: 30px;
  }
}

.section.section-usps {
  background-color: #FFF !important;
  padding: 0px;
}
.section.section-usps ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Vertically align the items */
  width: 100%;
  height: 50px;
}
@media (max-width: 992px) {
  .section.section-usps ul {
    flex-direction: column;
    height: max-content;
    margin-bottom: 30px;
    margin-top: 30px;
    align-items: baseline;
  }
}
.section.section-usps ul li {
  display: flex;
  align-items: center; /* Vertically align the text */
  font-size: 19px;
  font-family: "Roboto";
  font-weight: 500;
  margin-right: 20px;
}
@media (max-width: 992px) {
  .section.section-usps ul li {
    margin-bottom: 10px;
  }
}
.section.section-usps ul li img {
  margin-right: 20px;
}