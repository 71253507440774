@use 'settings' as *;

input[type='text'],
input[type='email'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='date'],
select,
textarea {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border: 1px solid rgba($blauw, 0.3);
  color: $blauw;
  font-size: 0.8em;
  background: none;
}

input[type='date'] {
  padding-right: 15px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../img/angle-down.svg');
  background-size: 12px auto;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 17px;

  &:focus {
    outline: none;
    border-color: #1d4f6e;
  }
}

textarea {
  height: 150px;
}

.input-row {
  margin-bottom: 25px;
  position: relative;
  label {
    position: absolute;
    background-color: white;
    font-size: 10px;
    padding: 3px 5px;
    left: 10px;
    top: -8px;
    color: $blauw;
  }

  .feedback {
    margin-top: 3px;
    font-size: 12px;
    line-height: 12px;
    display: block;
    position: relative;
    color: $blauw;
  }

  &.error {
    label {
      color: $danger;
    }
    input,
    textarea,
    select {
      border-color: $danger;
    }

    .feedback {
      color: $danger;
    }
  }
}

.radio-row {
  margin-bottom: 25px;
  label {
    font-size: 10px;
    color: $blauw;
  }

  ul {
    list-style: none;

    li + li {
      margin-left: 10px;
    }

    li {
      display: inline-block;

      label {
        font-size: 0.8em;
      }
    }
  }
}

.input-row,
.radio-row {
  label {
    .required {
      color: $rood;
      margin-left: 3px;
    }
  }
}

.input-confirm {
  font-size: 1em;
  position: relative;
  color: #666;
  padding: 15px 25px;

  &:nth-child(even) {
    background: rgba($blauw, 0.04);
  }

  &:first-of-type {
    margin-top: 10px;
  }

  h3 {
    font-size: inherit;
    color: $antraciet;
    margin-bottom: 0;
    font-weight: 600;
    padding: 0;
  }
}

.input-aantal {
  display: flex;

  .aantal-btn,
  .days-amount-btn {
    width: 25px;
    height: 50px;
    line-height: 50px;
    background: #666;
    text-align: center;
    color: white;
    cursor: pointer;
    display: block;

    &:hover {
      background-color: #333;
    }

    &.min {
      -webkit-border-top-left-radius: 3px;
      -webkit-border-bottom-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      -moz-border-radius-bottomleft: 3px;
    }

    &.plus {
      -webkit-border-top-right-radius: 3px;
      -webkit-border-bottom-right-radius: 3px;
      -moz-border-radius-topright: 3px;
      -moz-border-radius-bottomright: 3px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  input {
    width: 100%;
    max-width: 50px;
    appearance: none;
    border: 1px solid #f1f1f1;
    text-align: center;
    padding: 0px;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @media (min-width: $breakpoint-md) {
  }
}

input[type='checkbox'] ~ label .checkbox {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  line-height: 30px;
  text-align: center;
  float: left;
  margin-right: 10px;
  border-radius: 3px;

  .fa {
    opacity: 0;
    line-height: inherit;
    color: $donkergrijs;
    font-size: 0.9em;
  }

  &:hover {
    .fa {
      opacity: 0.2;
    }
  }
}

input[type='checkbox']:checked ~ label {
  border-color: $groen;

  .checkbox .fa {
    opacity: 1;
    color: $groen;
  }
}

.input-check {
  width: 100%;
  display: table;
  line-height: 0;
  margin-bottom: 3px;
  cursor: pointer;

  input {
    display: none;
  }

  input[type='checkbox'] ~ label {
    color: $blauw;
    font-weight: 200;
    font-size: 14px;
    .checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid $blauw;
      line-height: 10px;
      text-align: center;
      float: left;
      margin: 0;
      margin-right: 10px;
      border-radius: 3px;

      .fa {
        opacity: 0;
        line-height: inherit;
        color: $blauw;
        font-size: 10px;
      }

      &:hover {
        .fa {
          opacity: 0.2;
        }
      }
    }
  }

  input[type='checkbox']:checked ~ label {
    color: $rood;
    .checkbox {
      border-color: $rood;
    }

    .checkbox .fa {
      opacity: 1;
      color: $rood;
    }
  }
}

.car_types {
  width: calc(100% + 10px);
  display: flex;
  margin: 0px -5px;

  .car_type__container {
    position: relative;
    width: 100%;
    padding: 5px;
  }

  .car_type {
    width: 100%;
    float: left;
    border: 2px solid white;
    font-size: 0.6em;
    display: block;
    text-align: center;
    position: relative;
    border-radius: 3px;
    padding: 20px 10px;
    cursor: pointer;
    color: $donkergrijs;

    svg {
      fill: $donkergrijs;
    }

    .svg-box {
      width: calc(100% - 20px);
      margin: 0px 10px;
      display: block;
      padding-top: 83.225%;
      position: relative;

      svg {
        width: 100%;
        max-width: 100px;
        position: absolute;
        top: 50%;
        left: calc(50% + 1px);
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      border-color: $donkergrijs;
      color: $donkergrijs;

      svg {
        fill: $donkergrijs;
      }
    }
  }

  input {
    display: none;
  }

  input:checked ~ label.car_type {
    border: 2px solid $blauw;
    color: $blauw;

    svg {
      fill: $blauw;
    }

    &.auto {
      border-color: $rood;
      color: $rood;

      svg {
        fill: $rood;
      }
    }

    &.elektrisch {
      border-color: $groen;
      color: $groen;

      svg {
        fill: $groen;
      }
    }
  }
}

.range__slider {
  width: 100%;
  display: flex;
  flex-direction: row;

  .input-row:first-child {
    margin-right: 3px;
  }
}

// Custom form
fieldset {
  border: 0;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;

  h3 {
    margin-bottom: 25px;
    width: 100%;
  }

  p {
    font-size: 14px;
    margin-bottom: 25px;
    width: 100%;
  }

  .form-input {
    position: relative;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      width: calc(50% - 5px);

      &:nth-of-type(even) {
        margin-left: auto;
      }
    }

    &.wide {
      width: 100%;
    }

    label {
      background: white;
      color: $blauw;
      font-size: 14px;
      left: 15px;
      line-height: 14px;
      padding: 3px;
      position: absolute;
      top: -8px;
    }

    input,
    textarea,
    select {
      border: 1px solid #e7e7e7;
      margin-bottom: 20px;
      padding: 15px;
      width: 100%;

      &:focus {
        outline-color: $blauw;
      }
    }

    input[type='search'] {
      -webkit-appearance: none;
      border-radius: 0;
    }

    input,
    select {
      height: 57px;
      line-height: 57px;
    }
  }
}

// WPForms
.wpforms-container {
  .wpforms-form {
    input[type='checkbox'],
    input[type='radio'] {
      margin: 0 8px 0 3px !important;
    }

    input.wpforms-error {
      border-color: $danger !important;
    }

    .wpforms-field-container {
      .wpforms-field {
        margin: 0;
        position: relative;

        &.wpforms-one-half,
        &.wpforms-two-fourths {
          width: 100%;

          @media (min-width: $breakpoint-md) {
            width: calc(50% - 5px);

            &:not(.wpforms-first) {
              margin-left: 10px;
            }
          }

          input {
            max-width: 100%;
            width: 100%;

            &[type='checkbox'],
            &[type='radio'] {
              width: auto;
              margin: 0 6px 0 3px;
            }
          }
        }

        ul {
          height: 100% !important;
        }

        &.wpforms-one-fourth {
          width: calc(50% - 5px);
          margin-left: 2.5px;
          margin-right: 2.5px;

          @media (min-width: $breakpoint-md) {
            width: calc(25% - 7.5px);

            &:not(.wpforms-first) {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }

        &.wpforms-has-error {
          input,
          textarea {
            border-color: $danger;
          }
        }

        &-radio,
        &-checkbox,
        &-gdpr-checkbox {
          margin-bottom: 15px;
          margin-top: 15px;
          width: 100%;

          > label {
            left: 0 !important;
          }

          @media (min-width: $breakpoint-lg) {
            width: 50%;

            &.full-width {
              width: 100%;
            }
          }

          ul {
            padding-top: 15px !important;

            li {
              input {
                height: 13px !important;
                width: 13px !important;
              }
            }
          }
        }

        &-gdpr-checkbox {
          ul {
            height: auto !important;
          }
        }

        &.wpforms-list-inline {
          ul {
            align-items: flex-start;
            display: flex;
            flex-flow: row wrap;
          }
        }

        .wpforms-field-label {
          background: white;
          color: $blauw;
          font-size: 14px;
          font-weight: normal;
          left: 15px;
          line-height: 14px;
          margin: 0;
          -ms-overflow-style: none;
          overflow-x: scroll;
          padding: 2px 3px;
          position: absolute;
          scrollbar-width: none;
          top: 2px;
          white-space: nowrap;
          z-index: 1;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .wpforms-field-label-inline {
          color: $blauw;
        }

        .wpforms-captcha-question {
          margin: 0 0 4px 0;
          top: 14px;
          position: relative;
          margin-bottom: 20px;
        }

        .wpforms-captcha-equation {
          top: 17px;
          position: relative;
          margin-bottom: 20px;
        }

        .wpforms-captcha-math {
          display: flex;
          flex-direction: column;
        }

        .wpforms-captcha-math input {
          margin-left: 0px;
        }

        .wpforms-label-hide {
          display: none;

          + ul {
            padding-top: 0 !important;
          }
        }

        .wpforms-field-sublabel {
          display: none;
        }

        .is-focused,
        .is-open,
        .is-active {
          .choices__inner,
          .choices__list {
            box-shadow: none;
          }
        }

        .choices__inner {
          padding: 0;
        }

        .choices__list {
          z-index: 2;

          &--single {
            line-height: 57px;
            padding: 15px;
          }
        }

        input,
        textarea,
        select,
        .wpforms-field-required,
        .wpforms-field {
          &:not([type='checkbox']),
          &:not([type='radio']) {
            border: 1px solid #e7e7e7;
            margin-bottom: 0;
            padding: 15px;
            width: 100%;

            &:not(.wpforms-error) {
              &:focus {
                border: 1px solid $blauw;
              }
            }
          }
        }

        input,
        select,
        input:not([type='radio']),
        input:not([type='checkbox']),
        .wpforms-field-required:not(textarea),
        .wpforms-field:not(textarea),
        .choices__inner {
          height: 57px;
          line-height: 57px;

          &[type='file'] {
            line-height: 1;
          }
        }

        input[type='tel'] {
          &.wpforms-smart-phone-field {
            padding-left: 50px;
          }
        }
      }
    }

    .wpforms-submit-container {
      button[type='submit'] {
        background: $rood;
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        display: inline-block;
        font-size: 0.8em;
        font-weight: 400;
        height: 50px;
        letter-spacing: 1px;
        line-height: 47px;
        margin-top: 15px;
        max-width: 100%;
        min-width: 240px;
        padding: 0px 30px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.2s linear;
        white-space: nowrap;

        &:hover {
          background: darken($rood, 10%);
          color: white;

          a {
            text-decoration: none;
          }
        }
      }
    }

    // button overwrites for .wpforms-container .wpforms-form *
    .btn {
      background: none;
      border: 2px solid white;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 400;
      height: 50px;
      letter-spacing: 1px;
      line-height: 47px;
      margin-top: 15px;
      max-width: 100%;
      padding: 0px 30px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.2s linear;
      white-space: nowrap;

      &.btn-primary {
        background: $rood;
        border-color: $rood;
        color: white;

        &:hover {
          background: darken($rood, 10%);
          border-color: darken($rood, 10%);
          color: white;

          a {
            text-decoration: none;
          }
        }
      }

      &.btn-white {
        border-color: white;
        color: white;

        &:hover {
          background: white;
          color: $rood;
        }
      }

      &.btn-secondary {
        border-color: $blauw;
        color: $blauw;
        margin-bottom: 15px;
        margin-top: 0;
        padding: 0 25px;
        text-align: center;

        &:hover {
          background: $blauw;
          color: white;
        }
      }

      &.btn-load {
        position: relative;

        @keyframes example {
          from {
            width: 0%;
          }
          to {
            width: calc(100% + 4px);
          }
        }

        &::after {
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-name: example;
          background-color: rgba(0, 0, 0, 0.1);
          content: '';
          height: calc(100% + 4px);
          left: -2px;
          position: absolute;
          top: -2px;
          width: 0%;
        }
      }

      &.btn-small {
        transform: scale(0.75);
        transform-origin: left center;
      }

      &.btn-submit {
        min-width: 300px;
      }

      &.btn-login {
        margin-bottom: 10px;
      }

      &.btn-rental {
        border-radius: 20px;
        height: 40px;
        line-height: 30px;
        margin-right: 10px;
        margin-top: 0;
        padding: 5px 25px;
        text-transform: capitalize;
      }

      &.btn-location {
        margin-top: 0;
        width: 250px;
      }

      &:hover {
        background: white;
        color: #fcfcfc;
      }

      .fa {
        font-size: inherit;
        line-height: inherit;
        // margin: 0 0 0 5px;
        position: relative;
      }
    }
  }
}

// login formulier
#loginform,
#register-form {
  width: 100%;

  .login-username,
  .login-password,
  .login-email {
    margin-bottom: 0;
    position: relative;

    label {
      background: white;
      color: $blauw;
      font-size: 14px;
      font-weight: 400;
      left: 15px;
      line-height: 14px;
      padding: 3px;
      position: absolute;
      top: -8px;
    }

    input {
      border: 1px solid #e7e7e7;
      height: 57px;
      line-height: 57px;
      margin-bottom: 20px;
      padding: 15px;
      width: 100%;

      &:focus {
        outline-color: $blauw;
      }
    }
  }

  .login-submit {
    #wp-submit {
      background: none;
      background: $rood;
      border: 2px solid $rood;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 400;
      height: 50px;
      letter-spacing: 1px;
      line-height: 47px;
      margin-top: 15px;
      max-width: 100%;
      padding: 0px 30px;
      text-align: center;
      text-decoration: none;

      text-transform: uppercase;
      transition: all 0.2s linear;
      white-space: nowrap;

      &:hover {
        background: darken($rood, 10%);
        border-color: darken($rood, 10%);
        color: white;
      }
    }
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='password'],
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-attachment: fixed;
}

.flatpickr-months .flatpickr-month {
  height: 44px;
}

@media only screen and (max-width: 600px) {
  div.wpforms-container-full
    .wpforms-form
    .wpforms-field:not(.wpforms-field-phone):not(
      .wpforms-field-select-style-modern
    ) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.wp_login_error p {
  background: $lichtgrijs;
  border: 1px solid $danger;
  border-radius: 3px;
  margin-bottom: 25px;
  padding: 13px 15px;
}

div.wpforms-container-full
  .wpforms-datepicker-wrap
  .wpforms-field-medium
  + .wpforms-datepicker-clear {
  right: 15px !important;
}

.wpforms-list-inline {
  height: auto !important;
}

.flatpickr-months {
  height: 40px;
}

.bekeuring-error {
  display: none;
  margin-top: -20px;
}

#bekeuring-naam {
  &::placeholder {
    /* Chrome/Opera/Safari */
    font-size: 0.8em;
    color: $donkergrijs;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 0.8em;
    color: $donkergrijs;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 0.8em;
    color: $donkergrijs;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 0.8em;
    color: $donkergrijs;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 0.8em;
    color: $donkergrijs;
  }
}

div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full
  .wpforms-form
  .wpforms-field-row.wpforms-field-medium {
  max-width: 100% !important;
}
