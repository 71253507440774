@use '../partials/settings' as *;

#winkelwagen {
  padding: 25px 0px;
  overflow: hidden;

  background: $lichtgrijs;

  @media (min-width: $breakpoint-md) {
    padding: 50px 0px;
  }

  .steps {
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 0px -10px;
    margin-bottom: 25px;
    background-color: rgba(black, 0.05);

    .step__filler {
      width: 33%;
      position: absolute;
      bottom: 0;
      height: 100%;
      background: $blauw;
      transition: width 0.2s linear;
    }

    .step {
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 1;
      cursor: pointer;
      padding: 15px 0px;
      font-size: 0;

      strong {
        font-size: 18px;
      }

      @media (min-width: $breakpoint-md) {
        font-size: 1em;
      }

      &.active {
        color: white;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .block {
    margin-top: 10px;
    padding: 25px;
    background: white;

    .optie {
      padding: 25px;
      border: 1px solid rgba(black, 0.05);
      margin-bottom: 25px;

      .cart__items {
        flex-direction: column;
        margin-top: 25px;

        @media (min-width: $breakpoint-lg) {
          flex-direction: row;
        }
      }

      .tankpas__card {
        margin-bottom: 25px;
        width: 100%;
        padding-right: 5px;

        label.cart__item {
          width: 100%;
          flex-direction: row;
          display: flex;
          border: 2px solid $lichtgrijs;
          align-items: center;
          cursor: pointer;
          border-radius: 3px;

          &:hover {
            border-color: $donkergrijs;
          }

          .checkbox {
            float: none;
            margin-right: 25px;
          }
        }

        input {
          display: none;
        }

        input:checked ~ .cart__item {
          border-color: $groen;
        }
      }

      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        font-weight: 600;
        line-height: 30px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        label {
          width: 100%;
          line-height: 30px;
        }

        input {
          display: none;
        }

        .arrow {
          margin-left: auto;
        }
      }
    }

    h2 {
      text-align: left;
    }

    &.cart h2 {
      padding-left: 10px;
    }

    .prijs {
      text-align: right;
    }
  }

  .cart {
    display: flex;
    flex-direction: column;
    padding: 0px;

    h3,
    h2 {
      padding: 25px;
      display: block;
      margin-bottom: 0;
    }

    .cart__items {
      display: flex;
      flex-direction: column;

      .cart__item {
        padding: 10px 20px;
        position: relative;

        &:nth-child(odd) {
          background: rgba($blauw, 0.04);

          label {
            background: #f6f8f9;
          }
        }

        .cart__item--inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          @media (min-width: $breakpoint-md) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }

          .cart__item--a {
            flex-direction: row;
            align-items: center;
            justify-content: stretch;
            display: flex;
            width: 50%;
          }

          .cart__item--b {
            flex-direction: row;
            align-items: center;
            display: flex;
            justify-content: end;
            width: 25%;
          }

          .cart__item--extras {
            text-align: left;
            font-size: 13px;
            width: 25%;

            span {
              white-space: nowrap;
              display: block;

              strong {
                color: $rood;
              }
            }
          }

          .figure_holder {
            width: 30%;
            max-width: 120px;
          }

          figure {
            padding-top: 0px;
            width: 100%;
            height: 0px;
            padding-top: 56.25%;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .info {
            padding: 0px 10px;
            flex-grow: 1;

            .name {
              color: $blauw;
              font-weight: 200;
              font-size: 1.2em;
              line-height: 1;
              display: block;

              @media (min-width: $breakpoint-sm) {
                font-size: 1.2em;
              }

              strong {
                font-weight: 600;
                display: inline-block;
                font-size: inherit;
              }
            }

            .maanden {
              display: block;
              font-size: 0.7em;
            }
          }

          .details {
            font-size: 0.6em;

            span {
              display: block;
              white-space: nowrap;
              padding-right: 30px;
            }
          }

          .startdatum {
            padding-right: 5px;
            margin-bottom: 0;
            width: 50%;

            input {
              max-width: 250px;
              border-radius: 3px;
            }

            @media (min-width: $breakpoint-lg) {
              padding-right: 50px;
            }
          }

          .input-aantal {
            // width: 20%;
            margin-left: auto;
          }

          .price__container {
            //margin-left: auto;
            padding-left: 15px;

            @media (min-width: $breakpoint-md) {
              width: 150px;
              padding-left: 25px;
            }
          }
        }

        .remove {
          position: absolute;
          right: -13px;
          background-color: white;
          width: 26px;
          height: 26px;
          color: black;
          text-align: center;
          line-height: 22px;
          font-size: 10px;
          border-radius: 50%;
          cursor: pointer;
          display: none;
          top: 50%;
          transform: translate(0%, -50%);
          border: 2px solid black;

          &:hover {
            background-color: $rood;
            color: white;
            border-color: $rood;
          }
        }

        &:hover .remove {
          display: block;
        }

        .info-wide {
          width: 100%;
          display: flex;
          flex-direction: column;
          font-size: 0.6em;
          padding-top: 3px;
          color: $antraciet;

          span {
            font-size: inherit;
            display: block;
            padding-right: 50px;
          }
        }

        &.tankpas .cart__item--inner .info .name {
          flex: 1 0 0;
        }

        &.cart__item--finish {
          padding: 0px;
          border: 1px solid #ebebeb;
          margin: 10px;
          background: white;
          border-radius: 3px;

          .cart__item--inner {
            padding: 15px;
          }

          .info-rows {
            width: 100%;
            display: table;

            .info-item {
              display: block;
              padding: 7px 15px;
              font-size: 0.7em;

              &:nth-child(even) {
                background: rgba($blauw, 0.04);
              }

              strong {
                font-size: 1em;
              }

              .extra-price {
                float: right;
                color: $rood;
                font-weight: 600;
              }

              &.subtotaal {
                padding: 10px 15px;
                background-color: rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: row;
                align-items: center;

                .price__container {
                  margin-left: auto;
                  padding-left: 25px;
                }
              }
            }
          }
        }
      }
    }

    .cart__footer {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-top: 1px solid #f1f1f1;

      .aantal {
        color: $donkergrijs;

        strong {
          font-weight: 600;
        }
      }

      .price__container {
        width: auto;
        align-self: flex-end;
        margin-left: auto;
        padding-left: 25px;
        display: none;

        @media (min-width: $breakpoint-lg) {
          display: block;
        }
      }
    }
  }

  .gegevens {
    padding: 10px;

    h2 {
      padding: 25px 25px 25px 0;
      display: block;
      margin-bottom: 0;
    }

    .input-confirm-group + .input-confirm-group {
      margin-top: 25px;
    }

    .input-confirm-group {
      border: 1px solid #ebebeb;
      border-radius: 3px;

      & > h3 {
        padding: 15px 10px;
        display: block;
        margin-bottom: 0;
        font-size: 22px;
      }

      .input-confirm {
        margin: 0;
        padding: 15px 10px;

        h3 {
          padding: 0;
        }
      }
    }
  }

  .buttons {
    padding: 10px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;

    &.floating-cart-buttons {
      box-shadow: 0 0 6px 3px rgba(#222, 0.2);
      position: fixed;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 30;
      display: none;
    }

    @media (min-height: 1000px) and (max-width: $breakpoint-xl) {
      position: relative;
    }

    .buttons-flex-container {
      display: flex;
      align-items: center;
    }

    .price__container {
      margin-right: 15px;

      &.totaal {
        display: none;

        @media (min-width: $breakpoint-md) {
          display: flex;
          flex-grow: 1;
          margin-right: auto;
          padding-left: 15px;
          .price {
            color: #333;
          }
        }
      }
    }

    .btn {
      margin: 0;
    }

    .btn-step {
      float: right;
    }

    .btn-finish {
      background-color: $geel;
      border-color: $geel;

      &:hover {
        background-color: darken($geel, 10%);
        border-color: darken($geel, 10%);
      }
    }
  }

  .reservering__finish {
    text-align: center;
    padding: 50px;
    width: auto;
    max-width: 600px;

    .far {
      font-size: 120px;
      color: $groen;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: none;

  .popup__overlay {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .popup__container {
    width: 100%;
    max-width: 500px;
    padding: 25px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;

    .popup__close {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.1);
      color: #666;
      text-align: center;
      line-height: 39px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .btn {
      height: auto;
      padding: 0px 10px;
      width: 100%;
    }
  }
}
