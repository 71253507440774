@use "../partials/settings" as *;

.page-contact {
	
	h1 {
		font-size: 35px;
		font-weight: 400;
		line-height: 1.1;
		margin: 0 0 10px;
		padding: 20px 2px;
	}
	
	a {
		color: #404040;
		text-decoration: none;
	}
	
	.content {
		a:hover {
			text-decoration: underline;
		}
		
		img {
			margin: 0 -40px -45px -40px;
			width: calc(100% + 80px);
		}
	}
}


.maps {
	display: flex;
	flex-flow: column wrap;
	position: relative;
	width: 100%;
	
	@media (min-width: $breakpoint-md) {
		display: flex;
		flex-flow: row-reverse;
		align-items: stretch;
		justify-content: stretch;
	}
	
	#map {
		height: 500px;
		order: 2;
		width: 100%;
	}
	
	.popover {
		background: #fff;
		border: 1px solid #ccc;
		display: none;
		height: 100%;
		left: unset;
		max-width: 100%;
		order: 1;
		padding: 25px;
		position: relative;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 1;
		
		@media (min-width: $breakpoint-md) {
			max-width: 350px;
			height: 500px;
			// position: absolute;
		}
		
		.vestigingsinfo {
			margin-top: 50px;
		}
		
		&__partner-list {
			padding: 15px;

			.pad {
				padding: 10px;
				margin-bottom: 10px;
			}
			.vestigingsinfo {
				margin-top: 0;
				// display: flex;
				height: 100%;
				overflow: hidden;

				.listings {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					overflow: scroll;
				}
				
				ul {
					list-style-type: none;
					
					li {
						position: relative;
						padding: 5px 0;
						
						strong {
							font-weight: 500;
							font-size: 1em;
							
							&::after {
								position: absolute;
								width: 100%;
								height: 100%;
								content: '';
								left: 0;
								top: 0;
							}
						}

						p {
							font-size: 0.9em;
						}
						
						div {
							font-weight: 300;
						}
					}
				}
			}
		}
		
		a {
			&:hover {
				color: black;
				text-decoration: underline;
			}
		}
		
		h3 {
			color: black;
			margin-bottom: 20px;
		}
		
		p {
			color: black;
		}
		
		hr {
			margin: 15px 0;
		}
		
		.partnerlink {
			align-items: center;
			background: $rood;
			border-radius: 50px;
			color: white;
			display: flex;
			height: 35px;
			justify-content: center;
			position: absolute;
			right: 80px;
			top: 27px;
			transition: .15s ease-out;
			width: 35px;
			
			&:hover {
				background: darken($rood, 10%);
				color: white;
				text-decoration: none;
				transition: .15s ease-out;
			}
		}
		
		.close {
			color: black;
			cursor: pointer;
			height: 40px;
			line-height: 40px;
			opacity: .66;
			position: absolute;
			right: 0px;
			text-align: center;
			top: 0px;
			transition: .15s ease-out;
			width: 40px;
			
			&:hover {
				color: rgba(black, 0.33);
				transition: .15s ease-out;
			}
		}
		
		.rental {
			a {
				&:hover {
					color: white;
					text-decoration: none;
				}
			}
		}
	}	
}

.search-location {
	display: inline-block;
	height: 50px;
	position: relative;
	width: 100%;
	
	@media (min-width: 555px) {
		width: auto;
	}
	
	.search-location__results {
		background: #fff;
		border-radius: 3px;
		box-shadow: 0 3px 6px rgba(black, .20);
		left: 11px;
		list-style: none;
		margin: 0;
		position: absolute;
		text-align: left;
		top: 50px;
		width: calc(100% - 11px);
		z-index: 2;
		
		li {
			border-bottom: 1px solid #E7E7E7;
			padding: 5px 15px;
			position: relative;
			
			&:hover {
				background: #E7E7E7;
			}
			
			a {
				font-weight: bold;
				
				&:hover {
					color: black;
					text-decoration: none;
				}
				
				&::before {
					content: '';
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 0;
				}
			}
		}
	}
}

#geocoder {
	margin-top: 10px;
	
	@media (min-width: 555px) {
		margin-left: 10px;
		margin-top: 0;
	}
	
	.mapboxgl-ctrl-geocoder {
		appearance: none;
		border: 1px solid #E7E7E7;
		border-radius: 3px;
		height: 50px;
		line-height: 50px;
		padding-left: 15px;
		width: 100%;
		
		@media (min-width: 555px) {
			width: 250px;
		}
		
		&:focus {
			border-color: $blauw;
		}
		
		&::placeholder {
			font-size: .8em;
		}
		
		.mapboxgl-ctrl-geocoder--input {
			height: 36px;
			padding: 6px 35px;
			border: 0;
			font-size: 1em;
			position: relative;
			top: -1px;
		}
	}

	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
		top: 13px;
		right: 14px;
	}

	.mapboxgl-ctrl-geocoder--icon-search {
		top: 13px;
		left: 14px;
	}

	.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
		box-shadow: none;
		text-align: left;
		z-index: 210;
	}
}

// map
// #listings {
// 	display: none;
// }

// MAP
/* Marker tweaks */
.loading-spinner {
	display: none;
	height: 50px;
	left: calc(50% - 25px);
	position: absolute;
	top: calc(50% - 25px);
	width: 50px;
	z-index: 1;
}


.sidebar {
	border-right: 1px solid rgba(0, 0, 0, 0.25);
	display: none;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 33.3333%;
}

.pad2 {
	padding: 20px;
}

.map {
	bottom: 0;
	/*left:33.3333%;*/
	/*width:66.6666%;*/
	left: 0;
	position: absolute !important;
	top: 0;
	width: 100%;
}

.heading {
	background: #fff;
	background-color: #00853e;
	border-bottom: 1px solid #eee;
	color: #fff;
	line-height: 60px;
	min-height: 60px;
	padding: 0 10px;
}

.listings {
	height: 100%;
	overflow: auto;
	// padding-bottom: 60px;
}

.listings .item {
	// border-bottom: 1px solid #eee;
	display: block;
	padding: 10px;
	text-decoration: none;
	border-radius: 3px;

	&.active {
		background-color: rgba($blauw, 0.2);
	}

	&:hover {
		background-color: #f7f7f7;

		&.active {
			background-color: rgba($blauw, 0.3);
		}
	}

	* {
		text-decoration: none;
	}
}

// .listings .item:last-child {
// 	border-bottom: none;
// }

// .listings .item .title {
// 	color: #00853e;
// 	display: block;
// 	font-weight: 700;
// }

// .listings .item .title small {
// 	font-weight: 400;
// }

// .listings .item.active .title,
// .listings .item.active {
// 	background-color: #f8f8f8;
// }


.marker {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border: none;
	cursor: pointer;
	background-image: url('../img/map-pin.svg');
	width: 36px;
	height: 36px;
}

.clearfix {
	display: block;
}

.clearfix:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	visibility: hidden;
}

#loc {
	background: white;
	border: 2px solid #d6d6d6;
	border-radius: 4px;
	color: #0c0c0c;
	display: none;
	font-family: 'HurmeBold', Arial, Helvetica, sans-serif;
	font-size: 17px;
	left: calc(50% - 130px);
	padding: 13px;
	position: absolute;
	text-align: center;
	top: 158px;
	width: 260px;
	z-index: 1;
}

/* Marker tweaks */
.mapboxgl-popup {
	padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
	color: white;
	display: block;
	right: 10px !important;
}

.mapboxgl-popup-content {
	font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
	padding: 0 !important;
	width: 180px;
}

.mapboxgl-popup-content-wrapper {
	padding: 1%;
}

.mapboxgl-popup-content h3 {
	background: #626262;
	border-radius: 3px 3px 0 0;
	color: #fff200;
	display: block;
	font-weight: 700;
	margin: 0;
	margin-top: -15px;
	padding: 10px;
	text-transform: capitalize;
}

.mapboxgl-popup-content h4 {
	display: block;
	font-weight: 400;
	margin: 0;
	padding: 10px 10px 10px 10px;
	text-transform: capitalize;
}

.mapboxgl-popup-content div {
	padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
	cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
	margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
	border-bottom-color: #ffeb00;
}

.mapboxgl-ctrl-geocoder {
	border: 2px solid #00853e;
	border: 0;
	border-radius: 0;
	margin-top: 0;
	position: relative;
	top: 0;
	width: 800px;
}

.mapboxgl-ctrl-geocoder > div {
	margin-left: 0;
	min-width: 100%;
}

.mapboxgl-ctrl-bottom-right {
	opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
	display: none;
	width: 0;
}

a.mapboxgl-ctrl-logo {
	opacity: 0;
}

.mapboxgl-ctrl-bottom-right {
	opacity: 0;
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
	display: none;
	width: 0;
}

