@use "../partials/settings" as *;

.brands {

    .brand {
        float: left;
        width: 50%;
        list-style: none;
        margin-bottom: 25px;

        figure {
            width: 150px;
            margin: 0px auto;
            height: 0px;
            padding-top: 25%;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;

                &:hover {
                    transform: scale(1.02);
                }
  
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  width: auto;
                }
            }
        }

        @media(min-width: 1020px) {
            width: 20%;
        }
    }
}